import React, { InputHTMLAttributes } from 'react';

import { FormLabel } from './styles';

interface ComponentProps {
  label: string;
  input: InputHTMLAttributes<HTMLInputElement>;
  className?: string;
}

const CredentialsFormInput = ({
  label,
  input,
  className = '',
}: ComponentProps) => {
  return (
    <FormLabel htmlFor={input.id} className={className}>
      {label}
      <input className="credential-form-input" {...input} />
    </FormLabel>
  );
};


export default CredentialsFormInput;
