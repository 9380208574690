import React from 'react';

import {
  AttachmentLessonInfo,
  AttachmentLessonLink,
  AttachmentLessonListItemContainer
} from './AttachmentLessonListItem.styles';
import Lesson from '../../models/Lesson';
import useCourses from '../../services/swr/useCourses';
import StrippedButton from '../../components/StrippedButton';
import { MdRemoveCircleOutline } from 'react-icons/md';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import useModalRef from '../../hooks/useModalRef';
import DoubleArrowIcon from '../../components/DoubleArrowIcon';
import useAttachments from '../../services/swr/useAttachments';


interface ComponentProps {
  lessonInfo: Lesson;
  attachmentId: string;
}

const AttachmentLessonListItem = ({ lessonInfo, attachmentId }: ComponentProps) => {
  const { getCourse } = useCourses();
  const { removeLessonAttachment } = useAttachments();
  
  const deletePromptRef = useModalRef();

  const onRemove = () => {
    removeLessonAttachment(attachmentId, lessonInfo.id);
  };
  
  const course = lessonInfo.courseId ? getCourse(lessonInfo.courseId) : null;

  const duration = lessonInfo.duration ? Math.round(lessonInfo.duration / 60)  : 0;
  return (
    <AttachmentLessonListItemContainer>
      <AttachmentLessonLink to={`/attachments-details/${lessonInfo.id}`}>
        <AttachmentLessonInfo>
          <div>
            <p className="course-title">{lessonInfo.title}</p>
            <p><DoubleArrowIcon size={15} color="#000" /></p>
             <p className="course-title">{course?.title}</p> 
          </div>
          <p className="course-description"><strong>Pontos: </strong>{lessonInfo.balance}</p>
        <p className="course-description"><strong>Experiência: </strong>{lessonInfo.experiencePoints}</p>    
        <p className="course-description"><strong>Duração: </strong> ≅ {duration} minutos</p>
        </AttachmentLessonInfo>
      </AttachmentLessonLink>

      <StrippedButton
        onClick={() => deletePromptRef.current?.openModal()}
      >
        <MdRemoveCircleOutline className="remove-icon" size={24} />
      </StrippedButton>
      <ConfirmationPrompt
        ref={deletePromptRef}
        promptText={`Tem certeza que deseja remover o anexo dessa aula?`}
        onConfirm={onRemove}
      />
    </AttachmentLessonListItemContainer>
  );
};

export default AttachmentLessonListItem;
