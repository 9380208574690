import { useCallback, useState } from 'react';

import api from '../api';
import Attachment from '../../models/Attachment';
import useToast from '../../hooks/useToast';
import useSWR, { useSWRConfig } from 'swr';
import { fetcher } from './swr';
import Lesson from '../../models/Lesson';

const useAttachments = () => {
  const { addToast } = useToast();

  const { data, error } = useSWR<Attachment[]>('/attachments', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 12,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { mutate } = useSWRConfig();
  const [attachmentLessons, setAttachmentLessons] = useState<Lesson[]>([]);

  const getAttachment = useCallback(
    (attachmentId: string) =>
      data?.find(attachment => attachment.id === attachmentId),
    [data],
  );

  const addLessonToAttachment = useCallback(
    (attachmentId: string, lessonsIds: string[]) => {
      api
        .post(`/attachments/${attachmentId}/add-to-lesson`, {
          lessonsIds,
        })
        .then(() =>
          addToast({
            type: 'success',
            title: 'Anexo adicionado à aula com sucesso',
          }),
        )
        .catch(() =>
          addToast({
            type: 'error',
            title: 'Erro ao adicionar anexo à aula',
          }),
        )
        .finally(() => {
          mutate(`/attachments/${attachmentId}/lessons`);
        });
    },
    [addToast, mutate],
  );

  const getLessonByAttachment = useCallback((attachmentId: string) => {
    api.get(`/attachments/${attachmentId}/lessons`).then(response => {
      setAttachmentLessons(response.data);
    });
  }, []);

  const removeLessonAttachment = useCallback(
    (attachmentId: string, lessonId: string) => {
      api
        .delete(`/attachments/${attachmentId}/remove-from-lesson/${lessonId}`)
        .then(() =>
          addToast({
            type: 'success',
            title: 'Anexo removido da aula com sucesso',
          }),
        )
        .catch(() =>
          addToast({
            type: 'error',
            title: 'Erro ao remover aula do anexo',
          }),
        )
        .finally(() => {
          mutate(`/attachments/${attachmentId}/lessons`);
        });
    },
    [addToast, mutate],
  );

  const addAttachment = useCallback(
    (title: string, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      api
        .post(`/attachments/register?title=${title}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() =>
          addToast({
            type: 'success',
            title: 'Anexo cadastrado com sucesso',
          }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao cadastrar anexo' }),
        )
        .finally(() => {
          mutate('/attachments');
        });
    },
    [addToast, mutate],
  );
  const deleteAttachment = useCallback(
    (attachmentId: string) => {
      if (data)
        mutate(
          `/attachments`,
          data.filter(c => c.id !== attachmentId),
          false,
        );

      api
        .delete(`/attachments/delete/${attachmentId}`)
        .then(() =>
          addToast({ type: 'success', title: 'Anexo deletado com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao deletar Anexo' }),
        )
        .finally(() => mutate('/attachments'));
    },
    [addToast, data, mutate],
  );

  return {
    error,
    getAttachment,
    addAttachment,
    deleteAttachment,
    attachmentLessons,
    addLessonToAttachment,
    getLessonByAttachment,
    removeLessonAttachment,
    attachments: data || [],
    isLoading: !data && !error,
  };
};

export default useAttachments;
