import React, { useEffect, useState } from 'react';
import useStudentCourses from '../../services/swr/useStudentCourses';
import StudentCourseMetrics from './StudentCourseMetrics';
import { CourseSelector } from './StudentMetricsTab.styles';

interface ComponentProps {
  studentId: string;
}

const StudentMetricsTab = ({ studentId }: ComponentProps) => {
  const { courses } = useStudentCourses(studentId);
  const [selectedCourse, setSelectedCourse] = useState(courses[0]);

  const handleCourseSelection = (courseId: string) => {
    const selectedCourse = courses.find(course => course.id === courseId);
    if (selectedCourse) setSelectedCourse(selectedCourse);
  };

  useEffect(() => {
    if (courses.length > 0 && !selectedCourse) {
      setSelectedCourse(courses[0]);
    }
  }, [courses, selectedCourse]);

  return (
    <>
      <CourseSelector>
        <p className="selected-course">{selectedCourse?.title || ''}</p>
        <select
          className="course-selector"
          onChange={e =>
            handleCourseSelection(e.target.selectedOptions[0].value)
          }
        >
          {courses.map(course => (
            <option key={course.id} value={course.id}>
              {course.title}
            </option>
          ))}
        </select>
      </CourseSelector>

      {selectedCourse && (
        <StudentCourseMetrics
          studentId={studentId}
          courseId={selectedCourse.id}
        />
      )}
    </>
  );
};

export default StudentMetricsTab;
