import React, { Fragment, useCallback } from 'react';
import { useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import GenericDivider from '../GenericDivider';
import {
  ConfirmSelectionButton,
  List,
  ListContainer,
  ListItem,
} from './GenericSelectableList.styles';

type ChildComponentProps<T> = {
  data: T;
};

type ComponentProps<T> = {
  dataArray: Array<T>;
  confirmSelectionButtonText?: string;
  onConfirmSelection: (data: T[]) => void;
  listItemComponent: React.ComponentType<ChildComponentProps<T>>;
};

export default function GenericSelectableList<T extends { id: string }>({
  dataArray,
  confirmSelectionButtonText = 'Confirmar Seleção',
  onConfirmSelection,
  listItemComponent,
}: ComponentProps<T>): React.ReactElement {
  const [selectedDataIds, setSelectedDataIds] = useState<string[]>([]);

  const handleDataToggle = useCallback(
    (dataId: string) => {
      let newselectedDataIds: string[] = [];

      if (selectedDataIds.includes(dataId))
        newselectedDataIds = selectedDataIds.filter(id => id !== dataId);
      else newselectedDataIds = [...selectedDataIds, dataId];

      setSelectedDataIds(newselectedDataIds);
    },
    [selectedDataIds],
  );

  const handleConfirmSelection = () => {
    const selectedData = dataArray.filter(d => selectedDataIds.includes(d.id));
    onConfirmSelection(selectedData);
  };

  return (
    <ListContainer>
      <List>
        {dataArray.map((data, index) => (
          <Fragment key={data.id}>
            <li>
              <ListItem onClick={() => handleDataToggle(data.id)}>
                {React.createElement(listItemComponent, { data })}
                {selectedDataIds.includes(data.id) ? (
                  <MdCheckBox className="selection-icon" />
                ) : (
                  <MdCheckBoxOutlineBlank className="selection-icon" />
                )}
              </ListItem>
            </li>
            {index < dataArray.length - 1 && <GenericDivider />}
          </Fragment>
        ))}
      </List>

      <ConfirmSelectionButton onClick={handleConfirmSelection}>
        {confirmSelectionButtonText}
      </ConfirmSelectionButton>
    </ListContainer>
  );
}
