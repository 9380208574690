import styled from 'styled-components';
import Color from '../../styles/colors';
import StrippedButton from '../StrippedButton';

const colorGreyRgba = new Color('grey').withAlpha(0.5).rgba();

export const ButtonContainer = styled(StrippedButton)`
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--color-black);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;
  border: 1px solid ${colorGreyRgba};
  border-radius: 8px;

  box-shadow: 0 0 4px 0 ${colorGreyRgba};

  & > :not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0 4px 1px ${colorGreyRgba};
  }

  &:active {
    transition: 0.1s;
    transition-timing-function: ease-out;
    transform: translateY(2px);
  }
`;
