import React, { useCallback, useState } from 'react';
import useFilter from '../../hooks/useFilter';
import { ListControls } from './SearchableList.styles';

interface ComponentProps<T> {
  dataArray: Array<T>;
  searchMatches: (search: string, data: T) => boolean;
  onAdd?: () => void;
  onSort?: () => void;
  children: React.ComponentType<{ data: Array<T> }>;
}

export default function SearchableList<T>({
  dataArray,
  searchMatches,
  onAdd,
  onSort,
  children,
}: ComponentProps<T>) {
  const { filter, clearFilter, filteredData } = useFilter<T>(dataArray);

  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const handleSearch = useCallback(
    async (search: string) => {
      const treatedSearch = search
        .toLowerCase()
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      filter(data => searchMatches(treatedSearch, data));
      setIsUsingSearch(true);
    },
    [filter, searchMatches],
  );

  return (
    <>
      <ListControls
        onSearch={handleSearch}
        onAdd={onAdd}
        onSort={onSort}
        onClearSearch={clearFilter}
      />
      {React.createElement(children, {
        data: isUsingSearch ? filteredData : dataArray,
      })}
    </>
  );
}
