import React from 'react';
import { useParams } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import PageHeader from './ClassDetailsPageHeader';
import {
  ClassDetailsTabNavigator,
  PageContent,
} from './ClassDetailsPage.styles';
import ClassStudentsTable from './ClassStudentsTable';
import ClassCoursesList from './ClassCoursesList';
import { Tab } from '../../components/TabNavigator';
import ClassDetailsPageUrlParams from '../../routes/location/ClassDetailsPageUrlParams';
import useClasses from '../../services/swr/useClasses';
import Class from '../../models/Class';
import NotFoundPage from '../404';
import ClassMetricsTab from './ClassMetricsTab';
import ClassesQuizList from './ClassQuizList';

const ClassDetailsPage = () => {
  const { classId } = useParams<ClassDetailsPageUrlParams>();
  const { getClass, isLoading } = useClasses();

  const classInfo = getClass(classId);

  if (!classInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />
      {isLoading ? (
        <div>Carregando turma...</div>
      ) : (
        <PageContent>
          <PageHeader classInfo={classInfo as Class} />

          <ClassDetailsTabNavigator>
            <Tab label="Alunos">
              <ClassStudentsTable classId={classId} />
            </Tab>
            <Tab label="Cursos">
              <ClassCoursesList classId={classId} />
            </Tab>
            <Tab label="Questionários">
              <ClassesQuizList classId={classId} />
            </Tab>
            <Tab label="Métricas">
              <ClassMetricsTab classId={classId} />
            </Tab>
          </ClassDetailsTabNavigator>
        </PageContent>
      )}
    </>
  );
};

export default ClassDetailsPage;
