import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import useToast from '../../hooks/useToast';
import { UpdateProfileDTO } from '../../models/Profile';
import Teacher, { CreateTeacherDTO } from '../../models/Teacher';
import api from '../api';
import { fetcher } from './swr';

export default function useTeachers() {
  const { data, error } = useSWR<Teacher[]>('/teachers/index', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 24,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { addToast } = useToast();

  const getTeacher = useCallback(
    (teacherId: string) => data?.find(t => t.id === teacherId),
    [data],
  );

  const addTeacher = useCallback(
    async ({ name, email, password }: CreateTeacherDTO) => {
      try {
        await api.post('/teachers/register', { name, email, password });

        addToast({
          type: 'success',
          title: 'Professor cadastrado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar novo professor',
        });
      } finally {
        mutate('/teachers/index');
      }
    },
    [addToast],
  );

  const updateTeacherProfile = useCallback(
    async (teacherProfile: UpdateProfileDTO) => {
      if (data) {
        const {
          id: profileId,
          password,
          ...teacherProfileInfo
        } = teacherProfile;

        const newData = data.map(teacher => {
          if (teacher.profile.id === profileId) {
            return {
              ...teacher,
              profile: Object.assign(teacher.profile, teacherProfileInfo),
            };
          }

          return teacher;
        });

        mutate('/teachers/index', newData, false);

        try {
          await api.put(`/users/update/${profileId}`, {
            ...teacherProfileInfo,
            ...(password && {
              password,
              passwordConfirmation: password,
            }),
          });

          addToast({
            type: 'success',
            title: 'Professor editado com sucesso',
          });
        } catch (err) {
          addToast({ type: 'error', title: 'Erro ao editar professor' });
        } finally {
          mutate('/teachers/index');
        }
      }
    },
    [addToast, data],
  );

  const deleteTeacher = useCallback(
    (teacherId: string) => {
      if (data)
        mutate(
          '/teachers/index',
          data.filter(teacher => teacher.id !== teacherId),
          false,
        );

      api
        .delete(`/teachers/delete/${teacherId}`)
        .then(() =>
          addToast({
            type: 'success',
            title: 'Professor deletado com sucesso',
          }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao deletar professor' }),
        )
        .finally(() => mutate('/teachers/index'));
    },
    [addToast, data],
  );

  return {
    teachers: data || [],
    error,
    isLoading: !error && !data,
    getTeacher,
    addTeacher,
    updateTeacherProfile,
    deleteTeacher,
  };
}
