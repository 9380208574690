import styled from 'styled-components';

import MenuContainer from '../MenuContainer';

export const AdminMenuContainer = styled(MenuContainer)`
  /* NavBarContent height + NavBarContainer height/2 */
  top: 70px;
  left: 0;

  padding: 16px;

  transform-origin: top;
`;

export const AdminMenuOptions = styled.div`
  display: flex;
  flex-direction: column;

  .link {
    margin: 8px;

    font-family: var(--font-sans);
    font-size: 1.25rem;
    font-weight: 500;
    text-decoration: none;
    color: var(--color-white);
  }
`;
