import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import Course from '../../models/Course';
import { ModalContainer, Title } from './EditCourseLessonsPointsModal.styles';
import { FormikHelpers } from 'formik';
import { stripEmptyFields } from '../../utils/objects';
import useCourseLessons, { BulkUpdateCourseLessonsPoints } from '../../services/swr/useCourseLessons';

interface ComponentProps {
  courseInfo: Course;
}

const EditCourseLessonsPointsModal = React.forwardRef(
  ({ courseInfo }: ComponentProps, ref) => {
    const { bulkUpdateLessonsCoursesPoints } = useCourseLessons(courseInfo.id);

    const onSubmit = useCallback(
      (values: Record<string, unknown>, helpers: FormikHelpers<Record<string, unknown>>) => {
        const { setSubmitting, resetForm } = helpers;
        const updateCourseLessonsDTO: BulkUpdateCourseLessonsPoints = {
          courseId: courseInfo.id,
          ...stripEmptyFields(values),
        };
        bulkUpdateLessonsCoursesPoints(updateCourseLessonsDTO);

        resetForm();
        setSubmitting(false);
        closeModal();
        window.location.reload();
      },
      [courseInfo.id, ref, bulkUpdateLessonsCoursesPoints], // eslint-disable-line react-hooks/exhaustive-deps
    );
    
    const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );

    return (
      <ModalContainer ref={ref}>
        <Title>
          Editar Pontuação das Aulas
        </Title>
        <GenericModalForm
          initialValues={{
            balance: 0,
            experiencePoints: 0,
          }}
          validationSchema={Yup.object().shape({
            balance: Yup.number(),
            experiencePoints: Yup.number(),
          })}
          submitButtonType="edit"
          onSubmit={onSubmit}
          onCancel={closeModal}
        >
          <GenericTextInput
            name="balance"
            label="Quantidade de Moedas"
            type="number"
            tooltip="Se você deixar algum dos campos vazio, a quantidade permancerá com o valor atual."
            placeholder="itcoins"
          />
          <GenericTextInput
            name="experiencePoints"
            type="number"
            label="Quantidade de Pontos de Experiência"
            placeholder="xp"
          />
        </GenericModalForm>
      </ModalContainer>
    );
  },
);

EditCourseLessonsPointsModal.displayName = 'EditCourseLessonsPointsModal';

export default EditCourseLessonsPointsModal;
