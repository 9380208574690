import React, { useCallback, useMemo } from 'react';
import useToast from '../../hooks/useToast';

import {
  CoursesListItem,
  ModalContainer,
} from './AddCoursesToClassModal.styles';
import useClassCourses from '../../services/swr/useClassCourses';
import Course from '../../models/Course';
import SearchableList from '../../components/SearchableList';
import GenericSelectableList from '../../components/GenericSelectableList';
import { ModalRef } from '../../hooks/useModalRef';
import useCourses from '../../services/swr/useCourses';

interface ComponentProps {
  classId: string;
}

const AddCoursesToClassModal = React.forwardRef(
  ({ classId }: ComponentProps, ref) => {
    const { addToast } = useToast();
    const { courses } = useCourses();
    const { courses: classCourses, addCourses } = useClassCourses(classId);

    const coursesNotInClass = useMemo(() => {
      const classCoursesIds = classCourses.map(c => c.id);

      const coursesNotInClass = courses.filter(
        course => !classCoursesIds.includes(course.id),
      );

      return coursesNotInClass;
    }, [classCourses, courses]);

    const handleSearch = (search: string, course: Course) => {
      const treatedCourseName = course.title?.toLowerCase();

      const match =
        (treatedCourseName && treatedCourseName.includes(search)) ||
        course.description.includes(search);

      return match;
    };

    const handleSubmission = useCallback(
      (courses: Course[]) => {
        if (courses.length === 0) {
          addToast({
            type: 'error',
            title: 'Nenhum curso foi selecionado',
            description:
              'Por favor selecione pelo menos um curso para adicionar à turma',
          });

          return;
        }

        const coursesIds = courses.map(course => course.id);
        addCourses(coursesIds);

        (ref as ModalRef).current?.closeModal();
      },
      [addCourses, addToast, ref],
    );

    return (
      <ModalContainer ref={ref}>
        <SearchableList
          dataArray={coursesNotInClass}
          searchMatches={handleSearch}
        >
          {({ data }) => (
            <GenericSelectableList
              dataArray={data}
              onConfirmSelection={handleSubmission}
              listItemComponent={({ data }) => (
                <CoursesListItem>
                  <p className="course-title">{data.title}</p>
                </CoursesListItem>
              )}
            ></GenericSelectableList>
          )}
        </SearchableList>
      </ModalContainer>
    );
  },
);

AddCoursesToClassModal.displayName = 'AddCoursesToClassModal';

export default AddCoursesToClassModal;
