import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';
import GenericFileInput from '../../components/GenericFileInput';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextArea from '../../components/GenericTextArea';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import Course from '../../models/Course';
import useCourses from '../../services/swr/useCourses';
import { ModalContainer } from './EditCourseModal.styles';
import { FormikHelpers } from 'formik';

interface ComponentProps {
  courseInfo: Course;
}

const EditCourseModal = React.forwardRef(
  ({ courseInfo }: ComponentProps, ref) => {
    const { updateCourse, updateCourseThumbnail } = useCourses();
    const thumbnailInputRef = useRef<HTMLInputElement>(null);
    const thumbnailFile = useRef<File | null>(null);

    const onSubmit = useCallback(
      (values: Record<string, unknown>, helpers: FormikHelpers<Record<string, unknown>>) => {
        updateCourse({
          id: courseInfo.id,
          title: values.title as string,
          description: values.description as string,
        });

        if (thumbnailFile.current)
          updateCourseThumbnail(courseInfo.id, thumbnailFile.current);

        if (thumbnailInputRef.current) thumbnailInputRef.current.value = '';

        const { setSubmitting, resetForm } = helpers;

        setSubmitting(false);
        resetForm();

        (ref as ModalRef).current?.closeModal();
      },
      [courseInfo.id, ref, updateCourse, updateCourseThumbnail],
    );
    
    const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );

    return (
      <ModalContainer ref={ref}>
        <GenericModalForm
          initialValues={{
            title: '',
            description: '',
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string(),
            description: Yup.string(),
          })}
          submitButtonType="edit"
          onSubmit={onSubmit}
          onCancel={closeModal}
        >
          <GenericTextInput
            name="title"
            label="Título do Curso"
            type="text"
            placeholder={courseInfo.title}
          />
          <GenericTextArea
            name="description"
            label="Descrição do Curso"
            placeholder={courseInfo.description}
          />
          <GenericFileInput
            ref={thumbnailInputRef}
            name="thumbnail-file"
            setFile={(file: File) => {
              thumbnailFile.current = file;
            }}
            label="Thumbnail do Curso"
          />
        </GenericModalForm>
      </ModalContainer>
    );
  },
);

EditCourseModal.displayName = 'EditCourseModal';

export default EditCourseModal;
