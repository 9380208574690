import React, { useCallback } from 'react';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';
import { ModalRef } from '../../hooks/useModalRef';
import useRankingPage from '../../services/swr/useRanking';
import { FormikHelpers } from 'formik';

interface ComponentProps { 
  schoolId: string;
}
export const ResetRankingModal = React.forwardRef(({ schoolId }: ComponentProps, ref) => {
  const { resetClassesRanking } = useRankingPage(schoolId);
     const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );
    const onSubmit = useCallback((values: Record<string, unknown>, {setSubmitting}:FormikHelpers<Record<string, unknown>>) => {
      const { resetStudentsPoints } = values;
      resetClassesRanking(resetStudentsPoints as boolean);
      
      setSubmitting(false);
      closeModal();
     }, [resetClassesRanking, closeModal]
    );
  
    return (
      <ModalContainer ref={ref}>
        <GenericModalForm
          initialValues={{
            resetStudentsPoints: false,
          }}
          submitButtonType="edit"
          onSubmit={onSubmit}
          onCancel={closeModal}
        >
          <GenericTextInput
            name="resetStudentsPoints"
            label="Resetar Pontuação dos Alunos: "
            type="checkbox"
            placeholder=""
          />
        </GenericModalForm>
      </ModalContainer>
    );
  },
);

ResetRankingModal.displayName = 'ResetRankingModal';


const ModalContainer = styled(GenericModal)`
  width: 80%;
  max-width: 360px;
`;
