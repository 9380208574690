import React, { useCallback } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import GenericSecondaryButton from '../../components/GenericSecondaryButton';

import { AttachmentInformation, AttachmentOptions, PageHeaderContainer, ResponsivityWrapper } from './AttachmentDetailsPageHeader.styles';
import useModalRef from '../../hooks/useModalRef';
import Attachment from '../../models/Attachment';
import useAttachments from '../../services/swr/useAttachments';

interface ComponentProps {
  attachmentInfo: Attachment;
}

const AttachmentDetailsPageHeader = ({ attachmentInfo }: ComponentProps) => {
  const { deleteAttachment } = useAttachments();
  
  const { push } = useHistory();

  const deleteAttachmentPromptRef = useModalRef();

  const size = Math.floor(Number(attachmentInfo.fileSize) / 100000);
  const fileType = attachmentInfo.fileType.split("/")[1].toUpperCase();
  const createdAt = new Date(attachmentInfo.createdAt).toLocaleDateString('pt-BR');


  const handleDeleteCourse = useCallback(() => {
    deleteAttachment(attachmentInfo.id);
    push('/attachments-management');
  }, [attachmentInfo, deleteAttachment, push]);

  return (
    <PageHeaderContainer>
      <ResponsivityWrapper>
        <AttachmentInformation>
          <h1 className="attachment-name">{attachmentInfo.title}</h1>
          <div className="complementary-info">
            <p><strong>Tamanho: </strong>{size}MB</p>
            <p><strong>Formato: </strong>{fileType}</p>
            <p><strong>Criado em: </strong>{createdAt}</p>
          </div>
        </AttachmentInformation>
      </ResponsivityWrapper>

      <AttachmentOptions>
        <GenericSecondaryButton
          className="delete-button"
          onClick={() => deleteAttachmentPromptRef.current?.openModal()}
        >
          <MdDeleteForever className="button-icon" size={24} />
          <p>Deletar</p>
        </GenericSecondaryButton>
      </AttachmentOptions>

      <ConfirmationPrompt
        ref={deleteAttachmentPromptRef}
        promptText={`Tem certeza que quer deletar "${attachmentInfo.title}" permanentemente?`}
        onConfirm={handleDeleteCourse}
      />
      
    </PageHeaderContainer>
  );
};

export default AttachmentDetailsPageHeader;
