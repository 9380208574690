import styled from 'styled-components';

export const ResponsivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const UserAvatar = styled.img`
  max-width: 240px;
  margin-bottom: 16px;

  @media screen and (min-width: 800px) {
    margin-right: 32px;
    margin-bottom: 0;
  }
`;

export const UserInfo = styled.article`
  text-align: center;

  @media screen and (min-width: 800px) {
    text-align: left;
  }

  .name,
  .email {
    font-family: 'Roboto', sans-serif;
  }

  .name {
    font-size: 1.953rem;
    font-weight: 500;
  }

  .email {
    font-size: 1.563rem;
  }
`;
