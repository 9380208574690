import styled from 'styled-components';
import { ContentWithFooter } from '../../components/Footer';
import { TabNavigator } from '../../components/TabNavigator';

export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
`;

export const SchoolsDetailsTabNavigator = styled(TabNavigator)`
  margin-top: 64px;
`;
