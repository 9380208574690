import React, { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import { LessonsAssignmentClicksOnDate } from '../../models/ClassAnalytics';
import useClassAnalytics from '../../services/swr/useClassAnalytics';
import { secondsToRoundMinutes } from '../../utils/time';
import {
  AssignmentClicksChartSection,
  LessonsAndAssignmentsDataSection,
  MetricCard,
} from './ClassMetricsTab.styles';

interface ComponentProps {
  classId: string;
}

function ratioToRoundPercentage(ratio: number): string {
  return `${Math.round(ratio * 100)}%`;
}

const ClassMetricsTab = ({ classId }: ComponentProps) => {
  const { classAnalytics } = useClassAnalytics(classId);

  const chartData = useMemo(
    () => [
      {
        label: 'Cliques',
        data: classAnalytics?.lessonsAssignmentClicksOverTime || [],
      },
    ],
    [classAnalytics?.lessonsAssignmentClicksOverTime],
  );

  const primaryAxis = useMemo(
    (): AxisOptions<LessonsAssignmentClicksOnDate> => ({
      getValue: d => new Date(d.date),
      scaleType: 'time',
      maxBandSize: 5,
      formatters: {
        /* Somehow date is sometimes `null`, still trying to figure out why
         * but for now this is a workaround. My current theory is that if
         * `chartData.data` is null, then `primaryAxis.getValue` is called
         * with `null` as the argument. */
        scale: date => date?.toLocaleDateString(),
      },
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<LessonsAssignmentClicksOnDate>[] => [
      {
        getValue: d => d.clicks,
        scaleType: 'linear',
      },
    ],
    [],
  );

  return (
    <>
      {classAnalytics && (
        <>
          <LessonsAndAssignmentsDataSection>
            <MetricCard>
              <div className="data">
                <p>{classAnalytics.totalAssignmentClicks}</p>
              </div>
              <p className="metric-name">Cliques em Atividade</p>
            </MetricCard>
            <MetricCard>
              <div className="data">
                <p>{secondsToRoundMinutes(classAnalytics.averageWatchTime)}</p>
              </div>
              <p className="metric-name">Tempo Médio em Aula</p>
            </MetricCard>
            <MetricCard>
              <div className="data">
                <p>{classAnalytics.totalFullyWatchedLessons}</p>
              </div>
              <p className="metric-name">Total de Aulas Assistidas</p>
            </MetricCard>
            <MetricCard>
              <div className="data">
                <p>
                  {ratioToRoundPercentage(
                    classAnalytics.averageFullyWatchedLessons,
                  )}
                </p>
              </div>
              <p className="metric-name">Média de Aulas Assistidas</p>
            </MetricCard>
          </LessonsAndAssignmentsDataSection>

          <AssignmentClicksChartSection>
            <h1 className="chart-title">Cliques em Atividades</h1>
            <div className="chart-container">
              <Chart
                options={{
                  data: chartData,
                  primaryAxis,
                  secondaryAxes,
                }}
              />
            </div>
          </AssignmentClicksChartSection>
        </>
      )}
    </>
  );
};

export default ClassMetricsTab;
