import React, { /*useCallback,*/ useEffect } from 'react';
import StylableComponent from '../StylableComponent';
import {
  Navigation,
  NavigationDivider,
  TabNavigatorContainer,
} from './TabNavigator.styles';

// Interface para definir as propriedades adicionais que serão adicionadas ao clonar o elemento
interface TabProps {
  _isActive?: boolean;
  _onClick?: () => void;
}

const TabNavigator  = ({ className, children }: StylableComponent) => {
  const [activeTab, setActiveTab] = React.useState('');
  /*
  const isValidTab = useCallback(
    (child: React.ReactElement) =>
      child.type === 'Tab' ||
      (typeof child.type !== 'string' && child.type.name === 'Tab'),
    [],
  );
  */

  useEffect(() => {
    React.Children.forEach(children, (child, index) => {
      if (
        React.isValidElement(child) &&
        index === 0
      ) {
        setActiveTab(child.props.label);
      }
    });
  }, [children]);

  return (
    <TabNavigatorContainer className={className}>
      <Navigation>
        {React.Children.map(children, child => {
          if (React.isValidElement(child))
            return React.cloneElement(child, {
              key: child.props.label,
              ...child.props, // Mantém as outras propriedades do elemento
              ...{
                _isActive: child.props.label === activeTab,
                _onClick: () => setActiveTab(child.props.label),
              } as TabProps, // Tipo da interface definida
            });

          return null;
        })}
      </Navigation>
      <NavigationDivider />
      <div>
        {React.Children.map(children, child => {
          if (React.isValidElement(child) /* && isValidTab(child) */ && child.props.label === activeTab)
            return child.props.children;

          return null;
        })}
      </div>
    </TabNavigatorContainer>
  );
};

export default TabNavigator;
