import React from 'react';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import {
  QuizDescriptionContainer,
  QuizInfo,
  QuizLink,
  QuizListItemContainer,
} from './QuizListItem.styles';
import { Quiz } from '../../models/Quiz';
import useQuiz from '../../services/swr/useQuiz';
import useToast from '../../hooks/useToast';
import { Tags, TagsContainer } from '../../styles/GenericComponentStyles';

interface ComponentProps {
  quizInfo: Quiz;
}

const QuizListItem = ({ quizInfo }: ComponentProps) => {
  const { push } = useHistory();
  const { removeQuiz } = useQuiz();
  const { addToast } = useToast();

  const handleDeleteQuiz = async (id: string) => {
    try {
      await removeQuiz(id);
      addToast({
        type: 'success',
        title: 'Questionário removido',
        description: 'O questionário foi removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover questionário',
        description:
          'Ocorreu um erro ao remover o questionário, tente novamente',
      });
    }
  };

  return (
    <QuizListItemContainer>
      <QuizLink to={`/quiz-details/${quizInfo.id}`}>
        <QuizInfo>
          <p className="school-title">
            <strong>{quizInfo.title}</strong>
          </p>
          {/* {quizInfo.title && <p title={quizzInfo.title} className="school-address">{quizInfo.title.slice(0, 30)}...</p>} */}

          <QuizDescriptionContainer>
            <p>{quizInfo.description}</p>
            <TagsContainer>
              {quizInfo.tags &&
                quizInfo.tags.split(',').map(tag => (
                  <Tags tagColor={tag} key={tag}>
                    {tag}
                  </Tags>
                ))}
            </TagsContainer>
          </QuizDescriptionContainer>
        </QuizInfo>
      </QuizLink>

      <MutableListItemOptions
        objectName={quizInfo.title}
        onEdit={() => push(`/quiz-details/${quizInfo.id}`)}
        onRemove={() => handleDeleteQuiz(quizInfo.id)}
      />
    </QuizListItemContainer>
  );
};

export default QuizListItem;
