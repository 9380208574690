import styled from 'styled-components';
import ContentWrapper from '../ContentWrapper';
import StrippedButton from '../StrippedButton';

export const FooterContainer = styled.footer`
  background-color: var(--color-dark-green);
  margin-top: 112px;
`;

export const FooterContent = styled(ContentWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: var(--font-sans);
  color: var(--color-white);

  padding: 16px 0;
`;

export const ScrollToTopButton = styled(StrippedButton)`
  font-weight: 500;
  color: var(--color-white);
`;
