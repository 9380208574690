import styled from 'styled-components';
import Color from '../../styles/colors';

const colorGreyRgba = new Color('grey').withAlpha(0.5).rgba();

export const CourseSelector = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;

  .selected-course {
    font-family: var(--font-sans);
    font-size: 1.953rem;
    font-weight: 500;
    color: var(--color-black);
  }

  .course-selector {
    font-family: var(--font-sans);
    font-size: 1rem;
    color: var(--color-black);

    padding: 8px 16px;
    border: 1px solid ${colorGreyRgba};
    border-radius: 8px;

    background-color: unset;
    box-shadow: 0 0 4px 0 ${colorGreyRgba};

    &:hover {
      cursor: pointer;
    }
  }
`;
