import styled from 'styled-components';

export const FreeTrialTableRowContainer = styled.tr`
  display: table-row;

  font-family: var(--font-sans);
  font-size: 1.25rem;
  color: var(--color-black);

  .parent-name,
  .student-name {
    font-weight: 500;
  }

  .parent-email,
  .parent-phone,
  .student-academic-year {
    font-weight: 300;
  }
`;

export const CellContent = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
