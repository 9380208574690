import React, { useCallback, useMemo } from 'react';
import { ModalRef } from '../../hooks/useModalRef';
import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';
import SearchableList from '../../components/SearchableList';
import GenericSelectableList from '../../components/GenericSelectableList';
import useToast from '../../hooks/useToast';
import { Question } from '../../models/Question';
import useQuestions from '../../services/swr/useQuestions';
import { mutate } from 'swr';

interface ComponentProps {
  quizId: string;
  quizQuestions: Question[];
}

const LessonListItem = ({ data }: { data: Question }) => {
  
  const answer = data[data.target as keyof Question];
  return (
    <ListItemContainer>
      <p className="question-description">{data.description}</p>
      <p title={data.description} className="correct-option">Resposta: {answer}</p>
    </ListItemContainer>
  );
};
const AddQuestionToQuizModal = React.forwardRef(
  ({ quizId, quizQuestions } : ComponentProps, ref) => {
    const { addToast } = useToast();
    const { questions, addQuestionToQuiz } = useQuestions();


    const questionsNotInQuiz = useMemo<Question[]>(() => {
      const quizQuestionsIds = quizQuestions.map(c => c.id);
      const questionsNotInQuiz = questions.filter(
        question => !quizQuestionsIds.includes(question.id),
      );

      return questionsNotInQuiz;
    }, [quizQuestions, questions]);


    const handleSearch = (search: string, question: Question) => {
      const treatedQuestionInfo = Object.values(question).join(' ').toLowerCase();

      return treatedQuestionInfo.includes(search.toLowerCase());
    };

    const handleSubmission = useCallback(
     async (data: Question[]) => {
        if (data.length === 0) {
          addToast({
            type: 'error',
            title: 'Nenhuma questão foi selecionada',
            description:
              'Por favor selecione pelo menos um questão para adicionar ao Quiz',
          });

          return;
        }

        await addQuestionToQuiz(data.map(d => d.id), quizId );

        if (ref) {
          (ref as ModalRef).current?.closeModal();
        }
        addToast({
          type: 'success',
          title: 'Questões adicionadas',
          description: 'As questões foram adicionadas ao Quiz.',
        });
        mutate('/question');
      },
      [addQuestionToQuiz, addToast, ref, quizId],
    );

    return (
      <ModalContainer ref={ref}>
        <SearchableList
          dataArray={questionsNotInQuiz}
          searchMatches={handleSearch}
        >
          {({ data }) => (
            <GenericSelectableList
              dataArray={data}
              confirmSelectionButtonText="Adicionar Questões"
              onConfirmSelection={handleSubmission}
              listItemComponent={LessonListItem}
            />
          )}
        </SearchableList>
      </ModalContainer>
    );
  },
);


AddQuestionToQuizModal.displayName = 'AddQuestionToQuizModal';

export default AddQuestionToQuizModal;

const ModalContainer = styled(GenericModal)`
  width: 50%;
`;

const ListItemContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
  }

  .correct-option {
    margin-top: 1rem;
    font-size: 0.875rem;
  }

  .question-description {
    font-weight: bold;
    font-size: 1rem;
    font-family: var(--font-sans);
  }
`;

