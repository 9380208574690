import React from 'react';
import LogoPane from './components/LogoPane';
import SignInForm from './components/SignInForm';

import { SignInContainer } from './styles';

const SignIn = () => {
  return (
    <SignInContainer>
      <div className="login-form-background ">
        <SignInForm />
      </div>
      <LogoPane />
    </SignInContainer>
  );
};

export default SignIn;
