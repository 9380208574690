import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import UserMenu from '../UserMenu';

import { BurguerMenuButton, Menu } from './styles';

interface BurguerMenuComponentProps {
  className?: string;
}

const BurguerMenu = ({ className = '' }: BurguerMenuComponentProps) => {
  const [shouldShowMenu, setShouldShowMenu] = useState(false);

  return (
    <div className={className}>
      <BurguerMenuButton onClick={() => setShouldShowMenu(true)}>
        <div className="burguer-menu-bar" />
        <div className="burguer-menu-bar" />
        <div className="burguer-menu-bar" />
      </BurguerMenuButton>

      <Menu shouldShow={shouldShowMenu}>
        <button
          type="button"
          className="close-button"
          onClick={() => setShouldShowMenu(false)}
        >
          <MdClose />
        </button>
        <UserMenu />
      </Menu>
    </div>
  );
};


export default BurguerMenu;
