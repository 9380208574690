import React from 'react';
import FreeTrialRequest from '../../models/FreeTrialRequest';
import {
  CellContent,
  FreeTrialTableRowContainer,
} from './FreeTrialTableRow.styles';

interface ComponentProps {
  freeTrialInfo: FreeTrialRequest;
}

const FreeTrialTableRow = ({ freeTrialInfo }: ComponentProps) => {
  return (
    <FreeTrialTableRowContainer>
      <td>
        <CellContent>
          <p className="parent-name">{freeTrialInfo.parentName || '-'}</p>
          <p className="parent-email">{freeTrialInfo.parentEmail || '-'}</p>
          <p className="parent-phone">{freeTrialInfo.parentPhone || '-'}</p>
        </CellContent>
      </td>
      <td>
        <CellContent>
          <p className="student-name">{freeTrialInfo.studentName}</p>
          <p className="student-academic-year">
            {freeTrialInfo.studentAcademicYear}
          </p>
        </CellContent>
      </td>
      <td className="spacer" style={{ width: 100 }} />
      <td>
        {Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'long',
          timeStyle: 'short',
        }).format(new Date(freeTrialInfo.date))}
      </td>
    </FreeTrialTableRowContainer>
  );
};

export default FreeTrialTableRow;
