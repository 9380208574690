import React from 'react';
import { SectionContainer } from './General.styles';
import useClasses from '../../services/swr/useClasses';
import useStudents from '../../services/swr/useStudents';
import useCourses from '../../services/swr/useCourses';

const General = () => {

  const { students } = useStudents();
  const { classes } = useClasses();
  const { courses } = useCourses();
    
  return (

    <SectionContainer>
      <div
        style={{
          // backgroundColor: 'darkblue',
          height: '500px',
          gridArea: 'classes',
        }}
      >
        <h1>Turmas <span className="rounded-area">{classes.length}</span></h1>
      </div>
      <div
        style={{
          // backgroundColor: 'darkblue',
          height: '500px',
          gridArea: 'class-details',
        }}
      ><h1 className="provisory-student">Estudantes <span className="rounded-area">{students.length}</span></h1></div>
      <div
        style={{
          // backgroundColor: 'darkgreen',
          height: '500px',
          gridArea: 'analytics',
        }}
      >
        <h1>Cursos <span className="rounded-area">{courses.length}</span></h1>     
      </div>
    </SectionContainer>
  );
};

export default General;
