import React, { useCallback, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useFilter from '../../hooks/useFilter';
import useModalRef from '../../hooks/useModalRef';
import Class from '../../models/Class';
import useClasses from '../../services/swr/useClasses';
import ClassListItem from './ClassListItem';

import {
  ClassListControls,
  ClassList,
  PageContent,
  PageHeader,
} from './ClassManagementPage.styles';
import CreateClassModal from './CreateClassModal';

const ClassManagementPage = () => {
  const { classes } = useClasses();
  const {
    filter,
    clearFilter,
    filteredData: filteredClasses,
  } = useFilter<Class>(classes);
  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const createClassModalRef = useModalRef();

  const onSearch = useCallback(
    (search: string) => {
      const treatedSearch = search.toLowerCase().trim();

      filter(classItem => {
        const treatedClassName = classItem.title.toLowerCase();

        if (treatedClassName && treatedClassName.includes(treatedSearch))
          return true;

        return false;
      });

      setIsUsingSearch(true);
    },
    [filter],
  );

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento de Turmas</h1>
        </PageHeader>

        <ClassListControls
          onSearch={onSearch}
          onClearSearch={clearFilter}
          onAdd={() => createClassModalRef.current?.openModal()}
        />

        <CreateClassModal ref={createClassModalRef} />

        <ClassList>
          {(isUsingSearch ? filteredClasses : classes).map(classObj => (
            <ClassListItem key={classObj.id} classInfo={classObj} />
          ))}
        </ClassList>
      </PageContent>
    </>
  );
};

export default ClassManagementPage;
