import React, { Fragment } from 'react';
import GenericDivider from '../../components/GenericDivider';
import SearchableList from '../../components/SearchableList';
import useModalRef from '../../hooks/useModalRef';

import { AttachmentListContainer } from '../AttachmentManagementPage/AttachmentListItem.styles';
import useQuiz from '../../services/swr/useQuiz';
import QuizQuestionListItem from './QuizQuestionListItem';
import { Question } from '../../models/Question';
import AddQuestionToQuizModal from './AddQuestionToQuizModal';

interface ComponentProps {
  quizId: string;
}

const QuizQuestionsList = ({ quizId }: ComponentProps) => {
  const { quizzes } = useQuiz();

  const addQuestionToQuizModalRef = useModalRef();

  const questions = quizzes.find(quiz => quiz.id === quizId)?.questions || [];

  const handleSearch = (search: string, question: Question) => {
    const treatedQuestionInfo = Object.values(question)
      .join(' ')
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return treatedQuestionInfo.includes(search.toLowerCase());
  };

  return (
    <>
      <SearchableList
        dataArray={questions}
        searchMatches={handleSearch}
        onAdd={() => addQuestionToQuizModalRef.current?.openModal()}
      >
        {({ data }) => (
          <>
            {data.length > 0 ? (
              <AttachmentListContainer>
                {data.map((quizQuestions, index) => (
                  <Fragment key={quizQuestions.id}>
                    <QuizQuestionListItem
                      questionInfo={quizQuestions}
                      quizId={quizId}
                    />
                    {index < data.length - 1 && <GenericDivider />}
                  </Fragment>
                ))}
              </AttachmentListContainer>
            ) : (
              <p>Nenhuma questão encontrada</p>
            )}
          </>
        )}
      </SearchableList>

      {quizzes.length > 0 && (
        <AddQuestionToQuizModal
          ref={addQuestionToQuizModalRef}
          quizId={quizId}
          quizQuestions={questions}
        />
      )}
    </>
  );
};

export default QuizQuestionsList;
