import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { Modal, ModalRef } from '../../hooks/useModalRef';
import useTeachers from '../../services/swr/useTeachers';
import { ModalContainer, ModalContent } from './AddTeacherModal.styles';
import { CreateTeacherDTO } from '../../models/Teacher';
import { FormikHelpers } from 'formik';



const AddTeacherModal = React.forwardRef<Modal, object>((_, ref) => {
  const { addTeacher } = useTeachers();

  const closeModal = useCallback(
    () => (ref as ModalRef).current?.closeModal(),
    [ref],
  );

  const onSubmit = useCallback(
    async (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>) => {
      const addTeacherDTO = {
        name: values.name,
        email: values.email,
        password: values.password,
      } as CreateTeacherDTO;
      await addTeacher(addTeacherDTO);

      setSubmitting(false);
      resetForm();
      closeModal();
    },
    [addTeacher, closeModal],
  );

  return (
    <ModalContainer ref={ref}>
      <ModalContent>
        <h1 className="modal-title">Adicionar Professor</h1>

        <GenericModalForm
          initialValues={{
            name: '',
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .required('e-Mail obrigatório')
              .email('Digite um e-Mail válido'),
            password: Yup.string()
              .required('Senha obrigatória')
              .min(6, 'Senha deve conter ao menos 6 caracteres'),
          })}
          onSubmit={onSubmit}
          onCancel={closeModal}
          submitButtonType="create"
        >
          <GenericTextInput
            name="name"
            label="Nome"
            type="text"
            placeholder="João da Silva"
          />
          <GenericTextInput
            name="email"
            label="e-Mail"
            type="email"
            placeholder="email@exemplo.com.br"
          />
          <GenericTextInput
            name="password"
            label="Senha"
            type="password"
            placeholder="correct-horse-battery-staple"
          />
        </GenericModalForm>
      </ModalContent>
    </ModalContainer>
  );
});

AddTeacherModal.displayName = 'AddTeacherModal';

export default AddTeacherModal;
