import React, { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import useCourseLessonsFeedbacks from '../../services/swr/useCourseLessonsFeedbacks';
import { RatingsChartSection } from './CourseMetricsTab.styles';

interface ComponentProps {
  courseId: string;
}

interface LessonRatings {
  lessonTitle: string;
  ratings: number[];
}

interface ChartData {
  [key: string]: LessonRatings;
}

const CourseMetricsTab = ({ courseId }: ComponentProps) => {
  const { feedbacks } = useCourseLessonsFeedbacks(courseId);

  const chartData = useMemo(() => {
    const data: ChartData = {};

    for (let i = 0; i < feedbacks.length; ++i) {
      const lessonFeedback = feedbacks[i];

      if (!data[lessonFeedback.lesson.id]) {
        data[lessonFeedback.lesson.id] = {
          lessonTitle: lessonFeedback.lesson.title,
          ratings: [],
        };
      }

      data[lessonFeedback.lesson.id].ratings.push(lessonFeedback.rating);
    }

    return [
      {
        label: 'Avaliação',
        data: Object.values(data),
      },
    ];
  }, [feedbacks]);

  const primaryAxis = useMemo(
    (): AxisOptions<LessonRatings> => ({
      getValue: d => d.lessonTitle,
      scaleType: 'band',
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<LessonRatings>[] => [
      {
        getValue: d => d.ratings.reduce((a, b) => a + b, 0) / d.ratings.length,
        scaleType: 'linear',
        max: 5,
        min: 0,
      },
    ],
    [],
  );

  return (
    <RatingsChartSection>
      <h1 className="chart-title">Avaliações em Aulas</h1>
      <div className="chart-container">
        <Chart
          options={{
            data: chartData,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </div>
    </RatingsChartSection>
  );
};

export default CourseMetricsTab;
