import React from 'react';
import { useField } from 'formik';
import { TextInputLabel } from './GenericCheckbox.styles';
import InputError from '../InputError';
import StylableComponent from '../StylableComponent';

interface ComponentProps extends StylableComponent {
  id?: string;
  name: string;
  text: string;
}

const GenericCheckbox = ({
  id = undefined,
  name,
  text,
  className
}: ComponentProps) => {
  const [fieldProps, fieldMeta] = useField({
    id,
    name,
  });

  return (
    <TextInputLabel htmlFor={name} className={className}>
      {fieldMeta.touched && fieldMeta.error && (
        <InputError>{fieldMeta.error}</InputError>
      )}
      <input className="generic-checkbox" type="checkbox" {...fieldProps} />
      {text}
    </TextInputLabel>
  );
};



export default GenericCheckbox;
