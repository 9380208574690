export function secondsToRoundMinutes(seconds: number): string {
  const minutes = Math.round(seconds / 60);
  return `${minutes} min`;
}

export function secondsToHHMMString(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours.toString().padStart(1, '0')}:${minutes
    .toString()
    .padStart(2, '0')}h`;
}
