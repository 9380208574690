import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericTextInput from '../../components/GenericTextInput';
import useSchools from '../../services/swr/useSchools';
import School, { UpdateSchoolDTO } from '../../models/School';
import { ModalContainer, ModalContent } from './EditSchoolModal.styles';
import { ModalRef } from '../../hooks/useModalRef';
import { stripEmptyFields } from '../../utils/objects';
import GenericModalForm from '../../components/GenericModalForm';
import { FormikHelpers } from 'formik';

interface ComponentProps {
  schoolInfo: School;
}

const EditSchoolModal = React.forwardRef<unknown, ComponentProps>(
  ({ schoolInfo }, ref) => {
    const { updateSchool } = useSchools();

    const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );

    const onSubmit = useCallback(
      async (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>> ) => {
        const updateSchoolDTO: UpdateSchoolDTO = {
          id: schoolInfo.id,
          ...stripEmptyFields(values),
        };
        updateSchool(updateSchoolDTO);

        (ref as ModalRef).current?.closeModal();

        setSubmitting(false);
        resetForm();
      },
      [schoolInfo.id, ref, updateSchool],
    );

    return (
      <ModalContainer ref={ref}>
        <ModalContent>
          <h1 className="modal-title">Editar Escola</h1>

          <GenericModalForm
            initialValues={{
              name: '',
              label: '',
              address: '',
              phone: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Campo obrigatório'),
              label: Yup.string().required('Campo obrigatório'),
              address: Yup.string(),
              phone: Yup.string()
            })}
            submitButtonType="edit"
            onSubmit={onSubmit}
            onCancel={closeModal}
          >
            <GenericTextInput
              name="name"
              label="Nome da Escola"
              type="text"
              placeholder={schoolInfo.name || 'Jardim de Infância'}
            />
            <GenericTextInput
              name="label"
              label="Código da Escola"
              type="text"
              placeholder={schoolInfo.name.split(' ')[0] || "JARDIMINFANCIA"}
            />
            <GenericTextInput
              name="address"
              label="Endereço "
              type="text"
              placeholder={schoolInfo.address || 'Rua das Flores, 123'}
            />

             <GenericTextInput
              name="phone"
              label="Telefone"
              type="text"
              placeholder={schoolInfo.phone || '31912345678'}
            />
          </GenericModalForm>
        </ModalContent>
      </ModalContainer>
    );
  },
);

EditSchoolModal.displayName = 'EditSchoolModal';

export default EditSchoolModal;
