import React, { Fragment, useCallback, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useFilter from '../../hooks/useFilter';


import GenericDivider from '../../components/GenericDivider';
import useModalRef from '../../hooks/useModalRef';
import { AttachmentList, AttachmentListControls, PageContent, PageHeader } from './AttachmentManagementPage.styles';
import CreateAttachmentModal from './CreateAttachmentModal';
import Attachment from '../../models/Attachment';
import useAttachments from '../../services/swr/useAttachments';
import AttachmentListItem from './AttachmentListItem';

const AttachmentManagementPage = () => {
  const { attachments } = useAttachments();
  const {
    filter,
    clearFilter,
    filteredData: filteredAttachments,
  } = useFilter<Attachment>(attachments);

  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const createAttachmentModalRef = useModalRef();

  const handleSearch = useCallback(
    async (search: string) => {
      const treatedSearch = search.toLowerCase().trim();

      filter(attachment => {
        const treatedAttachmentName = attachment.title?.toLowerCase();

        if (treatedAttachmentName && treatedAttachmentName.includes(treatedSearch)) {
            return true;
        }

        return false;
      });

      setIsUsingSearch(true);
    },
    [filter],
  );

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento de Anexos</h1>
        </PageHeader>

        <AttachmentListControls
          onSearch={handleSearch}
          onClearSearch={clearFilter}
          onAdd={() => createAttachmentModalRef.current?.openModal()}
        />

        <CreateAttachmentModal ref={createAttachmentModalRef} />

        <AttachmentList>
          {(isUsingSearch ? filteredAttachments : attachments).map((attachment: Attachment, index: number) => (
            <Fragment key={attachment.id}>
              <AttachmentListItem attachmentInfo={attachment} />
              {index <
                (isUsingSearch ? filteredAttachments : attachments).length - 1 && (
                <GenericDivider />
              )}
            </Fragment>
          ))}
        </AttachmentList>
      </PageContent>
    </>
  );
};

export default AttachmentManagementPage;
