import React from 'react';

import { SectionHeaderContainer } from './styles';

interface ComponentProps {
  color?: string;
  className?: string;
  children: React.ReactNode;
}

const SectionHeader = ({
  color = undefined,
  className = '',
  children,
}: ComponentProps) => {
  return (
    <SectionHeaderContainer className={className} color={color}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
           /*
         * Checks if the component is valid (avoiding typescript problems) and
         * if the component is some variant of a header (h1, h2, h3 etc.). This
         * way anything else (like icons and such) doesn't get targeted by rules
         * intended to be applied on text.
         */
          const validChild = child as React.ReactElement;
          return React.cloneElement(validChild, {
            className: `${
              validChild.type.toString().startsWith('h') ? 'title' : ''
            } ${index > 0 ? 'space-left' : ''} ${validChild.props.className}`,
          });
        }
        return child;
      })}
    </SectionHeaderContainer>
  );
};



export default SectionHeader;
