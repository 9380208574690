import styled from 'styled-components';
import MutableListItemOptions from '../../components/MutableListItemOptions';

export const ListItemContainer = styled.li`
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ResponsivityWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const LessonThumbnail = styled.img`
  max-width: 160px;
  border-radius: 8px;

  margin-right: 32px;

  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

export const LessonInformation = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  margin-right: 16px;

  .title-visibility-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .lesson-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-right: 8px;
  }

  .visibility-icon {
    min-width: 16px;
    min-height: 16px;
  }

  .lesson-description {
    font-weight: 300;
  }
`;

export const ItemOptions = styled(MutableListItemOptions)`
  margin-left: auto;
`;
