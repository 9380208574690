import React, { MouseEventHandler } from 'react';
import StylableComponent from '../StylableComponent';

import { ButtonContainer } from './GenericPrimaryButton.styles';

interface ComponentProps extends StylableComponent {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
  children: React.ReactNode;
}

const GenericPrimaryButton = ({
  className,
  onClick =  () => {},
  type = 'button',
  children,
}: ComponentProps) => {
  return (
    <ButtonContainer type={type} className={className} onClick={onClick}>
      {children}
    </ButtonContainer>
  );
};



export default GenericPrimaryButton;
