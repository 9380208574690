import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import useStudents from '../../services/swr/useStudents';
import { ModalContainer, ModalContent } from './AddStudentModal.styles';
import { FormikHelpers } from 'formik';
import { CreateStudentDTO } from '../../models/Student';

const AddStudentModal = React.forwardRef((_, ref) => {
  const { addStudent } = useStudents();

  const closeModal = useCallback(
    () => (ref as ModalRef).current?.closeModal(),
    [ref],
  );

  const onSubmit = useCallback(
    async (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>> ) => {
      const addStudentDTO = {
        name: values.name,
        email: values.email,
        password: values.password,
      } as CreateStudentDTO;
      await addStudent(addStudentDTO);

      setSubmitting(false);
      resetForm();
      closeModal();
    },
    [addStudent, closeModal],
  );

  return (
    <ModalContainer ref={ref}>
      <ModalContent>
        <h1 className="modal-title">Adicionar Aluno</h1>

        <GenericModalForm
          initialValues={{
            name: '',
            email: '',
            password: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            email: Yup.string()
              .required('e-Mail obrigatório')
              .email('Digite um e-Mail válido'),
            password: Yup.string()
              .required('Senha obrigatória')
              .min(6, 'Senha deve conter ao menos 6 caracteres'),
          })}
          onSubmit={onSubmit}
          onCancel={closeModal}
          submitButtonType="create"
        >
          <GenericTextInput
            name="name"
            label="Nome"
            type="text"
            placeholder="João da Silva"
          />
          <GenericTextInput
            name="email"
            label="Email"
            type="email"
            placeholder="email@exemplo.com"
          />
          <GenericTextInput
            name="password"
            label="Senha"
            type="password"
            placeholder="********"
          />
        </GenericModalForm>
      </ModalContent>
    </ModalContainer>
  );
});

AddStudentModal.displayName = 'AddStudentModal';

export default AddStudentModal;
