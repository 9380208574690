function extractVideoIdFromUrl(url: string): string {
  if (url.includes('watch?v=')) {
    return url.split('watch?v=')[1].split('&')[0];
  }

  const videoId = url.split('/').pop();

  return videoId as string;
}

export function convertUrlToEmbedUrl(url: string): string {
  const videoId = extractVideoIdFromUrl(url);

  return `https://www.youtube.com/embed/${videoId}`;
}
