import React, { useCallback } from 'react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import GenericSecondaryButton from '../../components/GenericSecondaryButton';
import useCourses from '../../services/swr/useCourses';
import Course from '../../models/Course';
import {
  CourseInformation,
  CourseOptions,
  CourseThumbnail,
  PageHeaderContainer,
  ResponsivityWrapper,
} from './CourseDetailsPageHeader.styles';
import useCourseLessons from '../../services/swr/useCourseLessons';
import EditCourseModal from './EditCourseModal';
import useModalRef from '../../hooks/useModalRef';
import EditCourseLessonsPointsModal from './EditCourseLessonsPointsModal';

interface ComponentProps {
  courseInfo: Course;
}

const CourseDetailsPageHeader = ({ courseInfo }: ComponentProps) => {
  const { deleteCourse } = useCourses();
  const { getLessonCount } = useCourseLessons(courseInfo.id);
  const { push } = useHistory();

  const deleteCoursePromptRef = useModalRef();
  const editCourseModalRef = useModalRef();
  const editCourseLessonsPointsModalRef = useModalRef();

  const handleDeleteCourse = useCallback(() => {
    deleteCourse(courseInfo.id);
    push('/course-management');
  }, [courseInfo, deleteCourse, push]);

  return (
    <PageHeaderContainer>
      <ResponsivityWrapper>
        <CourseThumbnail
          src={
            courseInfo.thumbnailUrl ||
            'https://www.pngkey.com/png/full/274-2744640_16-9-placeholder-topaz-c7510-cover-4-11.png'
          }
          alt="Thumbnail do Curso"
        />

        <CourseInformation>
          <h1 className="course-name">{courseInfo.title}</h1>
          <div className="complementary-info">
            <p>{getLessonCount() ?? '---'} aulas</p>
            <p>{null ?? '---'} professores</p>
          </div>
          <p className="course-description">{courseInfo.description}</p>
        </CourseInformation>
      </ResponsivityWrapper>

      <CourseOptions>
        <GenericSecondaryButton
          className="delete-button"
          onClick={() => deleteCoursePromptRef.current?.openModal()}
        >
          <MdDeleteForever className="button-icon" size={24} />
          <p>Deletar</p>
        </GenericSecondaryButton>
        <GenericSecondaryButton
          className="edit-button"
          onClick={() => editCourseModalRef.current?.openModal()}
        >
          <MdEdit className="button-icon" size={24} />
          <p>Editar</p>
        </GenericSecondaryButton>
         <GenericSecondaryButton
          className="bulk-edit-button"
          onClick={() => editCourseLessonsPointsModalRef.current?.openModal()}
        >
          <img
            src="/assets/coin.svg"
            alt="ícone de moeda"
            className="button-icon"
            width={22}
          />
          <p>Editar Todos</p>
        </GenericSecondaryButton>
      </CourseOptions>

      <ConfirmationPrompt
        ref={deleteCoursePromptRef}
        promptText={`Tem certeza que quer deletar "${courseInfo.title}" permanentemente?`}
        onConfirm={handleDeleteCourse}
      />
      
      <EditCourseLessonsPointsModal ref={editCourseLessonsPointsModalRef} courseInfo={courseInfo} />

      <EditCourseModal ref={editCourseModalRef} courseInfo={courseInfo} />
    </PageHeaderContainer>
  );
};

export default CourseDetailsPageHeader;
