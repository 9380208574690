import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
`;

export const QuizThumbnail = styled.img`
  width: 100%;
  max-width: 296px;
  margin-bottom: 24px;
  border-radius: 8px;

  @media screen and (min-width: 800px) {
    max-width: 240px;
    margin-right: 32px;
    margin-bottom: 0;
  }
`;

export const ResponsivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export const QuizInformation = styled.div`
  margin-right: 24px;
  margin-bottom: 24px;

  & strong {
    color: var(--color-dark-blue);
  }

  @media screen and (min-width: 480px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 800px) {
    margin-right: 0;
  }

  .quiz-name,
  .quiz-description,
  .complementary-info {
    font-family: var(--font-sans);
    color: var(--color-black);
  }

  .quiz-name {
    font-size: 1.953rem;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .complementary-info {
    display: flex;
    font-size: 1.25rem;
    margin-bottom: 16px;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  }

  .quiz-description {
    font-weight: 300;
  }
`;

export const QuizOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 880px) {
    flex-direction: row;
  }

  @media screen and (min-width: 480px) {
    margin-left: 16px;
  }

  .button-icon {
    min-width: 24px;
  }

  .delete-button,
  .bulk-edit-button,
  .edit-button {
    font-family: var(--font-sans);
    font-size: 1rem;
    font-weight: 500;

    height: fit-content;
    width: 100%;
    margin-bottom: 8px;

    @media screen and (min-width: 880px) {
      margin-bottom: 0;
    }
  }
  .bulk-edit-button {
    min-width: 180px;
  }

  .delete-button {
    margin-right: 8px;
    color: var(--color-red);
  }

  .edit-button {
    color: var(--color-black);
  }
`;




export const AttachmentListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AttachmentLink = styled(Link)`
  text-decoration: none;
`;

export const QuizInfo = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  .course-title {
    font-size: 1.25rem;
    font-weight: 500;

    margin-bottom: 8px;
  }
`;

export const QuizListContainer = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

