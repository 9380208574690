import styled from 'styled-components';
export const TextInputLabel = styled.label`
  :not(:last-child) {
    margin-bottom: 24px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 1rem;
  font-family: var(--font-sans);
  color: var(--color-black);

  .generic-text-input {
    margin-top: 8px;
    width: 100%;

    font-size: inherit;
    color: var(--color-grey);

    border: 1px solid hsla(190deg, 65%, 12%, 0.5);

    padding: 10px 16px;
    border-radius: 8px;
  }
  .select-field {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 8px;
    font-size: 1rem;
    font-family: var(--font-sans);
    color: var(--color-grey);
    background-color: var(--color-white);

    border: 1px solid hsla(190deg, 65%, 12%, 0.5);

    padding: 10px 16px;
    border-radius: 8px;
  }
`;
