import styled from 'styled-components';

export const FormLabel = styled.label`
  :not(:last-child) {
    margin-bottom: 24px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  color: var(--color-black-20);

  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
  }

  .credential-form-input {
    margin-top: 8px;
    width: 100%;

    font-size: inherit;
    color: var(--color-grey);

    border: 1px solid hsla(190deg, 65%, 12%, 0.5);

    padding: 10px 16px;
    border-radius: 24px;
  }
`;
