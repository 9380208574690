import styled from 'styled-components';
import GenericDivider from '../GenericDivider';

export const TabNavigatorContainer = styled.div``;

export const Navigation = styled.nav`
  & > :not(:last-child) {
    margin-right: 24px;
  }
`;

export const NavigationDivider = styled(GenericDivider)`
  margin-top: 16px;
  margin-bottom: 24px;
`;
