import styled from 'styled-components';
import StrippedButton from '../StrippedButton';

export const SearchBarContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const SearchInput = styled.input`
  font-family: var(--font-sans);

  width: 100%;
  padding: 8px 16px;
  border-radius: 8px 0 0 8px;
  border: 1px solid var(--color-grey);
  border-right: none;
`;

export const SearchButton = styled(StrippedButton)`
  color: var(--color-white);

  padding: 8px;
  background-color: var(--color-dark-green);
  border-radius: 0 8px 8px 0;

  .search-icon {
    min-width: 24px;
  }
`;
