import React from 'react';

import { MdSort, MdAddCircle } from 'react-icons/md';
import StylableComponent from '../StylableComponent';
import {
  AddButton,
  SearchControls,
  SortButton,
  SearchBar,
} from './SearchableListControls.styles';

interface ComponentProps extends StylableComponent {
  // eslint-disable-next-line no-unused-vars
  onSearch: (search: string) => void;
  onClearSearch?: () => void;
  onAdd?: () => void;
  onSort?: () => void;
}

const SearchableListControls = ({
  onSearch,
  onClearSearch = () => {},
  onAdd = undefined,
  onSort = undefined,
  className
}: ComponentProps) => {
  return (
    <SearchControls className={className}>
      <SearchBar onSearch={onSearch} onClear={onClearSearch} />

      {(onSort || onAdd) && (
        <div className="controls-buttons">
          {onSort && (
            <SortButton>
              <MdSort size={24} />
              <p>Ordenar</p>
            </SortButton>
          )}

          {onAdd && (
            <AddButton onClick={onAdd}>
              <MdAddCircle className="add-icon" />
              <p>Adicionar</p>
            </AddButton>
          )}
        </div>
      )}
    </SearchControls>
  );
};



export default SearchableListControls;
