import React from 'react';
import { useParams } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import { Tab } from '../../components/TabNavigator';
import NotFoundPage from '../404';
import AttachmentDetailsPageUrlParams from '../../routes/location/AttachmentDetailsPageUrlParams';
import useAttachments from '../../services/swr/useAttachments';
import { AttachmentDetailsTabNavigator, PageContent } from './AttachmentDetailsPage.styles';
import AttachmentDetailsPageHeader from './AttachmentDetailsPageHeader';
import Attachment from '../../models/Attachment';
import AttachmentLessonsList from './AttachmentLessonList';

const AttachmentDetailsPage =  () => {
  const { attachmentId } = useParams<AttachmentDetailsPageUrlParams>();
  const { getAttachment, isLoading } = useAttachments()

  const attachmentInfo = getAttachment(attachmentId);

  if (!attachmentInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />

      {isLoading ? (
        <p>Carregando curso...</p>
      ) : (
        <PageContent>
          <AttachmentDetailsPageHeader attachmentInfo={attachmentInfo as Attachment} />
          <AttachmentDetailsTabNavigator>
            <Tab label="Aulas com este Anexo">
              <AttachmentLessonsList attachmentId={attachmentId} />
            </Tab>
          </AttachmentDetailsTabNavigator>
        </PageContent>
      )}
    </>
  );
};

export default AttachmentDetailsPage;
