import React, { forwardRef, useCallback } from 'react';
import { Modal, ModalRef } from '../../hooks/useModalRef';
import StrippedButton from '../StrippedButton';
import {
  PromptContainer,
  PromptContent,
  PromptOptions,
} from './ConfirmationPrompt.styles';

interface ComponentProps {
  promptText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ConfirmationPrompt = forwardRef<Modal, ComponentProps>(
  ({ promptText, onConfirm, onCancel }, ref) => { 
    const closePrompt = useCallback(() => {
      const modal = (ref as ModalRef).current;
      modal?.closeModal();
    }, [ref]);

    const handleConfirm = useCallback(() => {
      onConfirm();
      closePrompt();
    }, [closePrompt, onConfirm]);

    const handleCancel = useCallback(() => {
      if (onCancel) onCancel();
      closePrompt();
    }, [closePrompt, onCancel]);

    return (
      <PromptContainer ref={ref}>
        <PromptContent>
          <p className="prompt-text">{promptText}</p>

          <PromptOptions>
            <StrippedButton className="cancel-button" onClick={handleCancel}>
              Não
            </StrippedButton>
            <StrippedButton className="confirm-button" onClick={handleConfirm}>
              Sim
            </StrippedButton>
          </PromptOptions>
        </PromptContent>
      </PromptContainer>
    );
  },
);

ConfirmationPrompt.displayName = 'ConfirmationPrompt';

export default ConfirmationPrompt;
