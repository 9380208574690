import styled from 'styled-components';

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
`;

export const ClassOptions = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  .button-icon {
    min-width: 24px;
  }

  .delete-button,
  .edit-button {
    font-family: var(--font-sans);
    font-size: 1rem;
    font-weight: 500;

    height: fit-content;
    width: 100%;
    margin-bottom: 8px;

    @media screen and (min-width: 800px) {
      margin-bottom: 0;
    }
  }

  .delete-button {
    margin-right: 8px;
    color: var(--color-red);
  }

  .edit-button {
    color: var(--color-black);
  }
`;

export const ClassInformation = styled.div`
  margin-right: 24px;
  margin-bottom: 24px;

  font-family: var(--font-sans);
  color: var(--color-black);

  @media screen and (min-width: 480px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 800px) {
    margin-right: 0;
  }

  .class-name {
    font-size: 1.953rem;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .class-link {
    color: var(--color-black);
    font-size: 1rem;
  }

  .class-stats {
    display: flex;
    font-size: 1.25rem;

    margin-bottom: 16px;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  }
`;
