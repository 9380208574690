import React, { useCallback, useRef } from 'react';
import { MdSearch } from 'react-icons/md';
import StylableComponent from '../StylableComponent';
import {
  SearchBarContainer,
  SearchButton,
  SearchInput,
} from './SearchBar.styles';

interface ComponentProps extends StylableComponent {
  onSearch: (search: string) => void;
  onClear?: () => void;
}

const SearchBar = ({
  onSearch,
  onClear = () => {},
  className
}: ComponentProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(() => {
    const searchString = searchInputRef.current?.value;

    if (!searchString) onClear();
    else if (searchString.split(' ').length > 1 || searchString.length > 5)
      onSearch(searchString);
  }, [onClear, onSearch]);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') handleSearch();
    },
    [handleSearch],
  );

  return (
    <SearchBarContainer className={className}>
      <SearchInput
        ref={searchInputRef}
        onChange={handleSearch}
        onKeyDown={handleInputKeyDown}
      />
      <SearchButton onClick={handleSearch}>
        <MdSearch className="search-icon" size={24} />
      </SearchButton>
    </SearchBarContainer>
  );
};


export default SearchBar;
