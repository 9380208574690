import React from 'react';
import ContentWrapper from '../ContentWrapper';
import StylableComponent from '../StylableComponent';
import { ContentWithFooterWrapper } from './ContentWithFooter.styles';
import Footer from './Footer';

const ContentWithFooter = ({
  className,
  children,
  isFooterEnabled = true,
}: StylableComponent) => {
  return (
    <ContentWithFooterWrapper className={className}>
      <ContentWrapper>{children}</ContentWrapper>
      {isFooterEnabled && <Footer />}
    </ContentWithFooterWrapper>
  );
};

export default ContentWithFooter;
