import React from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';

import GenericSecondaryButton from '../../components/GenericSecondaryButton';

import useModalRef from '../../hooks/useModalRef';
import { Quiz } from '../../models/Quiz';

import {
  PageHeaderContainer,
  QuizInformation,
  QuizOptions,
  ResponsivityWrapper,
} from './QuizDetailsPage.styles';
import useQuiz from '../../services/swr/useQuiz';
import useToast from '../../hooks/useToast';

interface ComponentProps {
  quizInfo: Quiz;
}

const QuizDetailsPageHeader = ({ quizInfo }: ComponentProps) => {
  const { removeQuiz } = useQuiz();

  const { push } = useHistory();
  const { addToast } = useToast();

  const deleteQuizPromptRef = useModalRef();

  const handleDeleteQuiz = async (id: string) => {
    try {
      await removeQuiz(id);
      addToast({
        type: 'success',
        title: 'Questionário removido',
        description: 'O questionário foi removido com sucesso',
      });
      push('/quiz-management');
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover questionário',
        description:
          'Ocorreu um erro ao remover o questionário, tente novamente',
      });
    }
  };

  return (
    <PageHeaderContainer>
      <ResponsivityWrapper>
        <QuizInformation>
          <h1 className="quiz-name">{quizInfo.title}</h1>
          <div className="complementary-info"></div>
        </QuizInformation>
      </ResponsivityWrapper>

      <QuizOptions>
        <GenericSecondaryButton
          className="delete-button"
          onClick={() => deleteQuizPromptRef.current?.openModal()}
        >
          <MdDeleteForever className="button-icon" size={24} />
          <p>Deletar</p>
        </GenericSecondaryButton>
      </QuizOptions>

      <ConfirmationPrompt
        ref={deleteQuizPromptRef}
        promptText={`Tem certeza que quer deletar "${quizInfo.title}" permanentemente?`}
        onConfirm={() => handleDeleteQuiz(quizInfo.id)}
      />
    </PageHeaderContainer>
  );
};

export default QuizDetailsPageHeader;
