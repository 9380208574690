import React, { useCallback, useEffect, useState } from 'react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import GenericSecondaryButton from '../../components/GenericSecondaryButton';
import useClasses from '../../services/swr/useClasses';
import Class from '../../models/Class';
import {
  ClassInformation,
  ClassOptions,
  PageHeaderContainer,
} from './ClassDetailsPageHeader.styles';
import EditClassModal from './EditClassModal';
import useClassStudents from '../../services/swr/useClassStudents';
import useClassCourses from '../../services/swr/useClassCourses';
import useModalRef from '../../hooks/useModalRef';
import useSchools from '../../services/swr/useSchools';
import useQuiz from '../../services/swr/useQuiz';
import { Tags } from '../../styles/GenericComponentStyles';

interface ComponentProps {
  classInfo: Class;
}

const ClassDetailsPageHeader = ({ classInfo }: ComponentProps) => {
  const { getQuizByClassId } = useQuiz();
  const { getStudentCount } = useClassStudents(classInfo.id);
  const { getCourseCount } = useClassCourses(classInfo.id);
  const { removeClass } = useClasses();
  const { push } = useHistory();
  const { schools } = useSchools();

  const deleteClassPromptRef = useModalRef();
  const editClassModalRef = useModalRef();

  const [quizCount, setQuizCount] = useState<number | null>(null);
  const handleDeleteClass = useCallback(() => {
    removeClass(classInfo.id);
    push('/class-management');
  }, [classInfo.id, push, removeClass]);

  useEffect(() => {
    const fetchQuizCount = async () => {
      try {
        const quizzes = await getQuizByClassId(classInfo.id);
        setQuizCount(quizzes.length);
      } catch (error) {
        console.error('Error fetching quizzes', error);
        setQuizCount(null);
      }
    };

    fetchQuizCount();
  }, [getQuizByClassId, classInfo.id]);

  return (
    <PageHeaderContainer>
      <ClassInformation>
        <h1 className="class-name">
          {classInfo.title} |{' '}
          {schools.find(school => school.id === classInfo.schoolId)?.name ?? ''}
        </h1>
        <div className="class-stats">
          <Tags tagColor="blue">{getStudentCount() ?? '---'} alunos</Tags>
          <Tags tagColor="blue">{getCourseCount() ?? '---'} cursos</Tags>
          <Tags tagColor="blue">{quizCount ?? '---'} questionários</Tags>
        </div>
        {classInfo.synchronousClassLink && (
          <a href={classInfo.synchronousClassLink} className="class-link">
            {classInfo.synchronousClassLink}
          </a>
        )}
      </ClassInformation>

      <ClassOptions>
        <GenericSecondaryButton
          className="delete-button"
          onClick={() => deleteClassPromptRef.current?.openModal()}
        >
          <MdDeleteForever className="button-icon" size={24} />
          <p>Deletar</p>
        </GenericSecondaryButton>
        <GenericSecondaryButton
          className="edit-button"
          onClick={() => editClassModalRef.current?.openModal()}
        >
          <MdEdit className="button-icon" size={24} />
          <p>Editar</p>
        </GenericSecondaryButton>
      </ClassOptions>

      <ConfirmationPrompt
        ref={deleteClassPromptRef}
        promptText={`Tem certeza que quer deletar "${classInfo.title}" permanentemente?`}
        onConfirm={handleDeleteClass}
      />

      <EditClassModal ref={editClassModalRef} classInfo={classInfo} />
    </PageHeaderContainer>
  );
};

export default ClassDetailsPageHeader;
