import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AttachmentLessonListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AttachmentLessonLink = styled(Link)`
  text-decoration: none;
`;

export const AttachmentLessonInfo = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  & strong {
    color: var(--color-dark-blue);
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 12px;
  }

  .course-title {
    font-size: 1.25rem;
    font-weight: 500;
  }
`;

export const AttachmentListContainer = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;
