import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { RankingMember } from '../../models/RankingMember';

const useRankingPage = (schoolId: string) => {
  const [ranking, setRanking] = useState<RankingMember[]>([]);

  const requestClassesRanking = useCallback(async () => {
    if (!schoolId) return;
    const { data } = await api.get<RankingMember[]>(
      `/classes/ranking/${schoolId}`,
    );
    setRanking(data);
  }, [schoolId]);

  const resetClassesRanking = useCallback(
    async (resetStudentsPoints: boolean) => {
      console.log('resetClassesRanking: ', resetStudentsPoints);
      await api.delete(`/classes/ranking/${schoolId}`, {
        params: { resetStudentsPoints },
      });
      console.log('Deleted');
      requestClassesRanking();
    },
    [schoolId, requestClassesRanking],
  );

  useEffect(() => {
    schoolId && requestClassesRanking();
  }, [schoolId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ranking,
    resetClassesRanking,
  };
};

export default useRankingPage;
