import React from 'react';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import {
  SchoolInfo,
  SchoolLink,
  SchoolListItemContainer,
} from './SchoolListItem.styles';
import School from '../../models/School';
import useSchools from '../../services/swr/useSchools';

interface ComponentProps {
  schoolInfo: School;
}

const SchoolListItem = ({ schoolInfo }: ComponentProps) => {
  const { push } = useHistory();
  const { removeSchool } = useSchools();

  return (
    <SchoolListItemContainer>
      <SchoolLink to={`/school-details/${schoolInfo.id}`}>
        <SchoolInfo>
          <p className="school-title"><strong>{schoolInfo.name}</strong></p>
          {schoolInfo.address && <p title={schoolInfo.address} className="school-address">{schoolInfo.address.slice(0, 30)}...</p>}
          
          <p className=""> {schoolInfo.phone}</p>
        </SchoolInfo>
      </SchoolLink>

      <MutableListItemOptions
        objectName={schoolInfo.name}
        onEdit={() => push(`/school-details/${schoolInfo.id}`)}
        onRemove={() => removeSchool(schoolInfo.id)}
      />
    </SchoolListItemContainer>
  );
};

export default SchoolListItem;
