import React from 'react';

import { Field, useField } from 'formik';
import { TextInputLabel } from '../GenericTextInput/GenericTextInput.styles';
import GenericToolTip from '../GenericToolTip';
import InputError from '../InputError';

type ComponentProps<T> = {
  label: string;
  name: string;
  tooltip?: string;
  dataArray: Array<T>;
  placeHolder: string;
  optionLabel: keyof T;
};

const GenericSelectList = <T extends { id: string }>({
  name,
  label,
  tooltip,
  dataArray,
  placeHolder,
  optionLabel,
}: ComponentProps<T>) => {
  const [_, fieldMeta]  = useField({ // eslint-disable-line @typescript-eslint/no-unused-vars
    name,
    type: 'text', 
 });
  return (
    <TextInputLabel htmlFor={name}>
      <div>
      {label}
            {fieldMeta.touched && fieldMeta.error && (
        <InputError>{fieldMeta.error}</InputError>
      )}
      { tooltip && (
        <GenericToolTip text={tooltip} />
      )}
      </div>
      <Field as="select" name={name} className="select-field">
        <option defaultValue="">{placeHolder}</option>
          {dataArray.map((school) => {
          return (
            <option key={school.id} value={school.id}>
                {school[optionLabel] as string}
            </option>
          )})}
      </Field>  
    </TextInputLabel>
  );
}
export default GenericSelectList;
