import React, { useCallback, useMemo, useState } from 'react';
import useToast from '../../hooks/useToast';

import {
  CoursesListItem as QuizListItem,
  ModalContainer,
  SelectContainer,
  SelectLabel,
  SelectCourse,
} from './AddCoursesToClassModal.styles';
import SearchableList from '../../components/SearchableList';
import GenericSelectableList from '../../components/GenericSelectableList';
import { ModalRef } from '../../hooks/useModalRef';

import useQuiz from '../../services/swr/useQuiz';
import { Quiz } from '../../models/Quiz';
import useCourses from '../../services/swr/useCourses';
import useClassCourses from '../../services/swr/useClassCourses';

interface ComponentProps {
  classId: string;
  classQuizzes: Quiz[];
}

const AddQuizToClassModal = React.forwardRef(
  ({ classId, classQuizzes }: ComponentProps, ref) => {
    const { addToast } = useToast();
    const { quizzes, addQuizToClassCourse } = useQuiz();
    const { courses } = useClassCourses(classId);

    const [searchedCourseId, setSearchedCourseId] = useState<
      string | undefined
    >(courses[0] ? courses[0].id : undefined);

    const quizzesNotInClass = useMemo(() => {
      const classQuizzesIds = classQuizzes.map(q => q.id);

      const quizzesNotInClass = quizzes.filter(
        quiz => !classQuizzesIds.includes(quiz.id),
      );

      return quizzesNotInClass;
    }, [classQuizzes, quizzes]);

    const handleSearch = (search: string, quiz: Quiz) => {
      const treatedQuiz = Object.values(quiz)
        .join(' ')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      const match = treatedQuiz.includes(search);

      return match;
    };

    const handleSubmission = useCallback(
      async (quizzes: Quiz[]) => {
        if (quizzes.length === 0 || !searchedCourseId) {
          addToast({
            type: 'error',
            title: `Nenhum ${!searchedCourseId ? 'curso' : 'quiz'} selecionado`,
            description: `Por favor selecione um ${
              !searchedCourseId ? 'curso' : 'quiz'
            } para adicionar à turma`,
          });

          return;
        }

        try {
          const quizzesIds = quizzes.map(quiz => quiz.id);
          await addQuizToClassCourse(classId, searchedCourseId, quizzesIds);
          addToast({
            type: 'success',
            title: 'Quiz adicionado à turma',
            description: 'O quiz foi adicionado à turma com sucesso',
          });
          setTimeout(() => window.location.reload(), 2000);
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar quiz à turma',
            description: `Não foi possível adicionar o quiz à turma: ${error}`,
          });
        }

        (ref as ModalRef).current?.closeModal();
      },
      [classId, addQuizToClassCourse, addToast, ref],
    );

    return (
      <ModalContainer ref={ref}>
        <SelectContainer>
          <SelectLabel htmlFor="course">Selecione o Curso</SelectLabel>
          <SelectCourse
            name="course"
            value={searchedCourseId}
            onChange={e => setSearchedCourseId(e.target.value)}
          >
            {courses.map(course => (
              <option value={course.id} key={course.id}>
                {course.title}
              </option>
            ))}
          </SelectCourse>
        </SelectContainer>
        <SearchableList
          dataArray={quizzesNotInClass}
          searchMatches={handleSearch}
        >
          {({ data }) => (
            <GenericSelectableList
              dataArray={data}
              onConfirmSelection={handleSubmission}
              listItemComponent={({ data }) => (
                <QuizListItem>
                  <p className="course-title">{data.title}</p>
                </QuizListItem>
              )}
            ></GenericSelectableList>
          )}
        </SearchableList>
      </ModalContainer>
    );
  },
);

AddQuizToClassModal.displayName = 'AddQuizToClassModal';

export default AddQuizToClassModal;
