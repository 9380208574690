import styled from 'styled-components';

export const SignInFormContainer = styled.section`
  width: 75%;
  max-width: 400px;

  .title,
  .title-message {
    font-family: 'Roboto', sans-serif;
  }

  .title {
    font-size: 1.563rem;
    color: var(--color-black-20);
    margin-bottom: 8px;

    @media screen and (min-width: 768px) {
      font-size: 1.953rem;
    }
  }

  .title-message {
    font-size: 1rem;
    color: var(--color-grey);
    margin-bottom: 56px;

    @media screen and (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .create-account-link {
    margin-top: 24px;

    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: var(--color-grey);

    span a {
      color: var(--color-grey);
    }
  }
`;
