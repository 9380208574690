import React, { Fragment } from 'react';
import GenericDivider from '../../components/GenericDivider';
import SearchableList from '../../components/SearchableList';
import useModalRef from '../../hooks/useModalRef';
import Lesson from '../../models/Lesson';
import useCourseLessons from '../../services/swr/useCourseLessons';
import AddLessonToCourseModal from './AddLessonToCourseModal';
import { LessonsListContainer } from './CourseLessonsList.styles';
import CourseLessonsListItem from './CourseLessonsListItem';

interface ComponentProps {
  courseId: string;
}

const CourseLessonsList = ({ courseId }: ComponentProps) => {
  const { courseLessons } = useCourseLessons(courseId);

  const addLessonToCourseModalRef = useModalRef();

  const handleSearch = (search: string, lesson: Lesson) => {
    const treatedLessonName = lesson.title.toLowerCase();
    const treatedLessonDescription = lesson.description.toLowerCase();
    const match =
      treatedLessonName.includes(search) ||
      treatedLessonDescription.includes(search);
    return match;
  };

  return (
    <>
      <SearchableList
        dataArray={courseLessons}
        searchMatches={handleSearch}
        onAdd={() => addLessonToCourseModalRef.current?.openModal()}
      >
        {({ data }) => (
          <>
            {data.length > 0 ? (
              <LessonsListContainer>
                {data.map((courseLesson, index) => (
                  <Fragment key={courseLesson.id}>
                    <CourseLessonsListItem
                      lessonInfo={courseLesson}
                      courseId={courseId}
                    />
                    {index < data.length - 1 && <GenericDivider />}
                  </Fragment>
                ))}
              </LessonsListContainer>
            ) : (
              <p>Nenhuma aula encontrada</p>
            )}
          </>
        )}
      </SearchableList>

      <AddLessonToCourseModal
        ref={addLessonToCourseModalRef}
        courseId={courseId}
      />
    </>
  );
};

export default CourseLessonsList;
