import React from 'react';
import { MdWarning } from 'react-icons/md';

import { InputErrorContainer } from './InputError.styles';

const InputError = ({ children }: {
  children: React.ReactNode;
}) => {
  return (
    <InputErrorContainer>
      <MdWarning size={16} />
      <p className="text">{children}</p>
    </InputErrorContainer>
  );
};

export default InputError;
