import React, { useCallback, useState, useEffect, useMemo } from 'react';
import useClassStudents from '../../services/swr/useClassStudents';
import useToast from '../../hooks/useToast';
import Student from '../../models/Student';

import api from '../../services/api';

import {
  ListItemContainer,
  ModalContainer,
} from './AddStudentsToClassModal.styles';
import { ModalRef } from '../../hooks/useModalRef';
import GenericSelectableList from '../../components/GenericSelectableList';
import SearchableList from '../../components/SearchableList';

interface ComponentProps {
  classId: string;
}

const StudentListItem = ({ data }: { data: Student }) => {
  return (
    <ListItemContainer>
      <p className="student-name">{data.profile.name}</p>
      <p className="student-email">{data.profile.email}</p>
    </ListItemContainer>
  );
};

const AddStudentsModal = React.forwardRef(
  ({ classId }: ComponentProps, ref) => {
    const { addToast } = useToast();
    const { classStudents, addStudents } = useClassStudents(classId);
    const [students, setStudents] = useState<Student[]>([]);

    const studentsNotInClass = useMemo<Student[]>(() => {
      const classStudentsIds = classStudents.map(s => s.id);
      const studentsNotInClass = students.filter(
        student => !classStudentsIds.includes(student.id),
      );

      return studentsNotInClass;
    }, [classStudents, students]);

    useEffect(() => {
      api.get('/students/index').then(response => {
        setStudents(response.data);
      });
    }, []);

    const handleSearch = (search: string, student: Student) => {
      const treatedStudentName = student.profile.name?.toLowerCase();

      const match =
        (treatedStudentName && treatedStudentName.includes(search)) ||
        student.profile.email.includes(search);

      return match;
    };

    const handleSubmission = useCallback(
      (data: Student[]) => {
        if (data.length === 0) {
          addToast({
            type: 'error',
            title: 'Nenhum aluno foi selecionado',
            description:
              'Por favor selecione pelo menos um aluno para adicionar a turma',
          });

          return;
        }

        addStudents(data);

        if (ref) {
          (ref as ModalRef).current?.closeModal();
        }
      },
      [addStudents, addToast, ref],
    );

    return (
      <ModalContainer ref={ref}>
        <SearchableList
          dataArray={studentsNotInClass}
          searchMatches={handleSearch}
        >
          {({ data }) => (
            <GenericSelectableList
              dataArray={data}
              confirmSelectionButtonText="Adicionar Alunos"
              onConfirmSelection={handleSubmission}
              listItemComponent={StudentListItem}
            />
          )}
        </SearchableList>
      </ModalContainer>
    );
  },
);

AddStudentsModal.displayName = 'AddStudentsModal';

export default AddStudentsModal;
