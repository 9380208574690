import React from 'react';
import { useField } from 'formik';
import { TextInputLabel } from './GenericTextInput.styles';
import InputError from '../InputError';
import GenericToolTip from '../GenericToolTip';

interface ComponentProps {
  name: string;
  label: string;
  type: 'text' | 'email' | 'password' | 'number' | "checkbox";
  placeholder: string;
  className?: string;
  tooltip?: string;
  value?: string;
}

const GenericTextInput = ({
  name,
  label,
  type,
  tooltip,
  value,
  placeholder,
  className = ''
}: ComponentProps) => {
  const [fieldProps, fieldMeta] = useField({
    name,
    type: type || 'text',
    value,
  });

  return (
    <TextInputLabel htmlFor={name} className={className}>
      <div>
      {label}
      { tooltip && (
        <GenericToolTip text={tooltip} />
      )}
      </div>
      {fieldMeta.touched && fieldMeta.error && (
        <InputError>{fieldMeta.error}</InputError>
      )}
      <input
        className="generic-text-input"
        type={type}
        placeholder={placeholder}
        {...fieldProps}
      />
    </TextInputLabel>
  );
};



export default GenericTextInput;
