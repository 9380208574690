import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet as Head } from 'react-helmet-async';
import Routes from './routes';
import AppProvider from './contexts';
import GlobalStyles from './styles/GlobalStyles';

const App = () => {
  return (
    <AppProvider>
      <Head>
        <title>ITUS - Plataforma do Administrador</title>
      </Head>

      <BrowserRouter>
        <Routes />
      </BrowserRouter>

      <GlobalStyles />
    </AppProvider>
  );
};

export default App;
