import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import api from '../api';
import { fetcher } from './swr';
import useToast from '../../hooks/useToast';
import Course from '../../models/Course';

export default function useClassCourses(classId: string) {
  const { addToast } = useToast();
  const { data, error } = useSWR<Course[]>(
    `/classes/${classId}/courses`,
    fetcher,
    {
      dedupingInterval: 60 * 1000 * 60 * 12,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { mutate } = useSWRConfig();

  const addCourses = useCallback(
    (coursesIds: string[]) => {
      api
        .post('/classes/add-courses', {
          classesIds: [classId],
          coursesIds,
        })
        .then(() =>
          addToast({
            type: 'success',
            title: 'Cursos adicionados com sucesso',
            description: `${coursesIds.length} cursos foram adicionados na turma`,
          }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao adicionar cursos' }),
        )
        .finally(() => mutate(`/classes/${classId}/courses`));
    },
    [addToast, classId, mutate],
  );

  const removeCourse = useCallback(
    (courseIds: string[]) => {
      if (data)
        mutate(
          `/classes/${classId}/courses`,
          data.filter(c => !courseIds.includes(c.id)),
          false,
        );

      api
        .delete('/classes/remove-courses', {
          data: {
            classesIds: [classId],
            coursesIds: courseIds,
          },
        })
        .then(() =>
          addToast({ type: 'success', title: 'Cursos removidos com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao remover cursos' }),
        )
        .finally(() => mutate('/classes/index'));
    },
    [addToast, classId, data, mutate],
  );

  const getCourseCount = useCallback(() => data?.length, [data?.length]);

  return {
    courses: data || [],
    addCourses,
    removeCourse,
    getCourseCount,
    error,
    isLoading: !data && !error,
  };
}
