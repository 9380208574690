import React, { MouseEventHandler } from 'react';
import { StrippedButtonContainer } from './StrippedButton.styles';

interface ComponentProps {
  className?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

const StrippedButton = ({
  className = '',
  type = 'button',
  disabled = false,
  onClick = undefined,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  children,
}: ComponentProps) => (
  <StrippedButtonContainer
    className={className}
    type={type}
    disabled={disabled}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </StrippedButtonContainer>
);



export default StrippedButton;
