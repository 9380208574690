import React from 'react';
import { Link } from 'react-router-dom';

import { NotFoundPageContainer } from './styles';

const NotFoundPage = () => (
  <NotFoundPageContainer>
    <h1>404</h1>
    <p>A página que você está tentando acessar não existe</p>
    <Link to="/dashboard">Me leve de volta</Link>
  </NotFoundPageContainer>
);

export default NotFoundPage;
