import styled from 'styled-components';
import { ContentWithFooter } from '../../components/Footer';
import SearchableListControls from '../../components/SearchableListControls';
import SectionHeader from '../../components/SectionHeader';

export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const PageHeader = styled(SectionHeader)`
  .page-title {
    font-size: 1.953rem;
  }
`;

export const CourseListControls = styled(SearchableListControls)`
  margin-top: 32px;
  margin-bottom: 48px;
`;

export const CourseList = styled.ul`
  list-style-type: none;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;
