import styled from 'styled-components';

export const TextAreaLabel = styled.label`
  :not(:last-child) {
    margin-bottom: 24px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 1rem;
  font-family: var(--font-sans);
  color: var(--color-black);

  .generic-text-area {
    margin-top: 8px;
    width: 100%;
    resize: vertical;

    font-size: inherit;
    color: var(--color-grey);

    border: 1px solid hsla(190deg, 65%, 12%, 0.5);

    padding: 10px 16px;
    border-radius: 8px;
  }
`;
