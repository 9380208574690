import React from 'react';
import { TabButton } from './Tab.styles';

interface ComponentProps {
  label: string;
  _isActive?: boolean;
  _onClick?: () => void;
  children: React.ReactNode;
}

const Tab = ({
  label,
  _isActive = false,
  _onClick = () => { }
}: ComponentProps) => {
  return (
    <TabButton isActive={_isActive} onClick={_onClick}>
      {label}
    </TabButton>
  );
};

export default Tab;
