import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { AuthProvider } from './AuthContext';
import { ToastProvider } from './ToastContext';
import { ErrorsProvider } from './ErrorsContext';

const AppProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  return (
    <HelmetProvider>
      <ToastProvider>
        <ErrorsProvider>
          <AuthProvider>{children}</AuthProvider>
        </ErrorsProvider>
      </ToastProvider>
    </HelmetProvider>
  );
};

export default AppProvider;
