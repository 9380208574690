import React from 'react';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import Course from '../../models/Course';
import useClassCourses from '../../services/swr/useClassCourses';
import { CourseInfo, ListItemContainer } from './ClassCoursesListItem.styles';

interface ComponentProps {
  classId: string;
  courseInfo: Course;
}

const ClassCoursesListItem = ({
  classId,
  courseInfo
}: ComponentProps) => {
  const { removeCourse } = useClassCourses(classId);
  const { push } = useHistory();

  return (
    <ListItemContainer>
      <CourseInfo>
        <p className="course-title">{courseInfo.title}</p>
        <p className="course-description">{courseInfo.description}</p>
      </CourseInfo>

      <MutableListItemOptions
        objectName={courseInfo.title}
        onEdit={() => push(`/course-details/${courseInfo.id}`)}
        onRemove={() => removeCourse([courseInfo.id])}
      />
    </ListItemContainer>
  );
};

export default ClassCoursesListItem;
