import React from 'react';
import { Link } from 'react-router-dom';

import { AdminMenuContainer, AdminMenuOptions } from './styles';

interface AdminMenuProps {
  shouldShow: boolean;
}

const AdminMenu = ({ shouldShow }: AdminMenuProps) => {
  return (
    <AdminMenuContainer shouldShow={shouldShow}>
      <AdminMenuOptions>

        <Link to="/students-management" className="link">
           Alunos
        </Link>
        <Link to="/course-management" className="link">
           Cursos
        </Link>
        <Link to="/class-management" className="link">
           Turmas
        </Link>
        <Link to="/schools-management" className="link">
          Escolas
        </Link>
        <Link to="/attachments-management" className="link">
          Anexos
        </Link>
        <Link to="/quiz-management" className="link"> 
          Quiz
        </Link>
        <Link to="/teachers-management" className="link">
           Professores
        </Link>
        <Link to="/parents-management" className="link">
           Responsáveis
        </Link>
        <Link to="/questions-management" className="link">
          Banco de Questões
        </Link>
        <Link to="/class-course-association" className="link">
          Associar Turmas e Cursos
        </Link>
        <Link to="/free-trial-management" className="link">
          Agendamentos de Aulas Gratuitas
        </Link>
      </AdminMenuOptions>
    </AdminMenuContainer>
  );
};

export default AdminMenu;
