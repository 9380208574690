import React, { useCallback, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useFilter from '../../hooks/useFilter';

import {
  QuizListControls,
  QuizList,
  PageContent,
  PageHeader,
} from './QuizManagementPage.styles';
import useQuiz from '../../services/swr/useQuiz';
import { Quiz } from '../../models/Quiz';
import QuizListItem from './QuizListItem';
import { useHistory } from 'react-router-dom';

export const QuizManagementPage = () => {
  const { quizzes } = useQuiz();
  const { push } = useHistory();
  const {
    filter,
    clearFilter,
    filteredData: filteredQuizzes,
  } = useFilter<Quiz>(quizzes);
  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const onSearch = useCallback(
    (search: string) => {
      const treatedSearch = search.toLowerCase().trim();

      filter(quiz => {
        const treatedQuiz = Object.values(quiz);
        const treatedQuizInfo = treatedQuiz.join(' ').toLowerCase();
        return treatedQuizInfo.includes(treatedSearch);
      });

      setIsUsingSearch(true);
    },
    [filter],
  );

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento dos Questionários</h1>
        </PageHeader>

        <QuizListControls
          onSearch={onSearch}
          onClearSearch={clearFilter}
          onAdd={() => push('/create-quiz')}
        />

        <QuizList>
          {(isUsingSearch ? filteredQuizzes : [...quizzes].reverse()).map(
            (quiz: Quiz) => (
              <QuizListItem key={quiz.id} quizInfo={quiz} />
            ),
          )}
        </QuizList>
      </PageContent>
    </>
  );
};
