import React, { useCallback, useMemo, useState } from 'react';
import { MdApps } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import AdminMenu from './components/AdminMenu';
import UserMenu from './components/UserMenu';

import {
  NavBarContainer,
  NavBarContent,
  UserInformation,
  NavBarBurguerMenu,
  Vanishable,
  UserContainer,
  UserMenuContainer,
  MenuGroup,
  AdminMenuToggler,
} from './styles';

const NavigationBar = () => {
  const { user } = useAuth();

  const [shouldShowUserMenu, setShouldShowUserMenu] = useState(false);
  const [shouldShowAdminMenu, setShouldShowAdminMenu] = useState(false);

  const userAvatarUrl = useMemo(() => {
    const avatarToken = user.profile.name
      ? user.profile.name
      : user.profile.email;

    return `https://api.multiavatar.com/${avatarToken}.svg`;
  }, [user]);

  const handleToggleUserMenu = useCallback(() => {
    setShouldShowUserMenu(prevValue => !prevValue);
  }, []);

  const handleToggleAdminMenu = useCallback(() => {
    setShouldShowAdminMenu(prevValue => !prevValue);
  }, []);

  return (
    <NavBarContainer>
      <NavBarContent>
        <AdminMenu shouldShow={shouldShowAdminMenu} />

        <MenuGroup>
          <Link to="/dashboard">
            <img
              src="/assets/itus-logo.svg"
              alt="Itus Logo"
              className="itus-logo"
            />
          </Link>
          <div className="divider" />
          <AdminMenuToggler onClick={handleToggleAdminMenu}>
            <MdApps size={32} />
            <p className="menu-title">Menu</p>
          </AdminMenuToggler>
        </MenuGroup>

        {/* TODO: Search bar */}

        <Vanishable at={768}>
          <UserContainer>
            <UserInformation onClick={handleToggleUserMenu}>
              <p className="user-name">{user.profile.name}</p>
              <img
                src={userAvatarUrl}
                alt="Avatar do usuário"
                className="user-avatar"
              />
            </UserInformation>
            <UserMenuContainer shouldShow={shouldShowUserMenu}>
              <UserMenu />
            </UserMenuContainer>
          </UserContainer>
        </Vanishable>

        <NavBarBurguerMenu />
      </NavBarContent>
    </NavBarContainer>
  );
};

export default NavigationBar;
