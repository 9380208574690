import React from 'react';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import Course from '../../models/Course';
import useCourseLessons from '../../services/swr/useCourseLessons';
import useCourses from '../../services/swr/useCourses';
import {
  CourseInfo,
  CourseLink,
  CourseListItemContainer,
} from './CourseListItem.styles';

interface ComponentProps {
  courseInfo: Course;
}

const CourseListItem = ({ courseInfo }: ComponentProps) => {
  const { push } = useHistory();
  const { deleteCourse } = useCourses();
  const { getLessonCount } = useCourseLessons(courseInfo.id);

  return (
    <CourseListItemContainer>
      <CourseLink to={`/course-details/${courseInfo.id}`}>
        <CourseInfo>
          <p className="course-title">{courseInfo.title}</p>
          <p>{getLessonCount() ?? '---'} Aulas</p>
        </CourseInfo>
      </CourseLink>

      <MutableListItemOptions
        objectName={courseInfo.title}
        onEdit={() => push(`/course-details/${courseInfo.id}`)}
        onRemove={() => deleteCourse(courseInfo.id)}
      />
    </CourseListItemContainer>
  );
};

export default CourseListItem;
