import React, { useState, useCallback, createContext, useMemo } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import Admin from '../models/Admin';
import api from '../services/api';
import useToast from '../hooks/useToast';

interface AuthData {
  token: string;
  user: Admin;
}

interface SignInCredentials {
  email: string;
  password: string;
}

export interface AuthContextState {
  user: Admin;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState,
);

export const AuthProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const { addToast } = useToast();

  const [authData, setAuthData] = useState<AuthData>(() => {
    const token = localStorage.getItem('@itus:token');
    const admin = localStorage.getItem('@itus:admin-user');

    if (token && admin) {
      const { exp } = jwtDecode<JwtPayload>(token);

      if (exp && Date.now() >= exp * 1000) {
        localStorage.removeItem('@itus:token');
        localStorage.removeItem('@itus:admin-user');

        return {} as AuthData;
      }

      // eslint-disable-next-line dot-notation
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(admin) };
    }

    return {} as AuthData;
  });

  const signIn = useCallback(
    async ({ email, password }: { email: string, password: string}) => {
      try {
        const response = await api.post('/admins/authenticate', {
          email,
          password,
        });

        if (response.status === 200 && response.data) {
          const { token, user } = response.data;

          localStorage.setItem('@itus:token', token);
          localStorage.setItem('@itus:admin-user', JSON.stringify(user));

          // eslint-disable-next-line dot-notation
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          setAuthData({ token, user });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [addToast],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@itus:token');
    localStorage.removeItem('@itus:admin-user');

    setAuthData({} as AuthData);
  }, []);

  const providerValue = useMemo(
    () => ({ user: authData.user, signIn, signOut }),
    [authData.user, signIn, signOut],
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};
