import React from 'react';
import MutableListItemOptions from '../../components/MutableListItemOptions';

import { AttachmentInfo, AttachmentLink, AttachmentListItemContainer } from './AttachmentListItem.styles';
import Attachment from '../../models/Attachment';
import useAttachments from '../../services/swr/useAttachments';
import { useHistory } from 'react-router-dom';


interface ComponentProps {
  attachmentInfo: Attachment;
}

const AttachmentListItem = ({ attachmentInfo }: ComponentProps) => {
  const { deleteAttachment } = useAttachments();
  const { push } = useHistory();

  const fileType = attachmentInfo.fileType.split("/")[1].toUpperCase();
  const size = Math.floor(Number(attachmentInfo.fileSize) / 100000 );
  const url = `/attachments-details/${attachmentInfo.id}`;
  return (
    <AttachmentListItemContainer>
      <AttachmentLink to={url}>
        <AttachmentInfo>
          <p className="course-title">{attachmentInfo.title}</p>
          <p className="course-description">{fileType} - {size}MB</p>
        </AttachmentInfo>
      </AttachmentLink>

      <MutableListItemOptions
        objectName={attachmentInfo.title}
        onEdit={() => { push(url) }}
        onRemove={() => deleteAttachment(attachmentInfo.id)}
      />
    </AttachmentListItemContainer>
  );
};

export default AttachmentListItem;
