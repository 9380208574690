import React from 'react';
import { useParams } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import { Tab } from '../../components/TabNavigator';
import styled from 'styled-components';
import NotFoundPage from '../404';
import { ContentWithFooter } from '../../components/Footer';
import { TabNavigator } from '../../components/TabNavigator';
import useQuiz from '../../services/swr/useQuiz';
import { QuizDetailsPageUrlParams } from '../../routes/location/DetailsPageUrlParams';
import { Quiz } from '../../models/Quiz';
import QuizDetailsPageHeader from './QuizDetailsPageHeader';
import QuizQuestionsList from './QuizQuestionsList';


const QuizDetailsPage =  () => {
  const { quizId } = useParams<QuizDetailsPageUrlParams>();
  const { quizzes, isLoading } = useQuiz();

  const quizInfo = quizzes.find(quiz => quiz.id === quizId);

  if (!quizInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />

      {isLoading ? (
        <p>Carregando curso...</p>
      ) : (
        <PageContent>
          <QuizDetailsPageHeader quizInfo={quizInfo as Quiz} />
          <QuizDetailsTabNavigator>
            <Tab label="Questões nesse Quiz">
              <QuizQuestionsList quizId={quizId} />
            </Tab>
          </QuizDetailsTabNavigator>
        </PageContent>
      )}
    </>
  );
};

export default QuizDetailsPage;

const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

const QuizDetailsTabNavigator = styled(TabNavigator)`
  margin-top: 64px;
`;

