import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';

export const ModalContainer = styled(GenericModal)`
  width: 80%;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  font-family: var(--font-sans);
  color: var(--color-black);

  .generic-checkbox {
    margin-right: 8px;
    width: 16px;

    :hover {
      cursor: pointer;
    }
  }
`;
