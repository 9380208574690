import React from 'react';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import Class from '../../models/Class';
import useClasses from '../../services/swr/useClasses';
import useClassStudents from '../../services/swr/useClassStudents';
import {
  ClassInfo,
  ClassLink,
  ClassListItemContainer,
} from './ClassListItem.styles';
import useSchools from '../../services/swr/useSchools';

interface ComponentProps {
  classInfo: Class;
}

const ClassListItem = ({ classInfo }: ComponentProps) => {
  const { push } = useHistory();
  const { getStudentCount } = useClassStudents(classInfo.id);
  const { removeClass } = useClasses();
  const { schools } = useSchools();
   const school = schools.find(school => school.id === classInfo.schoolId);
  return (
    <ClassListItemContainer>
      <ClassLink to={`/class-details/${classInfo.id}`}>
        <ClassInfo>
          <p className="class-title">{classInfo.title}  
            {school && ` | ${school.label}`}
          </p>
          <p className="class-student-count">
            {getStudentCount() ?? '---'} Alunos
          </p>
        </ClassInfo>
      </ClassLink>

      <MutableListItemOptions
        objectName={classInfo.title}
        onEdit={() => push(`/class-details/${classInfo.id}`)}
        onRemove={() => removeClass(classInfo.id)}
      />
    </ClassListItemContainer>
  );
};

export default ClassListItem;
