import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';

export const ModalContainer = styled(GenericModal)`
  width: 80%;
  max-width: 720px;
`;

export const ListItemContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
  }

  .student-name,
  .student-email {
    font-family: var(--font-sans);
  }

  .student-name {
    font-weight: 500;
  }
`;
