import styled from 'styled-components';
import { ContentWithFooter } from '../../components/Footer';
import { TabNavigator } from '../../components/TabNavigator';

export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const CourseDetailsTabNavigator = styled(TabNavigator)`
  margin-top: 64px;
`;
