import React, { Fragment, useEffect } from 'react';
import GenericDivider from '../../components/GenericDivider';
import SearchableList from '../../components/SearchableList';
import useModalRef from '../../hooks/useModalRef';
import useAttachments from '../../services/swr/useAttachments';
import { AttachmentListContainer } from '../AttachmentManagementPage/AttachmentListItem.styles';
import Lesson from '../../models/Lesson';
import AttachmentLessonListItem from './AttachmentLessonListItem';
import AddLessonToAttachmentModal from './AddLessonToAttachmentModal';
import useCourses from '../../services/swr/useCourses';

interface ComponentProps {
  attachmentId: string;
}

const AttachmentLessonsList = ({ attachmentId }: ComponentProps) => {
  const { attachmentLessons, getLessonByAttachment } = useAttachments();

  const addLessonToCourseModalRef = useModalRef();
  const { courses } = useCourses();
  const [defaultCourse] = courses;

  const handleSearch = (search: string, lesson: Lesson) => {
    const treatedLessonName = lesson.title.toLowerCase();
    const treatedLessonDescription = lesson.title.toLowerCase();
    const match =
      treatedLessonName.includes(search) ||
      treatedLessonDescription.includes(search);
    return match;
  };

  useEffect(() => {
    getLessonByAttachment(attachmentId);
  }, [attachmentId]); // eslint-disable-line

  return (
    <>
  
      <SearchableList
        dataArray={attachmentLessons}
        searchMatches={handleSearch}
        onAdd={() => addLessonToCourseModalRef.current?.openModal()}
      >
        {({ data }) => (
          <>
            {data.length > 0 ? (
              <AttachmentListContainer>
                {data.map((attachmentLessons, index) => (
                  <Fragment key={attachmentLessons.id}>
                    <AttachmentLessonListItem
                      lessonInfo={attachmentLessons}
                      attachmentId={attachmentId}
                    />
                    {index < data.length - 1 && <GenericDivider />}
                  </Fragment>
                ))}
              </AttachmentListContainer>
            ) : (
              <p>Nenhuma aula encontrada</p>
            )}
          </>
        )}
      </SearchableList>

      {courses.length > 0 && (
      <AddLessonToAttachmentModal
        ref={addLessonToCourseModalRef}
        attachmentId={attachmentId}
        attachmentLessons={attachmentLessons}
        courseId={defaultCourse.id}
      />
      )}

    </>
  );
};

export default AttachmentLessonsList;
