import styled from 'styled-components';

export const TableRow = styled.tr`
  display: table-row;

  td {
    padding: 16px 0;
  }

  .primary-data {
    font-weight: 500;
  }

  .link {
    text-decoration: none;
    color: var(--color-black);
  }
`;

export const StudentData = styled.div`
  padding-right: 40px;
`;

export const ParentData = styled.div`
  padding-right: 40px;
`;
