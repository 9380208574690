import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';

export const ModalContainer = styled(GenericModal)`
  width: 80%;
  max-width: 720px;
`;

export const CoursesListItem = styled.div`
  .course-title {
    font-family: var(--font-sans);
    margin-right: 16px;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectLabel = styled.label`
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--color-gray-800);
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: block;
`;
export const SelectCourse = styled.select`
  width: 100%;
  padding: 0.625rem;
  border-radius: 10px;
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--color-gray-800);
  background-color: var(--color-white);
  margin-bottom: 1rem;
`;
