import React, { Fragment } from 'react';
import NavigationBar from '../../components/NavigationBar';
import AddTeacherModal from './AddTeacherModal';
import TeacherListItem from './TeacherListItem';
import {
  PageContent,
  PageHeader,
  TeachersList,
} from './TeachersManagementPage.styles';
import GenericDivider from '../../components/GenericDivider';
import Teacher from '../../models/Teacher';
import useTeachers from '../../services/swr/useTeachers';
import useModalRef from '../../hooks/useModalRef';
import SearchableList from '../../components/SearchableList';

const TeacherManagementPage = () => {
  const { teachers } = useTeachers();
  const addTeacherModalRef = useModalRef();

  const handleSearch = (search: string, teacher: Teacher) => {
    const treatedTeacherName = teacher.profile.name?.toLowerCase();

    const match =
      (treatedTeacherName && treatedTeacherName.includes(search)) ||
      teacher.profile.email.includes(search);

    return match;
  };

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento de Professores</h1>
        </PageHeader>

        <SearchableList
          dataArray={teachers}
          searchMatches={handleSearch}
          onAdd={() => addTeacherModalRef.current?.openModal()}
        >
          {({ data }) => (
            <TeachersList>
              {data.map((teacher, index) => (
                <Fragment key={`${teacher.id}-${teacher.profile.email}`}>
                  <TeacherListItem teacherInfo={teacher} />
                  {index < teachers.length - 1 && <GenericDivider />}
                </Fragment>
              ))}
            </TeachersList>
          )}
        </SearchableList>

        <AddTeacherModal ref={addTeacherModalRef} />
      </PageContent>
    </>
  );
};

export default TeacherManagementPage;