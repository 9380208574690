import styled from 'styled-components';

import CallToActionButton from '../../components/CallToActionButton';
import ContentWrapper from '../../components/ContentWrapper';
import Scrollable from '../../components/Scrollable';

export const PageContent = styled(ContentWrapper)`
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const AssociationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 40px;
`;

export const EntityListsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  width: 100%;

  @media screen and (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
  }

  .title {
    font-family: var(--font-sans);
    color: var(--color-dark-green);
    margin-bottom: 8px;
  }
`;

export const EntityList = styled(Scrollable)`
  border-top: 1px solid var(--color-dark-blue);
  border-bottom: 1px solid var(--color-dark-blue);
  padding: 16px;

  height: 30vh;
  overflow-y: scroll;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Entity = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-bottom: 1px solid var(--color-dark-blue);
  background-color: unset;

  padding: 8px;
  width: 100%;

  font-family: var(--font-sans);

  &:hover {
    cursor: pointer;
  }
`;

export const AssociateButton = styled(CallToActionButton)`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  width: 40%;
  min-width: fit-content;
  margin-top: 32px;
`;
