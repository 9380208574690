import React from 'react';
import { MdVisibilityOff } from 'react-icons/md';
import useModalRef from '../../hooks/useModalRef';
import Lesson from '../../models/Lesson';
import useCourseLessons from '../../services/swr/useCourseLessons';
import {
  ItemOptions,
  LessonInformation,
  LessonThumbnail,
  ListItemContainer,
  ResponsivityWrapper,
} from './CourseLessonsListItem.styles';
import EditLessonModal from './EditLessonModal';

interface ComponentProps {
  lessonInfo: Lesson;
  courseId: string;
}

const CourseLessonsListItem = ({
  lessonInfo,
  courseId
}: ComponentProps) => {
  const { removeLesson } = useCourseLessons(courseId);

  const editLessonModalRef = useModalRef();

  return (
    <>
      <ListItemContainer>
        <LessonThumbnail
          src={`https://i.ytimg.com/vi/${lessonInfo.videoUri.slice(
            lessonInfo.videoUri.indexOf('embed/') + 6,
          )}/hqdefault.jpg`}
          alt="Thumbnail da aula"
        />

        <ResponsivityWrapper>
          <LessonInformation>
            <div className="title-visibility-wrapper">
              <p className="lesson-title">{lessonInfo.title}</p>
              {!lessonInfo.isVisible && (
                <MdVisibilityOff className="visibility-icon" />
              )}
            </div>
            <p className="lesson-description">{lessonInfo.description}</p>
          </LessonInformation>

          <ItemOptions
            objectName={lessonInfo.title}
            onEdit={() => editLessonModalRef.current?.openModal()}
            onRemove={() => removeLesson(lessonInfo.id)}
          />
        </ResponsivityWrapper>
      </ListItemContainer>

      <EditLessonModal
        ref={editLessonModalRef}
        courseId={courseId}
        lessonInfo={lessonInfo}
      />
    </>
  );
};

export default CourseLessonsListItem;
