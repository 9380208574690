import React, { useCallback } from 'react';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import { ModalContainer } from './CreateSchoolModal.styles';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import useSchools from '../../services/swr/useSchools';

const CreateSchoolModal = React.forwardRef((_, ref) => {
  const { addSchool } = useSchools();
  const onSubmit = useCallback(
    (values: Record<string, unknown>, { setSubmitting }: FormikHelpers<Record<string, unknown>>) => {
      addSchool({
        name: (values.name) as string,
        label: (values.label) as string,
        address: (values.address) as string || null,
        phone: (values.phone) as string || null,
      });
      setSubmitting(false);
      (ref as ModalRef).current?.closeModal();
    },
    [addSchool, ref],
  );
  const closeModal = useCallback(
    () => (ref as ModalRef).current?.closeModal(),
      [ref],
  );

  return (
    <ModalContainer ref={ref}>
          <GenericModalForm
            initialValues={{
              name: '',
              label: '',
              address: '',
              phone: ''
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Campo obrigatório'),
              label: Yup.string().required('Campo obrigatório'),
              address: Yup.string(),
              phone: Yup.string()
            })}
            submitButtonType="create"
            onSubmit={onSubmit}
            onCancel={closeModal}
          >
            <GenericTextInput
              name="name"
              label="Nome da Escola"
              type="text"
              placeholder='Jardim de Infância'
            />
            <GenericTextInput
              name="label"
              label="Código da Escola"
              type="text"
              placeholder="JARDIMINFANCIA"
            />
            <GenericTextInput
              name="address"
              label="Endereço "
              type="text"
              placeholder= 'Rua das Flores, 123'
            />

             <GenericTextInput
              name="phone"
              label="Telefone"
              type="text"
              placeholder='31912345678'
            />
          </GenericModalForm>
    </ModalContainer>
  );
});

CreateSchoolModal.displayName = 'CreateClassModal';

export default CreateSchoolModal;
