/**
 * Deep copies an object.
 *
 * @param {*} object
 * @returns
 */
export function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

/**
 * Fills all properties of an object that are undefined with the values of the same properties defined in another object.
 *
 * If a property exists on source, but not on target it will be copied over.
 *
 * @param {*} target object to be filled
 * @param {*} source object with values to fill target
 * @returns
 */
export function fill(target, source) {
  const targetCopy = JSON.parse(JSON.stringify(target));

  for (const [key, value] of Object.entries(target))
    if (value === undefined) targetCopy[key] = source[key];

  return targetCopy;
}

/**
 * Fills all properties of an object that are falsy with the values of the same properties defined in another object.
 *
 * If a property exists on source, but not on target it will be copied over.
 *
 * @param {*} target object to be filled
 * @param {*} source object with values to fill target
 * @returns
 */
export function fillEmptyFields(target, source) {
  const targetCopy = JSON.parse(JSON.stringify(target));

  for (const [key, value] of Object.entries(target))
    if (!value) targetCopy[key] = source[key];

  return targetCopy;
}

/**
 * Returns a new object with all properties of the original object that are not falsy.
 *
 * @param {*} obj
 * @returns
 */
export function stripEmptyFields(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value));
}
