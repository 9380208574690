import React, { useCallback } from 'react';

import Form from './Form';
import { PageContent, PageHeader } from './CreateQuestionsPage.styles';
import { useHistory } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import { FormQuizContentData } from '../../models/Quiz';
import useToast from '../../hooks/useToast';
import useQuestions from '../../services/swr/useQuestions';

const CreateQuestionsPage = () => {
  const { push } = useHistory();
  const { addQuestions } = useQuestions();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: FormQuizContentData[]) => {
      try {
        await addQuestions(data);
        addToast({
          type: 'success',
          title: 'Questionário criado com sucesso',
          description: 'O questionário foi criado com sucesso',
        });
        push('/quiz-management');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao criar questionário',
          description:
            'Ocorreu um erro ao criar o questionário, tente novamente',
        });
      }
    },
    [addQuestions, addToast, push],
  );

  return (
    <>
      <NavigationBar />
      <PageContent isFooterEnabled={false}>
        <PageHeader>
          <h1 className="page-title">Novas Perguntas</h1>
        </PageHeader>
        <Form onSubmit={handleSubmit} />
      </PageContent>
    </>
  );
};

export default CreateQuestionsPage;
