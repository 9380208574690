import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ClassListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ClassLink = styled(Link)`
  text-decoration: none;
`;

export const ClassInfo = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  .class-title {
    font-size: 1.25rem;
    font-weight: 500;

    margin-bottom: 8px;
  }

  .class-student-count {
  }
`;
