import styled from 'styled-components';
import Color from '../../styles/colors';

export const SectionHeader = styled.h2`
  font-family: var(--font-sans);
  font-size: 1.563rem;
  font-weight: 500;
  color: var(--color-black);

  margin-bottom: 24px;
`;

export const WatchTimeChartSection = styled.section`
  margin-bottom: 56px;

  .graph-container {
    position: relative;
    min-height: 600px;
  }
`;

export const ResponsivityWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LessonsAndAssignmentsDataSection = styled.section`
  margin-right: 96px;
  margin-bottom: 32px;

  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;

export const MetricCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;

  @media screen and (min-width: 480px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 72px;
  }
`;

const colorGreyRgba = new Color('grey').withAlpha(0.5).rgba();

export const MetricCard = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: var(--font-sans);
  font-weight: 500;

  .data {
    font-size: 1.953rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 112px;
    height: 112px;
    margin-bottom: 16px;

    box-shadow: 0 0 4px 0 ${colorGreyRgba};
    border-radius: 50%;

    @media screen and (min-width: 560px) {
      width: 136px;
      height: 136px;
    }
  }

  .metric-name {
    font-size: 1.25rem;
    text-align: center;
  }
`;

export const LessonsStatusesSection = styled.section`
  flex: 1;
  min-width: 296px;

  .section-title {
    font-family: var(--font-sans);
    font-size: 1.563rem;
    font-weight: 500;
    color: var(--color-black);

    margin-bottom: 16px;
  }

  .lessons-list {
    list-style: none;
    max-height: 420px;
    overflow-y: scroll;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

export const LessonInformation = styled.li`
  font-family: var(--font-sans);

  .lesson-title {
    font-size: 1.25rem;
  }

  .lesson-statuses {
    display: flex;

    font-size: 1rem;
    font-weight: 500;
  }
`;

export const LessonStatuses = styled.div`
  display: flex;
  align-items: center;

  margin-right: 16px;

  & > :not(:last-child) {
    margin-right: 16px;
  }

  .status {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 4px;
    }
  }

  .red {
    color: var(--color-red);
  }

  .orange {
    color: var(--color-orange);
  }

  .green {
    color: var(--color-dark-green);
  }
`;
