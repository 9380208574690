import styled from 'styled-components';
import StrippedButton from '../StrippedButton';

export const ButtonContainer = styled(StrippedButton)`
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--color-white);

  background-color: var(--color-dark-green);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;
  border-radius: 8px;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    transition: background-color 0.2s ease-in-out;
    background-color: var(--color-dark-green-hover);
  }

  &:active {
    transition: 0.1s;
    transition-timing-function: ease-out;
    transform: translateY(2px);
  }
`;
