import styled from 'styled-components';
import Color from '../../styles/colors';

export const LessonsAndAssignmentsDataSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  margin-top: 56px;
  margin-bottom: 72px;
`;

const colorGreyRgba = new Color('grey').withAlpha(0.5).rgba();

export const MetricCard = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: var(--font-sans);
  font-weight: 500;

  width: 254px;

  .data {
    font-size: 1.953rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 112px;
    height: 112px;
    margin-bottom: 16px;

    box-shadow: 0 0 4px 0 ${colorGreyRgba};
    border-radius: 50%;

    @media screen and (min-width: 560px) {
      width: 136px;
      height: 136px;
    }
  }

  .metric-name {
    font-size: 1.25rem;
    text-align: center;
  }
`;

export const AssignmentClicksChartSection = styled.section`
  .chart-title {
    font-family: var(--font-sans);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .chart-container {
    position: relative;
    min-height: 400px;
  }
`;
