import React from 'react';

import {
  FooterContainer,
  FooterContent,
  ScrollToTopButton,
} from './Footer.styles';

const Footer = () => {
  return (
    <FooterContainer id="footer">
      <FooterContent>
        <p>Escola ITUS - Plataforma do Administrador</p>
        <ScrollToTopButton
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Voltar ao Topo
        </ScrollToTopButton>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
