import styled from 'styled-components';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 880px) {
    flex-direction: row;
  }

  @media screen and (min-width: 480px) {
    margin-left: 16px;
  }

  .button-icon {
    min-width: 24px;
  }

  .delete-button,
  .edit-button {
    font-family: var(--font-sans);
    font-size: 1rem;
    font-weight: 500;

    height: fit-content;
    width: 100%;
    margin-bottom: 8px;

    @media screen and (min-width: 880px) {
      margin-bottom: 0;
    }
  }

  .delete-button {
    margin-right: 8px;
    color: var(--color-red);
  }

  .edit-button {
    color: var(--color-black);
  }
`;
