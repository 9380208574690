import { useMemo } from 'react';
import useSWR from 'swr';
import { StudentCourseAnalytics } from '../../models/StudentAnalytics';
import { fetcher } from './swr';

export default function useStudentCourseAnalytics(
  studentId: string,
  courseId: string,
) {
  const { data, error } = useSWR<StudentCourseAnalytics | null>(
    `/lesson-analytics/course-lessons-report/by-student?studentId=${studentId}&courseId=${courseId}`,
    fetcher,
  );

  const validAnalytics = useMemo(() => {
    if (!data) return null;

    const { lessonsAnalytics } = data;

    const validAnalytics = lessonsAnalytics.filter(
      lessonAnalytics => lessonAnalytics.duration !== null,
    );

    return validAnalytics;
  }, [data]);

  return {
    analytics: data,
    validAnalytics,
    isLoading: data === undefined && !error,
    isError: error,
  };
}
