import styled from 'styled-components';

export const InputErrorContainer = styled.article`
  display: flex;
  align-items: center;

  margin: 8px 0;
  padding: 8px;
  width: 100%;

  border: 1px solid var(--color-red);
  border-radius: 8px;

  color: var(--color-red);

  .text {
    font-family: var(--font-sans);
    font-size: 1rem;

    margin-left: 8px;
  }
`;
