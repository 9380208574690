import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCirclePlus } from 'react-icons/fa6';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiCircleRemove } from 'react-icons/ci';
import useToast from '../../hooks/useToast';
import { FormQuizContentData, QuizForm } from '../../models/Quiz';
import AddQuizAttachmentModal from './AddQuizAttachmentModal';
import useModalRef from '../../hooks/useModalRef';

interface FormContent {
  onSubmit: (data: QuizForm) => void;
}

type QuizInfoContent = {
  title: string;
  description: string;
  tags: string;
};

const Form = ({ onSubmit }: FormContent) => {
  const { addToast } = useToast();
  const [formContent, setFormContent] = useState<FormQuizContentData[]>([
    {
      tags: '',
      description: '',
      options: ['', ''],
      correctAnswer: -1,
    },
  ]);

  const [quizInfoContent, setQuizInfoContent] = useState<QuizInfoContent>({
    title: '',
    tags: '',
    description: '',
  });

  const useForm = () => {
    const addQuestion = () => {
      const field = {
        description: '',
        tags: '',
        options: ['', ''],
        correctAnswer: -1,
      };
      setFormContent([...formContent, field]);
    };

    const editQuizInfo = (
      field: 'title' | 'description' | 'tags',
      value: string,
    ) => {
      const quizInfo = { ...quizInfoContent };
      if (field === 'title') {
        quizInfo.title = value;
        setQuizInfoContent(quizInfo);
        return;
      }
      if (field === 'description') {
        quizInfo.description = value;
        setQuizInfoContent(quizInfo);
        return;
      }
      quizInfo.tags = value;
      setQuizInfoContent(quizInfo);
    };

    const editField = (
      index: number,
      fieldLabel: string,
      field: 'tags' | 'description',
    ) => {
      const formFields = [...formContent];
      if (field === 'tags') {
        formFields[index].tags = fieldLabel;
        setFormContent(formFields);
        return;
      }
      formFields[index].description = fieldLabel;
      setFormContent(formFields);
      return;
    };

    const editOption = (
      index: number,
      optionIndex: number,
      value: string,
    ): void => {
      const formFields = [...formContent];
      if (index > -1) {
        formFields[index].options[optionIndex] = value;
        setFormContent(formFields);
      }
    };

    const addOption = (index: number) => {
      const formFields = [...formContent];
      if (index > -1) {
        const formField = formFields[index];
        if (formField.options.length <= 3) {
          formField.options.push('');
          setFormContent(formFields);
        }
      }
    };
    const handleCorrectAnswerChange = (
      questionIndex: number,
      optionIndex: number,
    ) => {
      const formFields = [...formContent];
      formFields[questionIndex].correctAnswer = optionIndex;
      setFormContent(formFields);
    };

    const handleDeleteOption = (fieldIndex: number, optionIndex: number) => {
      const formFields = [...formContent];
      formFields[fieldIndex].options.splice(optionIndex, 1);
      setFormContent(formFields);
    };

    const handleDeleteQuestion = (fieldIndex: number) => {
      const formFields = [...formContent];
      formFields.splice(fieldIndex, 1);
      setFormContent(formFields);
    };

    const validateForm = () => {
      return formContent.every(field => {
        return (
          field.description.length > 0 &&
          field.options.every(option => option.length >= 2) &&
          field.correctAnswer !== -1 &&
          quizInfoContent.title.length > 0 &&
          quizInfoContent.tags.length > 0
        );
      });
    };

    const handleSubmit = () => {
      const formData = {
        ...quizInfoContent,
        questions: formContent,
      } as QuizForm;

      if (validateForm()) {
        onSubmit(formData);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro ao criar questionário',
        description: 'Preencha todos os campos corretamente',
      });
    };

    return {
      editField,
      editOption,
      addOption,
      addQuestion,
      editQuizInfo,
      handleSubmit,
      handleDeleteOption,
      handleDeleteQuestion,
      handleCorrectAnswerChange,
    };
  };

  const {
    editField,
    addOption,
    editOption,
    addQuestion,
    handleSubmit,
    editQuizInfo,
    handleDeleteOption,
    handleDeleteQuestion,
    handleCorrectAnswerChange,
  } = useForm();

  const addQuizAttachmentModalRef = useModalRef();

  return (
    <Container>
      <SaveContainer>
        <SaveButton onClick={handleSubmit}>Enviar</SaveButton>
        <AttachQuizButton
          onClick={() => addQuizAttachmentModalRef.current?.openModal()}
        >
          Anexar Quiz
        </AttachQuizButton>
      </SaveContainer>

      <Header>
        <HeaderTitle
          placeholder="Título do Quiz"
          value={quizInfoContent.title}
          onChange={e => editQuizInfo('title', e.target.value)}
        />
        <HeaderDescription
          placeholder="Descrição"
          value={quizInfoContent.description}
          onChange={e => editQuizInfo('description', e.target.value)}
        />
        <HeaderTags
          placeholder="Tags (separadas por vírgula)"
          value={quizInfoContent.tags}
          onChange={e => editQuizInfo('tags', e.target.value)}
        />
      </Header>

      <QuestionContainer>
        {formContent.map((field, index) => (
          <QuestionCard key={index}>
            <div>
              <QuestionDescriptionInput
                type="text"
                value={field.tags}
                placeholder="Tags da Questão"
                onChange={e => editField(index, e.target.value, 'tags')}
              />
              <QuestionDescriptionInput
                type="text"
                value={field.description}
                placeholder="Pergunta"
                onChange={e => editField(index, e.target.value, 'description')}
              />
            </div>
            <div>
              {field && (
                <OptionsContainer>
                  <OptionsWrapper>
                    {field.options.map((option, optionIndex) => (
                      <OptionWrapper
                        key={`option${String.fromCharCode(65 + optionIndex)}`}
                      >
                        <CorrectAnswerInput
                          type="checkbox"
                          checked={field.correctAnswer === optionIndex}
                          onChange={() =>
                            handleCorrectAnswerChange(index, optionIndex)
                          }
                        />
                        <OptionInput
                          type="text"
                          value={option}
                          placeholder={`Alternativa ${String.fromCharCode(
                            65 + optionIndex,
                          )}`}
                          onChange={e =>
                            editOption(index, optionIndex, e.target.value)
                          }
                        />
                        <RemoveContainer witdh={'3rem'}>
                          <DeleteButton
                            onClick={() =>
                              handleDeleteOption(index, optionIndex)
                            }
                          >
                            <CiCircleRemove size={20} />
                          </DeleteButton>
                        </RemoveContainer>
                      </OptionWrapper>
                    ))}
                  </OptionsWrapper>
                  <AddOptionButton
                    isEnabled={field.options.length < 4}
                    onClick={() => addOption(index)}
                  >
                    <FaCirclePlus color={'#5f6368'} size={28} />
                  </AddOptionButton>
                </OptionsContainer>
              )}
            </div>
            <RemoveContainer witdh="100%">
              <DeleteButton onClick={() => handleDeleteQuestion(index)}>
                <RiDeleteBin6Line size={20} />
              </DeleteButton>
            </RemoveContainer>
          </QuestionCard>
        ))}
      </QuestionContainer>
      <ButtonsContainer>
        <AddQuestionButton onClick={addQuestion}>
          <FaCirclePlus size={28} /> Adicionar Questão
        </AddQuestionButton>
        <Save2Button onClick={handleSubmit}>Enviar</Save2Button>
      </ButtonsContainer>

      <AddQuizAttachmentModal ref={addQuizAttachmentModalRef} />
    </Container>
  );
};

export default Form;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0 1rem;
  height: 100vh;
  width: 100%;
  margin-top: 2rem;
  gap: 1rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 60%;
  justify-content: center;
  align-items: start;
`;

const RemoveContainer = styled.div<{ witdh?: string }>`
  width: ${props => props.witdh || '5rem'};
  display: flex;
  justify-content: end;
  align-items: start;
  min-height: 2rem;

  padding-right: 1.25rem;

  cursor: pointer;
  color: rgba(31, 33, 37, 0.8);
  &:hover {
    opacity: 0.8;
    color: #ce5252;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OptionsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  padding-left: 1rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10rem;
`;
const AddOptionButton = styled.button<{ isEnabled: boolean }>`
  background-color: white;
  display: ${props => (props.isEnabled ? 'flex' : 'none')};
  cursor: pointer;
  border: none;
  transform: translateY(-0.5rem);
  padding-right: 1rem;
  &:hover {
    opacity: 0.8;
  }
`;

const AddQuestionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin-bottom: 3rem;
  background-color: #269912;
  color: white;
  border: none;
  cursor: pointer;
  height: fit-content;
  transition: background-color 0.3s;

  &:hover {
    background-color: #26aa00;
    color: white;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  justify-content: space-between;
`;
const OptionInput = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 1rem;
  height: 2rem;
  border: none;
  &:focus {
    outline: none;
    background-color: #e0e0e0;
    border-bottom: 2px solid #269912;
  }
  &:first-child {
    margin-top: 1.5rem;
  }
`;
const CorrectAnswerInput = styled.input`
  margin-right: 0.5rem;
  transform: translateY(-0.5rem);
  width: 27px;
  height: 25px;
  border-radius: 50%;
  appearance: none;
  background-color: #fff;
  border: 2px solid #269912;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #269912;
  }

  &:checked:after {
    transform: translate(-50%, -50%) scale(1);
  }

  &:after {
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.375rem;
  justify-content: center;
  margin-top: 1.5rem;
  align-items: flex-start;
  width: 60%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-top: 8px solid #269912;
  gap: 0.5rem;
  max-height: 10rem;
`;

const DeleteButton = styled.button`
  // none decoration
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(31, 33, 37, 0.8);
  &:hover {
    color: #d94242;
  }
`;

const HeaderTitle = styled.input`
  font-size: 1.7rem;
  font-weight: 600;
  border: none;
  width: 100%;

  &::placeholder {
    color: black;
  }

  &:focus {
    border: none;
    outline: none;
    margin-bottom: 0.5rem;
    background-color: #e0e0e0;
    border-bottom: 2px solid #269912;
    &::placeholder {
      color: gray;
    }
  }
`;

const HeaderDescription = styled.input`
  color: rgba(31, 33, 37, 0.8);
  border: none;
  width: 100%;
  padding: 0.25rem;
  &:focus {
    outline: none;
    margin-bottom: 0.5rem;
    background-color: #e0e0e0;
    border-bottom: 2px solid #269912;
  }
`;

const HeaderTags = styled(HeaderDescription)``;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
`;

const QuestionDescriptionInput = styled.input`
  height: 2.5rem;
  width: 100%;
  padding-left: 1.25rem;
  border: none;
  margin-top: 1rem;

  &:focus {
    outline: none;
    background-color: #e0e0e0;
    border-bottom: 2px solid #269912;
  }
`;

const AttachQuizButton = styled.button`
  background-color: rgba(2, 6, 93, 1);
  cursor: pointer;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;
const QuestionCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  width: 100%;
  padding: 1rem;
  border-top: 5px solid rgba(2, 53, 157, 0.8);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 2rem;
  align-items: center;
`;
const Save2Button = styled.button`
  background-color: rgba(2, 6, 93, 1);
  cursor: pointer;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 0.375rem;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;

const SaveButton = styled.button`
  background-color: rgba(2, 6, 93, 1);
  position: sticky;
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;
