import React, { Fragment, useCallback, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useFilter from '../../hooks/useFilter';
import Course from '../../models/Course';
import useCourses from '../../services/swr/useCourses';
import CourseListItem from './CourseListItem';
import CreateCourseModal from './CreateCourseModal';
import {
  CourseListControls,
  CourseList,
  PageContent,
  PageHeader,
} from './CourseManagementPage.styles';
import GenericDivider from '../../components/GenericDivider';
import useModalRef from '../../hooks/useModalRef';

const CourseManagement = () => {
  const { courses } = useCourses();
  const {
    filter,
    clearFilter,
    filteredData: filteredCourses,
  } = useFilter<Course>(courses);

  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const createCourseModalRef = useModalRef();

  const handleSearch = useCallback(
    async (search: string) => {
      const treatedSearch = search.toLowerCase().trim();

      filter(course => {
        const treatedCourseName = course.title?.toLowerCase();

        if (treatedCourseName && treatedCourseName.includes(treatedSearch))
          return true;

        return false;
      });

      setIsUsingSearch(true);
    },
    [filter],
  );

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento de Cursos</h1>
        </PageHeader>

        <CourseListControls
          onSearch={handleSearch}
          onClearSearch={clearFilter}
          onAdd={() => createCourseModalRef.current?.openModal()}
        />

        <CreateCourseModal ref={createCourseModalRef} />

        <CourseList>
          {(isUsingSearch ? filteredCourses : courses).map((course, index) => (
            <Fragment key={course.id}>
              <CourseListItem courseInfo={course} />
              {index <
                (isUsingSearch ? filteredCourses : courses).length - 1 && (
                <GenericDivider />
              )}
            </Fragment>
          ))}
        </CourseList>
      </PageContent>
    </>
  );
};

export default CourseManagement;
