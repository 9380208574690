import React, { useCallback, useMemo, useState } from 'react';
import useCourseLessons from '../../services/swr/useCourseLessons';
import { ModalRef } from '../../hooks/useModalRef';
import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';
import Lesson from '../../models/Lesson';
import SearchableList from '../../components/SearchableList';
import GenericSelectableList from '../../components/GenericSelectableList';
import useToast from '../../hooks/useToast';
import useAttachments from '../../services/swr/useAttachments';
import useCourses from '../../services/swr/useCourses';

interface ComponentProps {
  attachmentId: string;
  attachmentLessons: Lesson[];
  courseId: string;
}

const LessonListItem = ({ data }: { data: Lesson }) => {
  return (
    <ListItemContainer>
      <p className="lesson-name">{data.title}</p>
      <p title={data.description} className="lesson-description">
        {data.description.length > 35
          ? `${data.description.slice(0, 35)}...`
          : data.description}
      </p>
    </ListItemContainer>
  );
};
const AddLessonToAttachmentModal = React.forwardRef(
  ({ attachmentId, attachmentLessons, courseId }: ComponentProps, ref) => {
    const { addToast } = useToast();
    const { addLessonToAttachment } = useAttachments();
    const [searchedCourseId, setSearchedCourseId] = useState<string>(
      attachmentLessons[0]?.courseId || courseId,
    );
    const { courseLessons } = useCourseLessons(searchedCourseId);
    const { courses } = useCourses();

    const lessonsNotInAttachment = useMemo<Lesson[]>(() => {
      const courseLessonsIds = attachmentLessons.map(c => c.id);
      const lessonsNotInAttachment = courseLessons.filter(
        attachment => !courseLessonsIds.includes(attachment.id),
      );

      return lessonsNotInAttachment;
    }, [courseLessons, attachmentLessons]);

    const handleSearch = (search: string, lesson: Lesson) => {
      const treatedStudentName = lesson.title.toLowerCase();

      const match =
        (treatedStudentName && treatedStudentName.includes(search)) ||
        lesson.title.includes(search);

      return match;
    };

    const handleSubmission = useCallback(
      (data: Lesson[]) => {
        if (data.length === 0) {
          addToast({
            type: 'error',
            title: 'Nenhuma aula foi selecionado',
            description:
              'Por favor selecione pelo menos um aula para adicionar a turma',
          });

          return;
        }

        addLessonToAttachment(
          attachmentId,
          data.map(d => d.id),
        );

        if (ref) {
          (ref as ModalRef).current?.closeModal();
        }
      },
      [addLessonToAttachment, addToast, ref, attachmentId],
    );

    return (
      <ModalContainer ref={ref}>
        <SelectContainer>
          <SelectLabel htmlFor="course">Selecione o Curso</SelectLabel>
          <SelectCourse
            name="course"
            value={searchedCourseId}
            onChange={e => setSearchedCourseId(e.target.value)}
          >
            {courses.map(course => (
              <option value={course.id} key={course.id}>
                {course.title}
              </option>
            ))}
          </SelectCourse>
        </SelectContainer>
        <SearchableList
          dataArray={lessonsNotInAttachment}
          searchMatches={handleSearch}
        >
          {({ data }) => (
            <GenericSelectableList
              dataArray={data}
              confirmSelectionButtonText="Adicionar Aulas"
              onConfirmSelection={handleSubmission}
              listItemComponent={LessonListItem}
            />
          )}
        </SearchableList>
      </ModalContainer>
    );
  },
);

AddLessonToAttachmentModal.displayName = 'AddLessonToAttachmentModal';

export default AddLessonToAttachmentModal;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectLabel = styled.label`
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--color-gray-800);
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: block;
`;
const SelectCourse = styled.select`
  width: 100%;
  padding: 0.625rem;
  border-radius: 10px;
  font-family: var(--font-sans);
  font-size: 1rem;
  color: var(--color-gray-800);
  background-color: var(--color-white);
  margin-bottom: 1rem;
`;
const ModalContainer = styled(GenericModal)`
  width: 30%;
`;
const ListItemContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    cursor: pointer;
  }

  .lesson-description {
    font-size: 0.875rem;
  }

  .lesson-name {
    font-weight: 500;
    font-family: var(--font-sans);
  }
`;
