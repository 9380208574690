import { useField } from 'formik';
import React from 'react';
import InputError from '../InputError';
import { FileInputLabel } from './GenericFileInput.styles';

interface ComponentProps {
  name: string;
  label: string;
  /**
   * setFile is currently a workaround for formik not supporting file
   * inputs natively and throwing an InvalidStateError when trying to
   * set the file input value with `helpers.setValue`.
   */
  setFile: (file: File) => void;
  className?: string;
}

const GenericFileInput = React.forwardRef<unknown, ComponentProps>(
  ({ name, label, setFile, className }, ref) => {
    const [fieldProps, fieldMeta] = useField({
      name,
    });

    return (
      <FileInputLabel htmlFor={name} className={className}>
        {label}
        {fieldMeta.touched && fieldMeta.error && (
          <InputError>{fieldMeta.error}</InputError>
        )}
        <input
          ref={ref as React.Ref<HTMLInputElement>}
          className="generic-file-input"
          type="file"
          {...fieldProps}
          onChange={event => {
            if (event.currentTarget.files)
              setFile(event.currentTarget.files[0]);

            // const reader = new FileReader();
            // reader.addEventListener('load', readerEvent => {
            //   console.log(readerEvent.target?.result);
            //   helpers.setValue('');
            // });
            // reader.readAsDataURL(event.currentTarget.files[0]);
          }}
        />
      </FileInputLabel>
    );
  },
);

GenericFileInput.displayName = 'GenericFileInput';

export default GenericFileInput;
