import React from 'react';
import { MdEdit, MdRemoveCircleOutline } from 'react-icons/md';
import useModalRef from '../../hooks/useModalRef';
import ConfirmationPrompt from '../ConfirmationPrompt';
import StrippedButton from '../StrippedButton';
import StylableComponent from '../StylableComponent';
import { OptionsContainer } from './MutableListItemOptions.styles';

interface ComponentProps extends StylableComponent {
  /**
   * The text to be displayed in the deletion confirmation prompt.
   */
  objectName: string;
  onEdit: () => void;
  onRemove: () => void;
}

const MutableListItemOptions = ({
  objectName,
  onEdit,
  onRemove,
  className
}: ComponentProps) => {
  const deletePromptRef = useModalRef();

  return (
    <OptionsContainer className={className}>
      <StrippedButton onClick={onEdit}>
        <MdEdit className="edit-icon" size={24} />
      </StrippedButton>
      <StrippedButton
        onClick={() => deletePromptRef.current?.openModal()}
      >
        <MdRemoveCircleOutline className="remove-icon" size={24} />
      </StrippedButton>

      <ConfirmationPrompt
        ref={deletePromptRef}
        promptText={`Tem certeza que quer remover "${objectName}"?`}
        onConfirm={onRemove}
      />
    </OptionsContainer>
  );
};

export default MutableListItemOptions;
