import styled from 'styled-components';

export const SectionContainer = styled.section`
  margin-top: 32px;

  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  grid-column-gap: 16px;

  grid-template-areas: 'classes class-details analytics';

  .rounded-area {
    background-color: var(--color-dark-blue);
    padding: 8px 16px;
    color: var(--color-white);
    border-radius: 50%;
    margin-left: 8px;
  }

  .provisory-class {
    margin-left: 100px;
  }
  .provisory-student {
    padding-left: 50px;
  }

  @media (max-width: 1168px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 16px;
    grid-template-areas:
      'classes class-details'
      'analytics analytics';
  }

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'classes'
      'class-details'
      'analytics';
  }
`;
