import { useCallback, useState } from 'react';

export default function useFilter<T>(dataSet: Array<T>) {
  const [filteredData, setFilteredData] = useState(dataSet);

  const filter = useCallback(
    (predicate: (item: T) => boolean) => {
      if (!dataSet) return;

      setFilteredData(dataSet.filter(predicate));
    },
    [dataSet],
  );

  const clearFilter = useCallback(() => {
    setFilteredData(dataSet);
  }, [dataSet]);

  return {
    filter,
    clearFilter,
    filteredData,
  };
}
