import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { AnySchema } from 'yup';
import { MdCancel, MdAddCircle, MdEdit } from 'react-icons/md';

import {
  CancelButton,
  FormOptions,
  SubmitButton,
} from './GenericModalForm.styles';

interface ComponentProps {
  initialValues: Record<string, unknown>;
  submitButtonType: 'create' | 'edit';
  validationSchema?: AnySchema;
  onSubmit: (
    values: Record<string, unknown>,
    formikHelpers: FormikHelpers<Record<string, unknown>>,
  ) => void;
  onCancel?: () => void;
  children: React.ReactNode;
}

const GenericForm = ({
  initialValues,
  validationSchema,
  submitButtonType,
  onSubmit,
  onCancel,
  children
}: ComponentProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div>{children}</div>

          {!isSubmitting ? (
            <FormOptions>
              <CancelButton onClick={onCancel}>
                <MdCancel size={24} />
                <p>Cancelar</p>
              </CancelButton>

              <SubmitButton type="submit">
                {submitButtonType === 'create' && (
                  <>
                    <MdAddCircle size={24} />
                    <p>Criar</p>
                  </>
                )}
                {submitButtonType === 'edit' && (
                  <>
                    <MdEdit size={24} />
                    <p>Editar</p>
                  </>
                )}
              </SubmitButton>
            </FormOptions>
          ) : (
            <p>Enviando...</p>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default GenericForm;
