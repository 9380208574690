import { useRef } from 'react';

export type Modal = {
  openModal: () => void;
  closeModal: () => void;
};

export type ModalRef = React.RefObject<Modal>;

export default function useModalRef() {
  const modalRef = useRef<Modal>(null);

  return modalRef;
}
