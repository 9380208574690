import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import Student from '../../models/Student';
import api from '../api';
import { fetcher } from './swr';
import useToast from '../../hooks/useToast';

export default function useClassStudents(classId: string) {
  const { addToast } = useToast();

  const { data, error } = useSWR<Student[]>(
    `/students/from-class/${classId}`,
    fetcher,
    {
      dedupingInterval: 60 * 1000 * 60 * 12,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { mutate } = useSWRConfig();

  const addStudents = useCallback(
    (students: Student[]) => {
      if (data)
        mutate(
          `/students/from-class/${classId}`,
          [...data, ...students],
          false,
        );

      api
        .post(`/classes/${classId}/add-students`, {
          studentsIds: students.map(s => s.id),
        })
        .then(() =>
          addToast({
            type: 'success',
            title: 'Alunos adicionados',
            description: `${students.length} alunos foram adicionados na turma`,
          }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao adicionar alunos' }),
        )
        .finally(() => mutate(`/students/from-class/${classId}`));
    },
    [addToast, classId, data, mutate],
  );

  const removeStudents = useCallback(
    (students: Student[]) => {
      const studentsIds = students.map(s => s.id);

      if (data)
        mutate(
          `/students/from-class/${classId}`,
          data.filter(s => !studentsIds.includes(s.id)),
          false,
        );

      api
        .delete(`/classes/${classId}/remove-students`, {
          data: {
            studentsIds,
          },
        })
        .then(() =>
          addToast({
            type: 'success',
            title: 'Alunos removidos',
            description: `${students.length} alunos foram removidos da turma`,
          }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao remover alunos' }),
        )
        .finally(() => mutate(`/students/from-class/${classId}`));
    },
    [addToast, classId, data, mutate],
  );

  const getStudentCount = useCallback(() => data?.length, [data?.length]);

  return {
    classStudents: data || [],
    addStudents,
    removeStudents,
    getStudentCount,
    error,
    isLoading: !data && !error,
  };
}
