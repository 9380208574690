import React, { useEffect } from 'react';
import styled from 'styled-components';

import GenericRanking from '../../components/GenericRanking';
import useRankingPage from '../../services/swr/useRanking';
import useSkeleton from '../../services/swr/useSkeleton';
import { RankingSkeleton } from '../../components/Skeleton';
import GenericSecondaryButton from '../../components/GenericSecondaryButton';
import { TbReload } from "react-icons/tb";
import useModalRef from '../../hooks/useModalRef';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import { ResetRankingModal } from './ResetRankingModal';

interface ComponentProps {
  schoolId: string;
 }

export const ClassesRankingPage = ({ schoolId }: ComponentProps ) => {
  const ClassRanking = GenericRanking;
  const { ranking } = useRankingPage(schoolId);
  const { isLoading, setSkeletonLoading } = useSkeleton();
  const resetClassRankingPromptRef = useModalRef();
   const resetClassRankingButtonRef = useModalRef();

  useEffect(() => {
    if (ranking.length > 1) setSkeletonLoading(false);
  }, [ranking]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <ClassOptions>
        <GenericSecondaryButton
          className="delete-button"
          onClick={() => resetClassRankingButtonRef.current?.openModal()}
        >
          <TbReload className="button-icon" size={24} />
          <p>Resetar Ranking</p>
        </GenericSecondaryButton>
      </ClassOptions>
      <Wrapper>
        <Title>Ranking de Turmas</Title>
        {isLoading ? (
          <RankingSkeleton isLoading={isLoading} size={5} />
        ) : (
          <ClassRanking ranking={ranking} />
        )}
      </Wrapper>
      <ConfirmationPrompt
        ref={resetClassRankingButtonRef}
        promptText={`Tem certeza que quer deletar o ranking de turmas permanentemente?`}
        onConfirm={() => resetClassRankingPromptRef.current?.openModal()}
      />
      <ResetRankingModal ref={resetClassRankingPromptRef} schoolId={schoolId} />
    </Container>
  );
};
const Container = styled.div`
  transition: all 0.3s ease-in-out;
  /* background-color: black;  theme.colors.background */
`;

const Wrapper = styled.div`
  z-index: 1;
  max-width: 920px;
  margin: 0 auto;
  padding: 10vh 1rem 0;
`;

export const ClassOptions = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  .button-icon {
    min-width: 24px;
  }

  .delete-button,
  .edit-button {
    font-family: var(--font-sans);
    font-size: 1rem;
    font-weight: 500;

    height: fit-content;
    width: 15rem;
    margin-bottom: 8px;

    @media screen and (min-width: 800px) {
      margin-bottom: 0;
    }
  }

  .delete-button {
    margin-right: 8px;
    color: var(--color-red);
  }

  .edit-button {
    color: var(--color-black);
  }
`;

const Title = styled.h1`
  font-family: #f0f2f5; /* theme.colors.background */
  font-weight: 700;
  color: #222; /* theme.colors.themedTitle */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  & p {
    margin: 0 1rem;

    &:nth-child(-n + 5) {
      font-size: 1.0rem; /* theme.fontSizes.sm */
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    & p {
      margin: 0 0.5rem;
    }
  }
`;
