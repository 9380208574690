import React, { Fragment, useEffect, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import FreeTrialRequest from '../../models/FreeTrialRequest';
import api from '../../services/api';
import {
  FreeTrialRequestsTable,
  FreeTrialRequestsTableContainer,
  PageContent,
  PageHeader,
  TableRowDivider,
} from './FreeTrialManagementPage.styles';
import FreeTrialTableRow from './FreeTrialTableRow';

const FreeTrialManagementPage  = () => {
  const [freeTrialRequests, setFreeTrialRequests] = useState<
    FreeTrialRequest[]
  >([]);

  useEffect(() => {
    api
      .get('/support/free-trial/index')
      .then(response => setFreeTrialRequests(response.data));
  }, []);

  return (
    <>
      <NavigationBar />

      <PageContent>
        <PageHeader>
          <h1>Agendamento de Aulas</h1>
        </PageHeader>

        <FreeTrialRequestsTableContainer>
          <FreeTrialRequestsTable>
            <thead>
              <tr className="table-header">
                <th>Responsável</th>
                <th>Aluno</th>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th className="spacer" style={{ width: 100 }} />
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {freeTrialRequests.map((freeTrialRequest, index) => (
                <Fragment key={freeTrialRequest.id}>
                  <FreeTrialTableRow freeTrialInfo={freeTrialRequest} />
                  {index < freeTrialRequests.length - 1 && (
                    <TableRowDivider>
                      <td className="divider" colSpan={4} />
                    </TableRowDivider>
                  )}
                </Fragment>
              ))}
            </tbody>
          </FreeTrialRequestsTable>
        </FreeTrialRequestsTableContainer>
      </PageContent>
    </>
  );
};

export default FreeTrialManagementPage;
