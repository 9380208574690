import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import Lesson from '../../models/Lesson';
import api from '../api';
import { fetcher } from './swr';
import { fill } from '../../utils/objects';
import useToast from '../../hooks/useToast';

export type BulkUpdateCourseLessonsPoints = {
  courseId: string;
  balance?: number;
  experiencePoints?: number;
};
export default function useCourseLessons(courseId: string) {
  const { addToast } = useToast();

  const { data, error } = useSWR<Lesson[]>(
    `/courses/${courseId}/lessons`,
    fetcher,
    {
      dedupingInterval: 60 * 1000 * 60 * 0.5,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const { mutate } = useSWRConfig();

  const addLesson = useCallback(
    (lesson: Omit<Lesson, 'id'>) => {
      api
        .post<Lesson>('/lessons/register', {
          ...lesson,
          assignmentUri: lesson.assignmentUri || undefined,
          courseId,
        })
        .then(() =>
          addToast({ type: 'success', title: 'Aula cadastrada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao cadastrar aula' }),
        )
        .finally(() => mutate(`/courses/${courseId}/lessons`));
    },
    [addToast, courseId, mutate],
  );

  const updateLesson = useCallback(
    (lessonObj: Partial<Lesson>) => {
      if (data) {
        const dataToMutate = data.find(l => l.id === lessonObj.id);
        const mutatedData = fill(lessonObj, dataToMutate);

        const newData = JSON.parse(JSON.stringify(data));
        newData.splice(data.indexOf(dataToMutate as Lesson), 1, mutatedData);

        mutate(`/courses/${courseId}/lessons`, newData, false);

        api
          .put('/lessons/update', mutatedData)
          .then(() =>
            addToast({ type: 'success', title: 'Aula editada com sucesso' }),
          )
          .catch(() =>
            addToast({ type: 'error', title: 'Erro ao editar aula' }),
          )
          .finally(() => mutate(`/courses/${courseId}/lessons`));
      }
    },
    [addToast, courseId, data, mutate],
  );

  const bulkUpdateLessonsCoursesPoints = useCallback(
    (bulkUpdateLesson: BulkUpdateCourseLessonsPoints) => {
      api
        .put(`/lessons/bulk-lessons-points`, bulkUpdateLesson)
        .then(() =>
          addToast({
            type: 'success',
            title: 'Pontuação das aulas atualizados com sucesso',
          }),
        )
        .catch(() =>
          addToast({
            type: 'error',
            title: 'Erro ao atualizar pontuação das aulas',
          }),
        );
    },
    [addToast],
  );

  const removeLesson = useCallback(
    (lessonId: string) => {
      if (data)
        mutate(
          `/courses/${courseId}/lessons`,
          data.filter(l => l.id !== lessonId),
          false,
        );

      api
        .delete(`/lessons/delete/${lessonId}`)
        .then(() =>
          addToast({ type: 'success', title: 'Aula deletada com sucesso' }),
        )
        .catch(() => addToast({ type: 'error', title: 'Erro ao deletar aula' }))
        .finally(() => mutate(`/courses/${courseId}/lessons`));
    },
    [addToast, courseId, data, mutate],
  );

  const getLessonCount = useCallback(() => data?.length, [data]);

  return {
    error,
    addLesson,
    updateLesson,
    removeLesson,
    getLessonCount,
    courseLessons: data || [],
    isLoading: !data && !error,
    bulkUpdateLessonsCoursesPoints,
  };
}
