import useSWR from 'swr';
import Course from '../../models/Course';
import { fetcher } from './swr';

export default function useStudentCourses(studentId: string) {
  const { data, error } = useSWR<Course[]>(
    `/courses/from-all-student-classes/${studentId}`,
    fetcher,
    {
      dedupingInterval: 60 * 1000 * 60 * 24,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return {
    courses: data || [],
    isLoading: !error && !data,
    isError: error,
  };
}
