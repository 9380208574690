import React, { Fragment } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useParents from '../../services/swr/useParents';
import Parent from '../../models/Parent';
import ParentsListItem from './ParentsListItem';
import {
  PageContent,
  PageHeader,
  ParentsList,
} from './ParentsManagementPage.styles';
import GenericDivider from '../../components/GenericDivider';
import useModalRef from '../../hooks/useModalRef';
import SearchableList from '../../components/SearchableList';

const ParentsManagementPage  = () => {
  const { parents } = useParents();
  const addParentModalRef = useModalRef();

  const handleSearch = (search: string, parent: Parent) => {
    const treatedParentName = parent.profile.name?.toLowerCase();

    const match =
      (treatedParentName && treatedParentName.includes(search)) ||
      parent.profile.email.includes(search);

    return match;
  };

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento de Responsáveis</h1>
        </PageHeader>

        <SearchableList
          dataArray={parents}
          searchMatches={handleSearch}
          onAdd={() => addParentModalRef.current?.openModal()}
        >
          {({ data }) => (
            <ParentsList>
              {data.map((parent, index) => (
                <Fragment key={`${parent.id}-${parent.profile.email}`}>
                  <ParentsListItem parentInfo={parent} />
                  {index < parents.length - 1 && <GenericDivider />}
                </Fragment>
              ))}
            </ParentsList>
          )}
        </SearchableList>
      </PageContent>
    </>
  );
};

export default ParentsManagementPage;
