import React from 'react';
import { useHistory } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import SearchableList from '../../components/SearchableList';
import StudentsTable from '../../components/StudentTable';
import useModalRef from '../../hooks/useModalRef';
import Student from '../../models/Student';
import useStudents from '../../services/swr/useStudents';
import AddStudentModal from './AddStudentModal';
import { PageContent, PageHeader } from './StudentsManagementPage.styles';

const StudentsManagementPage = () => {
  const { students, deleteStudent } = useStudents();
  const { push } = useHistory();

  const addStudentModalRef = useModalRef();

  const handleSearch = (search: string, student: Student) => {
    const treatedStudentName = (student.profile.name as string).toLowerCase();

    const match =
      treatedStudentName.includes(search) ||
      student.profile.email.includes(search);

    return match;
  };

  const handleEditStudent = (student: Student) => {
    push(`/student-details/${student.id}`);
  };

  const handleRemoveStudent = (student: Student) => {
    deleteStudent(student.id);
  };

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1>Gerenciamento de Alunos</h1>
        </PageHeader>

        <SearchableList
          dataArray={students}
          searchMatches={handleSearch}
          onAdd={() => addStudentModalRef.current?.openModal()}
        >
          {({ data }) => (
            <StudentsTable
              students={data}
              onEditStudent={handleEditStudent}
              onRemoveStudent={handleRemoveStudent}
            />
          )}
        </SearchableList>

        <AddStudentModal ref={addStudentModalRef} />
      </PageContent>
    </>
  );
};

export default StudentsManagementPage;
