import useSWR, { mutate } from 'swr';
import { Quiz, QuizDTO, QuizForm } from '../../models/Quiz';
import { fetcher } from './swr';
import { useCallback } from 'react';
import api from '../api';

const useQuiz = () => {
  const { data, error } = useSWR<Quiz[] | undefined>('/quiz', fetcher, {
    dedupingInterval: 60 * 1000 * 60,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const toQuiz = (quiz: QuizForm): Partial<QuizDTO> => {
    return {
      quizTitle: quiz.title,
      tags: quiz.tags,
      description: quiz.description,
      questions: quiz.questions.map(question => ({
        description: question.description,
        optionA: question.options[0],
        optionB: question.options[1],
        optionC: question.options[2],
        optionD: question.options[3],
        target: `option${String.fromCharCode(65 + question.correctAnswer)}`,
        tags: question.tags || '',
      })),
    };
  };

  const attachQuiz = useCallback(
    async (
      file: File,
      quizTitle: string,
      description: string,
      tags?: string,
    ) => {
      const formData = new FormData();
      formData.append('questions', file);
      formData.append('quizTitle', quizTitle);
      formData.append('description', description);
      formData.append('tags', tags || '');

      await api.post('/quiz/attach', formData);
      mutate('/quiz');
    },
    [],
  );

  const getQuizByClassId = useCallback((classId: string) => {
    const quizzes = api
      .get<Quiz[]>(`/quiz/by-class/${classId}`)
      .then(response => response.data);
    return quizzes;
  }, []);

  const removeQuizFromClass = useCallback(
    async (classId: string, quizId: string) => {
      await api.delete(`/quiz/${classId}/${quizId}`);
      mutate('/quiz');
    },
    [],
  );
  const addQuizToClassCourse = useCallback(
    async (classId: string, courseId: string, quizIds: string[]) => {
      await api.post(`/quiz/${classId}/${courseId}/add`, { quizIds });
      mutate('/quiz');
    },
    [],
  );

  const removeQuiz = useCallback(async (id: string) => {
    await api.delete(`/quiz/delete/${id}`);
    mutate('/quiz');
  }, []);

  const addQuiz = useCallback(async (quiz: QuizForm) => {
    const newQuiz = toQuiz(quiz);
    await api.post('/quiz/create', newQuiz);
    mutate('/quiz');
  }, []);

  return {
    error,
    addQuiz,
    removeQuiz,
    attachQuiz,
    getQuizByClassId,
    removeQuizFromClass,
    addQuizToClassCourse,
    quizzes: data || [],
    isLoading: !data && !error,
  };
};

export default useQuiz;
