import React from 'react';
import { Link } from 'react-router-dom';
import Student from '../../models/Student';
// import api from '../../services/api';
// import Parent from '../../models/Parent';
import MutableListItemOptions from '../MutableListItemOptions';
import { ParentData, StudentData, TableRow } from './StudentsTableRow.styles';

interface ComponentProps {
  studentInfo: Student;
  onEditStudent: (student: Student) => void;
  onRemoveStudent: (student: Student) => void;
}

const StudentsTableRow = ({
  studentInfo,
  onEditStudent,
  onRemoveStudent,
}: ComponentProps) => {

  //const [studentParent, setStudentParent] = useState<Parent | null>(null);
  // useEffect(() => {
  //   api
  //     .get(`/students/${studentInfo.id}/parent`)
  //     .then(response => setStudentParent(response.data))
  //     .catch(() => setStudentParent(null));
  // }, [studentInfo.id]);

  return (
    <TableRow>
      <td>
        <StudentData>
          <Link to={`/student-details/${studentInfo.id}`} className="link">
            <p className="primary-data">{studentInfo.profile.name}</p>
            <p>{studentInfo.profile.email}</p>
          </Link>
        </StudentData>
      </td>
      <td>
        <ParentData>
          {/* {studentParent ? (
            <Link to={`/parent-details/${studentParent.id}`} className="link">
              <p className="primary-data">{studentParent.profile.name}</p>
              <p>{studentParent.profile.email}</p>
            </Link>
          ) : (
            <p>---</p>
          )} */}
          <p>---</p>
        </ParentData>
      </td>
      <td className="spacer" style={{ width: '100%' }} />
      <td>
        <MutableListItemOptions
          objectName={studentInfo.profile.name || studentInfo.profile.email}
          onEdit={() => onEditStudent(studentInfo)}
          onRemove={() => onRemoveStudent(studentInfo)}
        />
      </td>
    </TableRow>
  );
};

export default StudentsTableRow;
