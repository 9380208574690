import React, { useCallback, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useFilter from '../../hooks/useFilter';
import useModalRef from '../../hooks/useModalRef';


import {
  SchoolListControls,
  SchoolList,
  PageContent,
  PageHeader,
} from './SchoolManagementPage.styles';
import CreateSchoolModal from './CreateSchoolModal';
import School from '../../models/School';
import useSchools from '../../services/swr/useSchools';
import SchoolListItem from './SchoolListItem';

const SchoolManagementPage = () => {
  const { schools } = useSchools();
  const {
    filter,
    clearFilter,
    filteredData: filteredSchools,
  } = useFilter<School>(schools);
  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const createSchoolModalRef = useModalRef();

  const onSearch = useCallback(
    (search: string) => {
      const treatedSearch = search.toLowerCase().trim();

      filter(schoolItem => {
        const treatedSchoolName = schoolItem.label.toLowerCase();

        if (treatedSchoolName && treatedSchoolName.includes(treatedSearch))
          return true;

        return false;
      });

      setIsUsingSearch(true);
    },
    [filter],
  );

  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Gerenciamento de Escolas</h1>
        </PageHeader>

        <SchoolListControls
          onSearch={onSearch}
          onClearSearch={clearFilter}
          onAdd={() => createSchoolModalRef.current?.openModal()}
        />

        <CreateSchoolModal ref={createSchoolModalRef} />

        <SchoolList>
          {(isUsingSearch ? filteredSchools : schools).map(schoolObj => (
            <SchoolListItem key={schoolObj.id} schoolInfo={schoolObj} />
          ))}
        </SchoolList>
      </PageContent>
    </>
  );
};

export default SchoolManagementPage;
