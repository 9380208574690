import React, { MouseEventHandler } from 'react';
import StylableComponent from '../StylableComponent';

import { ButtonContainer } from './GenericSecondaryButton.styles';

interface ComponentProps extends StylableComponent {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

const GenericSecondaryButton = ({
  className,
  onClick = () => {},
  children
}: ComponentProps) => {
  return (
    <ButtonContainer className={className} onClick={onClick}>
      {children}
    </ButtonContainer>
  );
};


export default GenericSecondaryButton;
