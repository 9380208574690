import styled from 'styled-components';

export const SignInContainer = styled.main`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  .login-form-background {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
      width: 50vw;
      height: 100vh;
    }
  }
`;
