import React, { MouseEventHandler } from 'react';

import { CallToActionButtonContainer } from './styles';

interface CallToActionButtonComponentProps {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

const CallToActionButton = ({
  className,
  onClick,
  children,
}: CallToActionButtonComponentProps) => {
  return (
    <CallToActionButtonContainer className={className} onClick={onClick}>
      {children}
    </CallToActionButtonContainer>
  );
};

export default CallToActionButton;
