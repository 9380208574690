import styled from 'styled-components';

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .edit-icon,
  .remove-icon {
    min-width: 24px;
  }

  .edit-icon {
    color: var(--color-black);
    margin-right: 8px;
  }

  .remove-icon {
    color: var(--color-red);
  }
`;
