import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import Course from '../../models/Course';
import api from '../api';
import { fetcher } from './swr';
import { fillEmptyFields } from '../../utils/objects';
import useToast from '../../hooks/useToast';

export default function useCourses() {
  const { addToast } = useToast();

  const { data, error } = useSWR<Course[]>('/courses/index', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 12,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { mutate } = useSWRConfig();

  const getCourse = useCallback(
    (courseId: string) => data?.find(course => course.id === courseId),
    [data],
  );

  const addCourse = useCallback(
    (course: Omit<Course, 'id'>) => {
      api
        .post('/courses/register', course)
        .then(() =>
          addToast({ type: 'success', title: 'Curso cadastrado com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao cadastrar curso' }),
        )
        .finally(() => {
          mutate('/courses/index');
        });
    },
    [addToast, mutate],
  );

  const updateCourse = useCallback(
    (course: Partial<Course>) => {
      if (data) {
        const dataToMutate = data.find(c => c.id === course.id);
        const mutatedData = fillEmptyFields(course, dataToMutate);

        const newData = JSON.parse(JSON.stringify(data));
        newData.splice(data.indexOf(dataToMutate as Course), 1, mutatedData);

        mutate('/courses/index', newData, false);

        api
          .put('/courses/update', mutatedData)
          .then(() =>
            addToast({ type: 'success', title: 'Curso editado com sucesso' }),
          )
          .catch(() =>
            addToast({ type: 'error', title: 'Erro ao editar curso' }),
          )
          .finally(() => mutate('/courses/index'));
      }
    },
    [addToast, data, mutate],
  );

  const updateCourseThumbnail = useCallback(
    async (courseId: string, thumbnailFile: File) => {
      const formData = new FormData();
      formData.append('thumbnail', thumbnailFile);

      await api.patch(`/courses/${courseId}/thumbnail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [],
  );

  const deleteCourse = useCallback(
    (courseId: string) => {
      if (data)
        mutate(
          `/courses/index`,
          data.filter(c => c.id !== courseId),
          false,
        );

      api
        .delete(`/courses/delete/${courseId}`)
        .then(() =>
          addToast({ type: 'success', title: 'Curso deletado com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao deletar curso' }),
        )
        .finally(() => mutate('/courses/index'));
    },
    [addToast, data, mutate],
  );

  return {
    error,
    getCourse,
    addCourse,
    updateCourse,
    deleteCourse,
    courses: data || [],
    updateCourseThumbnail,
    isLoading: !data && !error,
  };
}
