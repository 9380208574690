import React from 'react';
import { ScrollableContainer } from './styles';

interface ScrollableProps {
  className?: string;
  children: React.ReactNode;
}

const Scrollable = ({ className = '', children }: ScrollableProps) => {
  return (
    <ScrollableContainer className={className}>{children}</ScrollableContainer>
  );
};



export default Scrollable;
