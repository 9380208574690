import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useToast from '../../hooks/useToast';
import Teacher from '../../models/Teacher';
import api from '../api';
import { fetcher } from './swr';

export default function useCourseTeachers(courseId: string) {
  const { data, error } = useSWR<Teacher[]>(
    `/teachers/from-course/${courseId}`,
    fetcher,
    {
      dedupingInterval: 60 * 1000 * 60 * 24 * 7,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { mutate } = useSWRConfig();

  const { addToast } = useToast();

  const addTeachers = useCallback(
    (teachers: Teacher[]) => {
      if (!data || teachers.length === 0) return;

      const newData = [...data, ...teachers];
      mutate(`/teachers/from-course/${courseId}`, newData, false);

      api
        .post(`/courses/${courseId}/add-teachers`, {
          teachersIds: teachers.map(teacher => teacher.id),
        })
        .then(() =>
          addToast({
            type: 'success',
            title: 'Professores adicionados',
            description: `${teachers.length} professores adicionados com sucesso ao curso`,
          }),
        )
        .catch(() =>
          addToast({
            type: 'error',
            title: 'Erro ao adicionar professores',
            description: 'Não foi possível adicionar os professores ao curso',
          }),
        )
        .finally(() => mutate(`/teachers/from-course/${courseId}`));
    },
    [addToast, courseId, data, mutate],
  );

  const removeTeachers = useCallback(
    (teachersIds: string[]) => {
      if (!data) return;

      const newData = data.filter(teacher => !teachersIds.includes(teacher.id));
      mutate('/teachers/from-course/${courseId}', newData, false);

      api
        .delete(`/courses/${courseId}/remove-teachers`, {
          data: {
            teachersIds,
          },
        })
        .then(() =>
          addToast({
            title: 'Professores removidos',
            type: 'success',
            description: `${teachersIds.length} professores removidos com sucesso do curso`,
          }),
        )
        .catch(() =>
          addToast({
            title: 'Erro ao remover professores',
            type: 'error',
            description: 'Não foi possível remover os professores do curso',
          }),
        )
        .finally(() => mutate(`/teachers/from-course/${courseId}`));
    },
    [addToast, courseId, data, mutate],
  );

  return {
    teachers: data || [],
    error,
    isLoading: !error && !data,
    addTeachers,
    removeTeachers,
  };
}
