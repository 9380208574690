import React from 'react';
import useClassStudents from '../../services/swr/useClassStudents';
import Student from '../../models/Student';
import AddStudentsToClassModal from './AddStudentsToClassModal';
import useModalRef from '../../hooks/useModalRef';
import StudentsTable from '../../components/StudentTable';
import { useHistory } from 'react-router-dom';
import SearchableList from '../../components/SearchableList';

interface ComponentProps {
  classId: string;
}

const ClassStudentsTable = ({ classId }: ComponentProps) => {
  const { push } = useHistory();
  const { classStudents, removeStudents } = useClassStudents(classId);

  const addStudentsToClassModalRef = useModalRef();

  const handleSearch = (search: string, student: Student) => {
    const treatedStudentName = student.profile.name?.toLowerCase();

    const match =
      (treatedStudentName && treatedStudentName.includes(search)) ||
      student.profile.email.includes(search);

    return match;
  };

  return (
    <>
      <SearchableList
        dataArray={classStudents}
        searchMatches={handleSearch}
        onAdd={() => addStudentsToClassModalRef.current?.openModal()}
      >
        {({ data }) => (
          <>
            {data.length > 0 ? (
              <StudentsTable
                students={data}
                onEditStudent={student =>
                  push(`/student-details/${student.id}`)
                }
                onRemoveStudent={student => removeStudents([student])}
              />
            ) : (
              <p>Nenhum aluno cadastrado na turma</p>
            )}
          </>
        )}
      </SearchableList>

      <AddStudentsToClassModal
        ref={addStudentsToClassModalRef}
        classId={classId}
      />
    </>
  );
};

export default ClassStudentsTable;
