import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import useToast from '../../hooks/useToast';
import { FaCirclePlus } from 'react-icons/fa6';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CiCircleRemove } from 'react-icons/ci';
import { FormQuizContentData } from '../../models/Quiz';
import useQuestions from '../../services/swr/useQuestions';

interface FormContent {
  onSubmit: (data: FormQuizContentData[]) => void;
}

const Form = ({ onSubmit }: FormContent) => {
  const { addToast } = useToast();
  const [formContent, setFormContent] = useState<FormQuizContentData[]>([
    {
      description: '',
      options: ['', ''],
      correctAnswer: -1,
    },
  ]);

  const useForm = () => {
    const addQuestion = () => {
      const field = {
        description: '',
        options: ['', ''],
        correctAnswer: -1,
      };
      setFormContent([...formContent, field]);
    };

    const editField = (index: number, fieldLabel: string) => {
      const formFields = [...formContent];
      if (index > -1) {
        formFields[index].description = fieldLabel;
        setFormContent(formFields);
      }
    };

    const editOption = (
      index: number,
      optionIndex: number,
      value: string,
    ): void => {
      const formFields = [...formContent];
      if (index > -1) {
        formFields[index].options[optionIndex] = value;
        setFormContent(formFields);
      }
    };

    const addOption = (index: number) => {
      const formFields = [...formContent];
      if (index > -1) {
        const formField = formFields[index];
        if (formField.options.length <= 3) {
          formField.options.push('');
          setFormContent(formFields);
        }
      }
    };
    const handleCorrectAnswerChange = (
      questionIndex: number,
      optionIndex: number,
    ) => {
      const formFields = [...formContent];
      formFields[questionIndex].correctAnswer = optionIndex;
      setFormContent(formFields);
    };

    const handleDeleteOption = (fieldIndex: number, optionIndex: number) => {
      const formFields = [...formContent];
      formFields[fieldIndex].options.splice(optionIndex, 1);
      setFormContent(formFields);
    };

    const handleDeleteQuestion = (fieldIndex: number) => {
      const formFields = [...formContent];
      formFields.splice(fieldIndex, 1);
      setFormContent(formFields);
    };

    const validateForm = () => {
      return formContent.every(field => {
        return (
          field.description.length > 0 &&
          field.options.every(option => option.length >= 2) &&
          field.correctAnswer !== -1
        );
      });
    };

    const handleSubmit = () => {
      if (validateForm()) {
        onSubmit(formContent);
        return;
      }
      addToast({
        type: 'error',
        title: 'Erro ao criar questionário',
        description: 'Preencha todos os campos corretamente',
      });
    };

    return {
      addQuestion,
      editField,
      editOption,
      addOption,
      handleCorrectAnswerChange,
      handleDeleteOption,
      handleDeleteQuestion,
      handleSubmit,
    };
  };

  const {
    editField,
    addOption,
    editOption,
    addQuestion,
    handleSubmit,
    handleDeleteOption,
    handleDeleteQuestion,
    handleCorrectAnswerChange,
  } = useForm();

  const { attachQuestions } = useQuestions();

  const questionFile = useRef<File | null>(null);
  const questionInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async () => {
    if (questionInputRef.current && questionInputRef.current.files) {
      try {
        questionFile.current = questionInputRef.current.files[0];
        await attachQuestions(questionFile.current);
        addToast({
          type: 'success',
          title: 'Questões anexadas com sucesso',
          description: 'As questões foram anexadas com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao anexar questões',
          description: 'Ocorreu um erro ao anexar as questões, tente novamente',
        });
      }
    }
  };

  return (
    <Container>
      <SaveContainer>
        <SaveButton onClick={handleSubmit}>Enviar</SaveButton>
        <AttachQuizLabel htmlFor="attachQuestion">
          Anexar Questões
        </AttachQuizLabel>
        <AttachQuizInput
          id="attachQuestion"
          ref={questionInputRef}
          type="file"
          onChange={handleFileChange}
        />
      </SaveContainer>

      <QuestionContainer>
        {formContent.map((field, index) => (
          <QuestionCard key={index}>
            <div>
              <QuestionDescriptionInput
                type="text"
                value={field.description}
                placeholder="Questão"
                onChange={e => editField(index, e.target.value)}
              />
            </div>
            <div>
              {field && (
                <OptionsContainer>
                  <OptionsWrapper>
                    {field.options.map((option, optionIndex) => (
                      <OptionWrapper
                        key={`option${String.fromCharCode(65 + optionIndex)}`}
                      >
                        <CorrectAnswerInput
                          type="checkbox"
                          checked={field.correctAnswer === optionIndex}
                          onChange={() =>
                            handleCorrectAnswerChange(index, optionIndex)
                          }
                        />
                        <OptionInput
                          type="text"
                          value={option}
                          placeholder={`Alternativa ${String.fromCharCode(
                            65 + optionIndex,
                          )}`}
                          onChange={e =>
                            editOption(index, optionIndex, e.target.value)
                          }
                        />
                        <RemoveContainer witdh={'3rem'}>
                          <DeleteButton
                            onClick={() =>
                              handleDeleteOption(index, optionIndex)
                            }
                          >
                            <CiCircleRemove size={20} />
                          </DeleteButton>
                        </RemoveContainer>
                      </OptionWrapper>
                    ))}
                  </OptionsWrapper>
                  <AddOptionButton
                    isEnabled={field.options.length < 4}
                    onClick={() => addOption(index)}
                  >
                    <FaCirclePlus color={'#5f6368'} size={28} />
                  </AddOptionButton>
                </OptionsContainer>
              )}
            </div>
            <RemoveContainer witdh="100%">
              <DeleteButton onClick={() => handleDeleteQuestion(index)}>
                <RiDeleteBin6Line size={20} />
              </DeleteButton>
            </RemoveContainer>
          </QuestionCard>
        ))}
      </QuestionContainer>
      <ButtonsContainer>
        <AddQuestionButton onClick={addQuestion}>
          <FaCirclePlus size={28} /> Adicionar Questão
        </AddQuestionButton>
        <Save2Button onClick={handleSubmit}>Enviar</Save2Button>
      </ButtonsContainer>
    </Container>
  );
};

export default Form;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0 1rem;
  height: 100vh;
  width: 100%;
  margin-top: 2rem;
  gap: 1rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 60%;
  justify-content: center;
  align-items: start;
`;

const RemoveContainer = styled.div<{ witdh?: string }>`
  width: ${props => props.witdh || '5rem'};
  display: flex;
  justify-content: end;
  align-items: start;
  min-height: 2rem;

  padding-right: 1.25rem;

  cursor: pointer;
  color: rgba(31, 33, 37, 0.8);
  &:hover {
    opacity: 0.8;
    color: #ce5252;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OptionsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  padding-left: 1rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10rem;
`;
const AddOptionButton = styled.button<{ isEnabled: boolean }>`
  background-color: white;
  display: ${props => (props.isEnabled ? 'flex' : 'none')};
  cursor: pointer;
  border: none;
  transform: translateY(-0.5rem);
  padding-right: 1rem;
  &:hover {
    opacity: 0.8;
  }
`;

const AddQuestionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin-bottom: 3rem;
  background-color: #269912;
  color: white;
  border: none;
  cursor: pointer;
  height: fit-content;
  transition: background-color 0.3s;

  &:hover {
    background-color: #26aa00;
    color: white;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  justify-content: space-between;
`;
const OptionInput = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 1rem;
  height: 2rem;
  border: none;
  &:focus {
    outline: none;
    background-color: #e0e0e0;
    border-bottom: 2px solid #269912;
  }
  &:first-child {
    margin-top: 1.5rem;
  }
`;
const CorrectAnswerInput = styled.input`
  margin-right: 0.5rem;
  transform: translateY(-0.5rem);
  width: 27px;
  height: 25px;
  border-radius: 50%;
  appearance: none;
  background-color: #fff;
  border: 2px solid #269912;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #269912;
  }

  &:checked:after {
    transform: translate(-50%, -50%) scale(1);
  }

  &:after {
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(31, 33, 37, 0.8);
  &:hover {
    color: #d94242;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
`;

const QuestionDescriptionInput = styled.input`
  height: 2.5rem;
  width: 100%;
  padding-left: 1.25rem;
  border: none;
  margin-top: 1rem;

  &:focus {
    outline: none;
    background-color: #e0e0e0;
    border-bottom: 2px solid #269912;
  }
`;

const AttachQuizInput = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;

const AttachQuizLabel = styled.label`
  background-color: rgba(2, 6, 93, 1);
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;
const QuestionCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  width: 100%;
  padding: 1rem;
  border-top: 5px solid rgba(2, 53, 157, 0.8);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 2rem;
  align-items: center;
`;
const Save2Button = styled.button`
  background-color: rgba(2, 6, 93, 1);
  cursor: pointer;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 0.375rem;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;

const SaveButton = styled.button`
  background-color: rgba(2, 6, 93, 1);
  position: sticky;
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;
