import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import Class, { UpdateClassDTO } from '../../models/Class';
import api from '../api';
import { fetcher } from './swr';
import useToast from '../../hooks/useToast';

export default function useClasses() {
  const { addToast } = useToast();
  const { data, error } = useSWR<Class[]>('/classes/index', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 12,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { mutate } = useSWRConfig();

  const getClass = useCallback(
    (classId: string) => data?.find(classItem => classItem.id === classId),
    [data],
  );

  const addClass = useCallback(
    (classObj: Omit<Class, 'id'>) => {
      api
        .post('/classes/register', {
          title: classObj.title,
          synchronousClassLink: classObj.synchronousClassLink || null,
          schoolId: classObj.schoolId || null,
          gradeRange: classObj.gradeRange || null,
        })
        .then(() =>
          addToast({ type: 'success', title: 'Turma cadastrada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao cadastrar Turma' }),
        )
        .finally(() => mutate('/classes/index'));
    },
    [addToast, mutate],
  );

  const updateClass = useCallback(
    (updateClassDTO: UpdateClassDTO) => {
      if (data) {
        const newData = data.map(c => {
          if (c.id === updateClassDTO.id)
            return Object.assign(c, updateClassDTO);
          return c;
        });

        mutate('/classes/index', newData, false);
      }

      api
        .put('/classes/update', updateClassDTO)
        .then(() =>
          addToast({ type: 'success', title: 'Tuma editada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao atualizar a turma' }),
        )
        .finally(() => mutate('/classes/index'));
    },
    [addToast, data, mutate],
  );

  const removeClass = useCallback(
    (classId: string) => {
      if (data)
        mutate(
          '/classes/index',
          data.filter(c => c.id !== classId),
          false,
        );

      api
        .delete(`/classes/delete/${classId}`)
        .then(() =>
          addToast({ type: 'success', title: 'Turma deletada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao deletar turma' }),
        )
        .finally(() => mutate('/classes/index'));
    },
    [addToast, data, mutate],
  );

  return {
    classes: data || [],
    getClass,
    addClass,
    updateClass,
    removeClass,
    error,
    isLoading: !data && !error,
  };
}
