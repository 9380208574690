import useSWR, { mutate } from 'swr';
import { fetcher } from './swr';
import { useCallback } from 'react';
import api from '../api';
import { Question } from '../../models/Question';
import { FormQuizContentData } from '../../models/Quiz';

const useQuestions = () => {
  const { data, error } = useSWR<Question[] | undefined>('/question', fetcher, {
    dedupingInterval: 60 * 1000 * 60,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const removeQuestionFromQuiz = useCallback(
    async (questionId: string, quizId: string) => {
      await api.delete(`/question/delete/${questionId}/${quizId}`);
    },
    [],
  );

  const toQuestion = (question: FormQuizContentData): Omit<Question, 'id'> => {
    return {
      description: question.description,
      optionA: question.options[0],
      optionB: question.options[1],
      optionC: question.options[2],
      optionD: question.options[3],
      target: `option${String.fromCharCode(65 + question.correctAnswer)}`,
    };
  };

  const attachQuestions = useCallback(async (file: File) => {
    const formData = new FormData();
    formData.append('questions', file);

    await api.post('/question/bulk-create', formData);
    mutate('/question');
  }, []);

  const addQuestions = useCallback(
    async (formQuizContentData: FormQuizContentData[]) => {
      const questions = formQuizContentData.map(toQuestion);
      await api.post('/question/create', questions);
      mutate('/question');
    },
    [],
  );

  const addQuestion = useCallback(async (question: Question) => {
    await api.post('/question/create', question);
    mutate('/question');
  }, []);

  const addQuestionToQuiz = useCallback(
    async (questionIds: string[], quizId: string) => {
      await api.post(`/question/add-to-quiz/${quizId}`, { questionIds });
      mutate('/question');
    },
    [],
  );

  const removeQuestion = useCallback(async (questionId: string) => {
    await api.delete(`/question/delete/${questionId}`);
    mutate('/question');
  }, []);

  return {
    error,
    addQuestion,
    addQuestions,
    removeQuestion,
    attachQuestions,
    addQuestionToQuiz,
    questions: data || [],
    removeQuestionFromQuiz,
    isLoading: !data && !error,
  };
};

export default useQuestions;
