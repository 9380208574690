import React, { useCallback } from 'react';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextArea from '../../components/GenericTextArea';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import useCourses from '../../services/swr/useCourses';
import { ModalContainer } from './CreateCourseModal.styles';
import { FormikHelpers } from 'formik';

const CreateClassModal = React.forwardRef((_, ref) => {
  const { addCourse } = useCourses();

  const onSubmit = useCallback(
    (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>) => {
      addCourse({
        title: values.title as string,
        description: values.description as string,
        thumbnailUrl: values['thumbnail-url'] as string || null,
      });

      setSubmitting(false);
      resetForm();

      (ref as ModalRef).current?.closeModal();
    },
    [addCourse, ref],
  );

  return (
    <ModalContainer ref={ref}>
      <GenericModalForm
        initialValues={{
          title: '',
          description: '',
          'thumbnail-url': '',
        }}
        submitButtonType="create"
        onSubmit={onSubmit}
        onCancel={() => (ref as ModalRef).current?.closeModal()}
      >
        <GenericTextInput
          name="title"
          label="Nome do Curso"
          type="text"
          placeholder="Curso de Programação"
        />
        <GenericTextArea
          name="description"
          label="Descrição do Curso"
          placeholder="Descrição do Curso"
        />
        <GenericTextInput
          name="thumbnail-url"
          label="URL da Thumbnail"
          type="text"
          placeholder="https://s3.us-west-002.backblazeb2.com/<file>"
        />
      </GenericModalForm>
    </ModalContainer>
  );
});

CreateClassModal.displayName = 'CreateClassModal';

export default CreateClassModal;
