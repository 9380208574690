import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import { UpdateProfileDTO } from '../../models/Profile';
import Student from '../../models/Student';
import useStudents from '../../services/swr/useStudents';
import { stripEmptyFields } from '../../utils/objects';

import { ModalContainer, ModalContent } from './EditStudentModal.styles';
import { FormikHelpers } from 'formik';

interface ComponentProps {
  studentInfo: Student;
}

const EditStudentModal = React.forwardRef<unknown, ComponentProps>(
  ({ studentInfo }, ref) => {
    const { updateStudentProfile } = useStudents();

    const onSubmit = useCallback(
      async (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>> ) => {
        const updateParentProfileDTO: UpdateProfileDTO = {
          id: studentInfo.profile.id,
          ...stripEmptyFields(values),
        };
        updateStudentProfile(updateParentProfileDTO);

        (ref as ModalRef).current?.closeModal();

        setSubmitting(false);
        resetForm();
      },
      [ref, studentInfo.profile.id, updateStudentProfile],
    );

    return (
      <ModalContainer ref={ref}>
        <ModalContent>
          <GenericModalForm
            initialValues={{
              name: '',
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              email: Yup.string().email('Digite um e-Mail válido'),
              password: Yup.string().min(
                6,
                'Senha deve conter ao menos 6 caracteres',
              ),
            })}
            submitButtonType="edit"
            onSubmit={onSubmit}
            onCancel={() => (ref as ModalRef).current?.closeModal()}
          >
            <GenericTextInput
              name="name"
              label="Nome"
              type="text"
              placeholder={studentInfo.profile.name || 'João da Silva'}
            />
            <GenericTextInput
              name="email"
              label="e-Mail"
              type="email"
              placeholder={studentInfo.profile.email}
            />
            <GenericTextInput
              name="password"
              label="Senha"
              type="password"
              placeholder="***********"
            />
          </GenericModalForm>
        </ModalContent>
      </ModalContainer>
    );
  },
);

EditStudentModal.displayName = 'EditStudentModal';

export default EditStudentModal;
