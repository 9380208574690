import React, { useCallback } from 'react';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import useParents from '../../services/swr/useParents';
import Parent from '../../models/Parent';
import { ParentInfo, ParentListItemContainer } from './ParentsListItem.styles';
import { Link, useHistory } from 'react-router-dom';

interface ComponentProps {
  parentInfo: Parent;
}

const ParentListItem  = ({ parentInfo }: ComponentProps) => {
  const { deleteParent } = useParents();
  const { push } = useHistory();

  const handleDelete = useCallback(() => {
    deleteParent(parentInfo.id);
  }, [deleteParent, parentInfo.id]);

  return (
    <ParentListItemContainer>
      <Link to={`/parent-details/${parentInfo.id}`} className="link">
        <ParentInfo>
          <p className="parent-name">{parentInfo.profile.name}</p>
          <p className="parent-email">{parentInfo.profile.email}</p>
        </ParentInfo>
      </Link>
      <MutableListItemOptions
        objectName={parentInfo.profile.name || parentInfo.id}
        onEdit={() => push(`/parent-details/${parentInfo.id}`)}
        onRemove={handleDelete}
      />
    </ParentListItemContainer>
  );
};

export default ParentListItem;
