import useSWR from 'swr';
import ClassAnalytics from '../../models/ClassAnalytics';
import { fetcher } from './swr';

export default function useClassAnalytics(classId: string) {
  const { data } = useSWR<ClassAnalytics>(
    `/lesson-analytics/class-lessons-report?classId=${classId}`,
    fetcher,
  );

  return { classAnalytics: data };
}
