import React, { useMemo } from 'react';
import Profile from '../../models/Profile';
import {
  ResponsivityWrapper,
  UserAvatar,
  UserInfo,
} from './UserProfileDetails.styles';

interface ComponentProps {
  userProfile: Profile;
}

const UserProfileDetails = ({ userProfile }: ComponentProps) => {
  const userAvatarUrl = useMemo(() => {
    const avatarToken = userProfile.name ? userProfile.name : userProfile.email;

    return `https://api.multiavatar.com/${avatarToken}.svg`;
  }, [userProfile]);

  return (
    <ResponsivityWrapper>
      <UserAvatar src={userAvatarUrl} alt="" />
      <UserInfo>
        <p className="name">{userProfile.name}</p>
        <p className="email">{userProfile.email}</p>
      </UserInfo>
    </ResponsivityWrapper>
  );
};

export default UserProfileDetails;
