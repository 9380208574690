import { useCallback } from 'react';
import useSWR, { mutate } from 'swr';
import useToast from '../../hooks/useToast';
import { UpdateProfileDTO } from '../../models/Profile';
import Student, { CreateStudentDTO } from '../../models/Student';
import api from '../api';
import { fetcher } from './swr';

export default function useStudents() {
  const { addToast } = useToast();
  const { data, error } = useSWR<Student[]>('/students/index', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 2,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const getStudent = useCallback(
    (studentId: string) => data?.find(student => student.id === studentId),
    [data],
  );

  const addStudent = useCallback(
    async (createStudentDTO: CreateStudentDTO) => {
      try {
        await api.post('/students/register', createStudentDTO);
        addToast({
          type: 'success',
          title: 'Aluno criado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao criar aluno',
        });
      } finally {
        mutate('/students/index');
      }
    },
    [addToast],
  );

  const updateStudentProfile = useCallback(
    async (studentProfile: UpdateProfileDTO) => {
      if (!data) return;

      const { id: profileId, password, ...studentProfileInfo } = studentProfile;

      const newData = data.map(student => {
        if (student.profile.id === profileId) {
          return {
            ...student,
            profile: Object.assign(student.profile, studentProfileInfo),
          };
        }

        return student;
      });

      mutate('/students/index', newData, false);

      try {
        await api.put(`/users/update/${profileId}`, {
          ...studentProfileInfo,
          ...(password && {
            password,
            passwordConfirmation: password,
          }),
        });

        addToast({
          type: 'success',
          title: 'Aluno editado com sucesso',
        });
      } catch (err) {
        addToast({ type: 'error', title: 'Erro ao editar alhno' });
      } finally {
        mutate('/students/index');
      }
    },
    [addToast, data],
  );

  const deleteStudent = useCallback(
    async (studentId: string) => {
      if (!data) return;

      mutate(
        '/students/index',
        data.filter(student => student.id !== studentId),
        false,
      );

      try {
        await api.delete(`/students/delete/${studentId}`);

        addToast({
          type: 'success',
          title: 'Aluno deletado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao deletar aluno',
          description: `${err}`,
        });
      } finally {
        mutate('/students/index');
      }
    },
    [addToast, data],
  );

  return {
    students: data || [],
    error,
    isLoading: !data && !error,
    getStudent,
    addStudent,
    updateStudentProfile,
    deleteStudent,
  };
}
