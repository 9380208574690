import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';

export const ModalContainer = styled(GenericModal)`
  width: 80%;
  max-width: 360px;

  .modal-title {
    font-family: var(--font-sans);
    font-size: 1.563rem;
    margin-bottom: 30px;
  }
`;
