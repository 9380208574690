import styled from 'styled-components';
import CallToActionButton from '../CallToActionButton';
import GenericSecondaryButton from '../GenericSecondaryButton';
import SearchBarBase from './SearchBar';

export const SearchControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 480px) {
    flex-direction: row;
    align-items: center;
  }

  .controls-buttons {
    display: flex;
    margin-left: 16px;

    @media screen and (max-width: 480px) {
      margin: 0 16px 0 0;
    }
  }
`;

export const SearchBar = styled(SearchBarBase)`
  max-width: 720px;

  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

export const SortButton = styled(GenericSecondaryButton)`
  font-weight: 500;

  margin-right: 8px;
`;

export const AddButton = styled(CallToActionButton)`
  display: flex;
  align-items: center;

  font-weight: 500;
  height: 42px;

  .add-icon {
    margin-right: 8px;
    min-width: 16px;
    min-height: 16px;
  }
`;
