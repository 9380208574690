import styled from 'styled-components';

export const ToolTipContainer = styled.span`
  position: absolute;
`;

export const ToolTipText = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
`;

export const ToolTipIcon = styled.span`
  border-radius: 50%;
  background-color: var(--color-dark-blue);
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
  font-size: 15px;
  font-weight: bold;
  transition: all 0.3s;
  &:hover {
    background-color: #555;
    color: white;
  }
`;
