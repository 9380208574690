import React from 'react';
import { Link } from 'react-router-dom';
import Teacher from '../../models/Teacher';
import useCourseTeachers from '../../services/swr/useCourseTeachers';
import {
  ItemOptions,
  ListItemContainer,
} from './CourseTeachersListItem.styles';

interface ComponentProps {
  teacherInfo: Teacher;
  courseId: string;
}

const CourseTeachersListItem = ({
  teacherInfo,
  courseId
}: ComponentProps) => {
  const { removeTeachers } = useCourseTeachers(courseId);

  return (
    <>
      <ListItemContainer>
        <Link to="" className="link">
          <p className="teacher-name">{teacherInfo.profile.name}</p>
          <p className="teacher-email">{teacherInfo.profile.email}</p>
        </Link>
        <ItemOptions
          objectName={teacherInfo.profile.name || teacherInfo.profile.email}
          onEdit={() => {}}
          onRemove={() => removeTeachers([teacherInfo.id])}
        />
      </ListItemContainer>
    </>
  );
};

export default CourseTeachersListItem;
