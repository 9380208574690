import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import Teacher, { UpdateTeacherDTO } from '../../models/Teacher';
import useTeachers from '../../services/swr/useTeachers';
import { stripEmptyFields } from '../../utils/objects';
import { ModalContainer, ModalContent } from './EditTeacherModal.styles';
import { FormikHelpers } from 'formik';

interface ComponentProps {
  teacherInfo: Teacher;
}

const EditTeacherModal = React.forwardRef(
  ({ teacherInfo }: ComponentProps, ref) => {
    const { updateTeacherProfile } = useTeachers();

    const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );

    const onSubmit = useCallback(
      async (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>) => {
        const updateTeacherDTO: UpdateTeacherDTO = {
          id: teacherInfo.profile.id,
          ...stripEmptyFields(values),
        };
        updateTeacherProfile(updateTeacherDTO);

        setSubmitting(false);
        resetForm();
        closeModal();
      },
      [closeModal, teacherInfo.profile.id, updateTeacherProfile],
    );

    return (
      <ModalContainer ref={ref}>
        <ModalContent>
          <h1 className="modal-title">Editar Professor</h1>

          <GenericModalForm
            initialValues={{
              name: '',
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              email: Yup.string().email('Digite um e-Mail válido'),
              password: Yup.string().min(
                6,
                'Senha deve conter ao menos 6 caracteres',
              ),
            })}
            submitButtonType="edit"
            onSubmit={onSubmit}
            onCancel={closeModal}
          >
            <GenericTextInput
              name="name"
              label="Nome"
              type="text"
              placeholder={teacherInfo.profile.name || 'João da Silva'}
            />
            <GenericTextInput
              name="email"
              label="e-Mail"
              type="email"
              placeholder={teacherInfo.profile.email}
            />
            <GenericTextInput
              name="password"
              label="Senha"
              type="password"
              placeholder="***********"
            />
          </GenericModalForm>
        </ModalContent>
      </ModalContainer>
    );
  },
);

EditTeacherModal.displayName = 'EditTeacherModal';

export default EditTeacherModal;
