import React from 'react';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import {
  TeacherInfo,
  TeacherListItemContainer,
} from './TeacherListItem.styles';
import Teacher from '../../models/Teacher';
import { Link, useHistory } from 'react-router-dom';
import useTeachers from '../../services/swr/useTeachers';

interface ComponentProps {
  teacherInfo: Teacher;
}

const TeacherListItem = ({ teacherInfo }: ComponentProps) => {
  const { deleteTeacher } = useTeachers();
  const { push } = useHistory();

  const handleDelete = () => deleteTeacher(teacherInfo.id);

  return (
    <TeacherListItemContainer>
      <Link to={`/teacher-details/${teacherInfo.id}`} className="link">
        <TeacherInfo>
          <p className="teacher-name">{teacherInfo.profile.name}</p>
          <p className="teacher-email">{teacherInfo.profile.email}</p>
        </TeacherInfo>
      </Link>
      <MutableListItemOptions
        objectName={teacherInfo.profile.name || teacherInfo.id}
        onEdit={() => push(`/teacher-details/${teacherInfo.id}`)}
        onRemove={handleDelete}
      />
    </TeacherListItemContainer>
  );
};

export default TeacherListItem;
