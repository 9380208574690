import styled from 'styled-components';

import GenericModal from '../GenericModal';
import StrippedButton from '../StrippedButton';

export const PromptContainer = styled(GenericModal)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 24px 0px;
`;

export const ClosePromptButton = styled(StrippedButton)`
  width: 24px;
  height: 24px;
  margin-left: auto;
`;

export const PromptContent = styled.div`
  font-family: var(--font-sans);

  .prompt-text {
    margin: 0 24px 24px 0;
    font-size: 1.125rem;
  }
`;

export const PromptOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 1.125rem;

  .cancel-button {
    color: var(--color-grey);
    text-decoration: underline;

    margin-right: 16px;
  }

  .confirm-button {
    font-weight: 500;
    color: var(--color-white);

    padding: 8px;
    background-color: var(--color-dark-green);

    border-radius: 8px;
  }
`;
