import styled from 'styled-components';

const colors = {
  default: '#000000',
  blue: '#07224b',
  matematica: '#145408',
  programacao: '#FF4500',
  cyberseguranca: '#0000FF',
  calculo: '#8A2BE2',
  ciencias: '#00FF00',
  informatica: '#dbbb0f',
  loops: '#FF69B4',
  condicionais: '#FF6347',
  variaveis: '#FF8C00',
  '1ºano': '#A52A2A',
  '2ºano': '#FF69B4',
};

function normalizeInput(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const Tags = styled.p<{ tagColor?: string }>`
  font-family: var(--font-sans);
  font-size: 0.675rem;
  background-color: ${({ tagColor }) => {
    const getRandomColor = () => {
      const colorKeys = Object.keys(colors) as Array<keyof typeof colors>;
      const randomKey = colorKeys[Math.floor(Math.random() * colorKeys.length)];
      return colors[randomKey];
    };
    if (tagColor && normalizeInput(tagColor.trim().toLowerCase()) in colors) {
      return colors[
        normalizeInput(tagColor.trim().toLowerCase()) as keyof typeof colors
      ];
    }
    return getRandomColor();
  }};
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 6px;
  color: white;
`;

export const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
  flex-direction: row;
`;
