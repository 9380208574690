import styled from 'styled-components';
import MutableListItemOptions from '../../components/MutableListItemOptions';

export const ListItemContainer = styled.li`
  display: flex;
  align-items: center;

  .link {
    font-family: var(--font-sans);
    font-size: 1.25rem;
    text-decoration: none;
    color: var(--color-black);
  }

  .teacher-name {
    font-weight: 500;
  }
`;

export const ItemOptions = styled(MutableListItemOptions)`
  margin-left: auto;
`;
