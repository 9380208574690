import React, { useCallback } from 'react';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import useClasses from '../../services/swr/useClasses';
import { ModalContainer } from './CreateClassModal.styles';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import useSchools from '../../services/swr/useSchools';
import GenericSelectList from '../../components/GenericSelectList';

const CreateClassModal = React.forwardRef((_, ref) => {
  const { addClass } = useClasses();
  const { schools } = useSchools();

  const onSubmit = useCallback(
    (values: Record<string, unknown>, { setSubmitting }: FormikHelpers<Record<string, unknown>>) => {
      addClass({
        title: (values.title) as string,
        synchronousClassLink: (values.link) as string || null,
        schoolId: (values.schoolId) as string || null,
        gradeRange: (values.gradeRange) as string || null,
      });

      setSubmitting(false);

      (ref as ModalRef).current?.closeModal();
    },
    [addClass, ref],
  );

  return (
    <ModalContainer ref={ref}>
      <h1 className="modal-title">Criar nova Turma</h1>
      <GenericModalForm
        initialValues={{
          title: '',
          link: '',
          schoolId: '',
          gradeRange: '',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Nome da turma é obrigatório'),
          link: Yup.string().url('O link deve ser uma URL válida'),
          schoolId: Yup.string().required('Escola é obrigatório').min(2, 'Escola é obrigatória'),
          gradeRange: Yup.string().required('Faixa de série é obrigatória'),
        })}
        submitButtonType="create"
        onSubmit={onSubmit}
        onCancel={() => (ref as ModalRef).current?.closeModal()}
      >
        <GenericTextInput
          name="title"
          label="Nome da Turma"
          type="text"
          placeholder="Turma de Lógica"
        />
        <GenericTextInput
          name="link"
          label="Link de Aula Síncrona"
          type="text"
          placeholder={'https://meet.google.com/abc-123-xyz'}
        />
        <GenericSelectList
          name="schoolId"
          dataArray={schools}
          optionLabel="name"
          label="Pertence à qual escola"
          placeHolder="Selecione a Escola"
        />
        <GenericTextInput
          name="gradeRange"
          label="Faixa de Série"
          type="text"
          tooltip="Exemplo: 1-4 para turmas do 1º ao 4º ano. E 2 para turmas com apenas 2º ano."
          placeholder="1-4"
        />
      </GenericModalForm>
    </ModalContainer>
  );
});

CreateClassModal.displayName = 'CreateClassModal';

export default CreateClassModal;
