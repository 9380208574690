import { useEffect, useState } from 'react';
import { LessonFeedback } from '../../models/Lesson';
import api from '../api';

export default function useCourseLessonsFeedbacks(courseId: string) {
  const [feedbacks, setFeedbacks] = useState<LessonFeedback[]>([]);

  useEffect(() => {
    api.get(`/feedbacks/get-ratings-by-course/${courseId}`).then(response => {
      setFeedbacks(response.data);
    });
  }, [courseId]);

  return {
    feedbacks,
  };
}
