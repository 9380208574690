import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import EditableDeletableOptions from '../../components/EditableDeletableOptions';
import NavigationBar from '../../components/NavigationBar';
import { Tab } from '../../components/TabNavigator';
import UserProfileDetails from '../../components/UserProfileDetails';
import useModalRef from '../../hooks/useModalRef';
import Parent from '../../models/Parent';
import ParentDetailsPageUrlParams from '../../routes/location/ParentDetailsPageUrlParams';
import useParents from '../../services/swr/useParents';
import NotFoundPage from '../404';
import EditParentModal from './EditParentModal';
import {
  PageContent,
  PageHeader,
  ParentDetailsTabNavigator,
} from './ParentDetailsPage.styles';

const ParentDetailsPage = () => {
  const { parentId } = useParams<ParentDetailsPageUrlParams>();
  const { getParent, deleteParent, isLoading } = useParents();
  const parentInfo = getParent(parentId) as Parent;

  const { push } = useHistory();

  const deleteParentPromptRef = useModalRef();
  const editParentModalRef = useModalRef();

  const handleDeleteParent = () => {
    deleteParent(parentInfo.id);
    push('/parents-management');
  };

  if (!parentInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />
      <PageContent>
        {parentInfo && (
          <>
            <PageHeader>
              <UserProfileDetails userProfile={parentInfo.profile} />

              <EditableDeletableOptions
                onEdit={() => editParentModalRef.current?.openModal()}
                onDelete={() => deleteParentPromptRef.current?.openModal()}
              />
            </PageHeader>

            <ParentDetailsTabNavigator>
              <Tab label={'Alunos'}>
                <p style={{ marginTop: '16px' }}>{'<<feature>>'}</p>
              </Tab>
              <Tab label={'Pagamentos'}>
                <p style={{ marginTop: '16px' }}>{'<<feature>>'}</p>
              </Tab>
            </ParentDetailsTabNavigator>

            <ConfirmationPrompt
              ref={deleteParentPromptRef}
              promptText={`Tem certeza que quer deletar "${parentInfo.profile.name}" permanentemente?`}
              onConfirm={handleDeleteParent}
            />
            <EditParentModal ref={editParentModalRef} parentInfo={parentInfo} />
          </>
        )}
      </PageContent>
    </>
  );
};

export default ParentDetailsPage;
