import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { convertUrlToEmbedUrl } from '../../utils/youtube';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import GenericTextArea from '../../components/GenericTextArea';
import { ModalContainer } from './AddLessonToCourseModal.styles';
import useCourseLessons from '../../services/swr/useCourseLessons';
import GenericCheckbox from '../../components/GenericCheckbox';
import { ModalRef } from '../../hooks/useModalRef';
import { FormikHelpers } from 'formik';
interface ComponentProps {
  courseId: string;
}

const AddLessonToCourseModal = React.forwardRef(
  ({ courseId }: ComponentProps, ref) => {
    const { addLesson } = useCourseLessons(courseId);

    const handleSubmit = useCallback(
      (values: Record<string, unknown>, { setSubmitting , resetForm }: FormikHelpers<Record<string, unknown>>) => {
        addLesson({
          title: (values['lesson-name']) as string,
          description: (values['lesson-description']) as string,
          videoUri: convertUrlToEmbedUrl((values['lesson-video-url']) as string),
          assignmentUri: (values['lesson-assignment-url']) as string || null,
          balance: (values['balance']) as number,
          experiencePoints: (values['experiencePoints']) as number,
          isVisible: (values['is-visible']) as boolean,
        });

        setSubmitting(false);
        resetForm();
        (ref as ModalRef).current?.closeModal();
        (document.querySelector('#is-visible-ckb') as HTMLInputElement).checked = false;
      },
      [addLesson, ref],
    );

    return (
      <ModalContainer ref={ref}>
        <GenericModalForm
          initialValues={{
            'lesson-name': '',
            'lesson-description': '',
            'lesson-video-url': '',
            'lesson-assignment-url': '',
            'is-visible': true,
            'balance': 0,
            'experiencePoints': 0,
          }}
          validationSchema={Yup.object().shape({
            'lesson-name': Yup.string().required('Nome da aula é obrigatório'),
            'lesson-description': Yup.string().required(
              'Descrição da aula é obrigatória',
            ),
            'lesson-video-url': Yup.string().required(
              'URL do vídeo é obrigatória',
            ),
            'lesson-assignment-url': Yup.string(),
          })}
          submitButtonType="create"
          onSubmit={handleSubmit}
          onCancel={() => (ref as ModalRef).current?.closeModal()}
        >
          <GenericTextInput
            name="lesson-name"
            label="Título da Aula"
            type="text"
            placeholder="Aula <#> do curso"
          />

          <GenericTextArea
            name="lesson-description"
            label="Descrição da Aula"
            placeholder="Descrição da aula"
          />

          <GenericTextInput
            name="lesson-video-url"
            label="URL do Vídeo"
            type="text"
            placeholder="'https://www.youtube.com/embed/DFiU4pt6P5k'"
          />

          <GenericTextInput
            name="lesson-assignment-url"
            label="URL da Atividade"
            type="text"
            placeholder="https://www.code.org"
          />
           <GenericTextInput
            name="balance"
            label="Quantidade de Moedas"
            type="number"
            placeholder="itcoins"
          />
           <GenericTextInput
            name="experiencePoints"
            type="number"
            label="Quantidade de Pontos de Experiência"
            placeholder="xp"
          />

          <GenericCheckbox
            id="is-visible-ckb"
            name="is-visible"
            text="Aula visível para alunos"
          />
        </GenericModalForm>
      </ModalContainer>
    );
  },
);

AddLessonToCourseModal.displayName = 'AddLessonToCourseModal';

export default AddLessonToCourseModal;
