import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import Teacher from '../../models/Teacher';
import TeacherDetailsPageUrlParams from '../../routes/location/TeacherDetailsPageUrlParams';
import useTeachers from '../../services/swr/useTeachers';
import NotFoundPage from '../404';
import {
  PageContent,
  PageHeader,
  TeacherDetailsTabNavigator,
} from './TeachersDetailsPage.styles';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import useModalRef from '../../hooks/useModalRef';
import EditableDeletableOptions from '../../components/EditableDeletableOptions';
import { Tab } from '../../components/TabNavigator';
import UserProfileDetails from '../../components/UserProfileDetails';
import EditTeacherModal from './EditTeacherModal';

const TeacherDetailsPage = () => {
  const { teacherId } = useParams<TeacherDetailsPageUrlParams>();
  const { getTeacher, deleteTeacher, isLoading } = useTeachers();
  const { push } = useHistory();

  const teacherInfo = getTeacher(teacherId) as Teacher;

  const deleteTeacherPromptRef = useModalRef();
  const editTeacherModalRef = useModalRef();

  const handleDeleteTeacher = () => {
    deleteTeacher(teacherInfo.id);
    push('/teachers-management');
  };

  if (!teacherInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />
      <PageContent>
        {teacherInfo && (
          <>
            <PageHeader>
              <UserProfileDetails userProfile={teacherInfo.profile} />

              <EditableDeletableOptions
                onEdit={() => editTeacherModalRef.current?.openModal()}
                onDelete={() => deleteTeacherPromptRef.current?.openModal()}
              />
            </PageHeader>

            <TeacherDetailsTabNavigator>
              <Tab label={'Cursos'}>
                <p style={{ marginTop: '16px' }}>{'<<feature>>'}</p>
              </Tab>
            </TeacherDetailsTabNavigator>

            <ConfirmationPrompt
              ref={deleteTeacherPromptRef}
              promptText={`Tem certeza que quer deletar "${teacherInfo.profile.name}" permanentemente?`}
              onConfirm={handleDeleteTeacher}
            />
            <EditTeacherModal
              ref={editTeacherModalRef}
              teacherInfo={teacherInfo}
            />
          </>
        )}
      </PageContent>
    </>
  );
};

export default TeacherDetailsPage;
