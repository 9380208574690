import React, { useCallback, useState, useMemo } from 'react';
import useFilter from '../../hooks/useFilter';
import useToast from '../../hooks/useToast';
import {
  ModalContainer,
  TeachersListControls,
  ListItemContainer,
} from './AddTeachersToCourseModal.styles';
import useCourseTeachers from '../../services/swr/useCourseTeachers';
import useTeachers from '../../services/swr/useTeachers';
import Teacher from '../../models/Teacher';
import { ModalRef } from '../../hooks/useModalRef';
import GenericSelectableList from '../../components/GenericSelectableList';

interface ComponentProps {
  courseId: string;
}

const TeacherListItem = ({ data }: { data: Teacher }) => {
  return (
    <ListItemContainer>
      <p className="teacher-name">{data.profile.name}</p>
      <p className="teacher-email">{data.profile.email}</p>
    </ListItemContainer>
  );
};

const AddTeachersModal = React.forwardRef(
  ({ courseId }: ComponentProps, ref) => {
    const { addToast } = useToast();
    const { teachers: courseTeachers, addTeachers } =
      useCourseTeachers(courseId);
    const { teachers } = useTeachers();

    const teachersNotInCourse = useMemo<Teacher[]>(() => {
      const courseTeachersIds = courseTeachers.map(t => t.id);
      const teachersNotInCourse = teachers.filter(
        t => !courseTeachersIds.includes(t.id),
      );

      return teachersNotInCourse;
    }, [courseTeachers, teachers]);

    const {
      filter,
      clearFilter,
      filteredData: filteredTeachers,
    } = useFilter<Teacher>(teachersNotInCourse);

    const [isUsingSearch, setIsUsingSearch] = useState(false);

    const handleSearch = useCallback(
      async (search: string) => {
        const treatedSearch = search.toLowerCase().trim();

        filter(teacher => {
          const treatedName = teacher.profile.name?.toLowerCase();
          if (treatedName && treatedName.includes(treatedSearch)) return true;
          if (teacher.profile.email.includes(treatedSearch)) return true;

          return false;
        });

        setIsUsingSearch(true);
      },
      [filter],
    );

    const handleSubmission = useCallback(
      (data: Teacher[]) => {
        if (data.length === 0) {
          addToast({
            type: 'error',
            title: 'Nenhum professor foi selecionado',
            description:
              'Por favor selecione pelo menos um professor para adicionar ao curso',
          });

          return;
        }

        addTeachers(data);

        if (ref) {
          (ref as ModalRef).current?.closeModal();
        }
      },
      [addTeachers, addToast, ref],
    );

    return (
      <ModalContainer ref={ref}>
        <TeachersListControls
          onSearch={handleSearch}
          onClearSearch={clearFilter}
        />

        <GenericSelectableList
          dataArray={isUsingSearch ? filteredTeachers : teachersNotInCourse}
          confirmSelectionButtonText="Adicionar Professores"
          onConfirmSelection={handleSubmission}
          listItemComponent={TeacherListItem}
        ></GenericSelectableList>
      </ModalContainer>
    );
  },
);

AddTeachersModal.displayName = 'AddTeachersModal';

export default AddTeachersModal;
