import styled from 'styled-components';

export const ParentListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .link {
    text-decoration: none;
  }
`;

export const ParentInfo = styled.article`
  .parent-name,
  .parent-email {
    font-family: var(--font-sans);
    font-size: 1.25rem;
    color: var(--color-black);
  }

  .parent-name {
    font-weight: 500;
  }
`;
