import styled from 'styled-components';
import { ContentWithFooter } from '../../components/Footer';
import SectionHeader from '../../components/SectionHeader';
import Color from '../../styles/colors';

export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const PageHeader = styled(SectionHeader)`
  margin-bottom: 16px;
`;

export const FreeTrialRequestsTableContainer = styled.div`
  overflow-x: scroll;
`;

export const FreeTrialRequestsTable = styled.table`
  width: 100%;
  border-spacing: 0 16px;

  @media (max-width: 1024px) {
    border-spacing: 16px 8px;
  }

  .table-header {
    font-family: var(--font-sans);
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--color-black);

    th {
      text-align: left;
    }
  }
`;

const dividerColor = new Color('grey').withAlpha(0.2).rgba();

export const TableRowDivider = styled.tr`
  .divider {
    height: 1px;
    width: 100%;
    background-color: ${dividerColor};
  }
`;
