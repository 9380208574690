import React, { useCallback } from 'react';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import useClasses from '../../services/swr/useClasses';
import Class from '../../models/Class';
import { ModalContainer } from './EditClassModal.styles';
import { ModalRef } from '../../hooks/useModalRef';
import { stripEmptyFields } from '../../utils/objects';
import { FormikHelpers } from 'formik';
import useSchools from '../../services/swr/useSchools';
import GenericSelectList from '../../components/GenericSelectList';

interface ComponentProps {
  classInfo: Class;
}

const EditClassModal = React.forwardRef(
  ({ classInfo }: ComponentProps, ref) => {
    const { updateClass } = useClasses();
    const { schools } = useSchools();
    const school = schools.find((school) => school.id === classInfo.schoolId);
    const onSubmit = useCallback(
      (values: Record<string, unknown>, { setSubmitting }: FormikHelpers<Record<string, unknown>>) => {
        updateClass({
          id: classInfo.id,
          ...stripEmptyFields({
            title: values.title,
            synchronousClassLink: values.link,
            schoolId: values.schoolId,
            gradeRange: values.gradeRange,
          }),
        });

        setSubmitting(false);

        (ref as ModalRef).current?.closeModal();
      },
      [classInfo.id, ref, updateClass],
    );
     const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );


    return (
      <ModalContainer ref={ref}>
        <GenericModalForm
          initialValues={{
            title: '',
            link: '',
          }}
          submitButtonType="edit"
          onSubmit={onSubmit}
          onCancel={closeModal}
        >
          <GenericTextInput
            name="title"
            label="Nome da Turma"
            type="text"
            placeholder={classInfo.title}
          />
          <GenericTextInput
            name="link"
            label="Link de Aula Síncrona"
            type="text"
            placeholder={classInfo.synchronousClassLink ?? 'https://meet.google.com/abc-123-xyz'}
          />
          <GenericSelectList
            name="schoolId"
            dataArray={schools}
            optionLabel="name"
            label="Pertence à qual escola"
            placeHolder={school?.name ?? 'Selecione a Escola'}
          />
          <GenericTextInput
            name="gradeRange"
            label="Faixa de Série"
            type="text"
            placeholder={classInfo.gradeRange ?? '1-4' }
        />
          
        </GenericModalForm>
      </ModalContainer>
    );
  },
);

EditClassModal.displayName = 'EditClassModal';

export default EditClassModal;
