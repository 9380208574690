import styled from 'styled-components';
import Color from '../../styles/colors';

export const TableContainer = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  font-family: var(--font-sans);
  font-size: 1.125rem;
`;

export const StudentsTableHead = styled.thead`
  .student-header,
  .parent-header {
    text-align: left;
  }

  .spacer {
    width: 100%;
  }

  .options-header {
    text-align: right;
  }
`;

const dividerColor = new Color('grey').withAlpha(0.2).rgba();

export const TableRowDivider = styled.tr`
  .divider {
    height: 1px;
    width: 100%;
    background-color: ${dividerColor};
  }
`;
