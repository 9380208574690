import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useToast from '../../hooks/useToast';
import { Question } from '../../models/Question';
import useModalRef from '../../hooks/useModalRef';
import { MdRemoveCircleOutline } from 'react-icons/md';
import useQuestions from '../../services/swr/useQuestions';
import StrippedButton from '../../components/StrippedButton';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import { mutate } from 'swr';
import { Tags, TagsContainer } from '../../styles/GenericComponentStyles';

interface ComponentProps {
  questionInfo: Question;
  quizId: string;
}

const QuizQuestionListItem = ({ questionInfo, quizId }: ComponentProps) => {
  const { removeQuestionFromQuiz } = useQuestions();
  const { addToast } = useToast();

  const deletePromptRef = useModalRef();

  const onRemove = async () => {
    try {
      await removeQuestionFromQuiz(questionInfo.id, quizId);
      addToast({
        type: 'success',
        title: 'Questão removida',
        description: 'A questão foi removida do Quiz.',
      });
      setTimeout(() => {
        window.location.reload();
        mutate('/question');
      }, 1000);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover questão',
        description: 'Ocorreu um erro ao remover a questão do Quiz.',
      });
    }
  };

  return (
    <QuizLessonListItemContainer>
      <QuizLessonLink to={`/attachments-details/${questionInfo.id}`}>
        <QuizLessonInfo>
          <div>
            <p className="course-title">{questionInfo.description}</p>
          </div>
          <p className="options">
            <strong>A) </strong>
            {questionInfo.optionA}
          </p>
          <p className="options">
            <strong>B) </strong>
            {questionInfo.description}
          </p>
          <p className="options">
            <strong>C) </strong>
            {questionInfo.optionC}
          </p>
          <p className="options">
            <strong>D) </strong>
            {questionInfo.optionD}
          </p>

          <p className="correct-option">
            <strong>Resposta correta: </strong> Alternativa{' '}
            {questionInfo.target.slice(-1)}
          </p>
          <LabelContainer>
            <TagsContainer>
              {questionInfo.tags &&
                questionInfo.tags.split(',').map(tag => (
                  <Tags tagColor={tag} key={tag}>
                    {tag}
                  </Tags>
                ))}
            </TagsContainer>
          </LabelContainer>
        </QuizLessonInfo>
      </QuizLessonLink>

      <StrippedButton onClick={() => deletePromptRef.current?.openModal()}>
        <MdRemoveCircleOutline className="remove-icon" size={24} />
      </StrippedButton>
      <ConfirmationPrompt
        ref={deletePromptRef}
        promptText={`Tem certeza que deseja remover essa questão do Quiz?`}
        onConfirm={onRemove}
      />
    </QuizLessonListItemContainer>
  );
};

export default QuizQuestionListItem;

export const QuizLessonListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuizLessonLink = styled(Link)`
  text-decoration: none;
`;

export const LabelContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const QuizLessonInfo = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  & strong {
    color: var(--color-dark-blue);
  }

  .course-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0.5rem 0;
  }
  .options {
    margin-top: 0.25rem;
  }
  .correct-option {
    margin-top: 1rem;
  }
`;

export const QuizListContainer = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;
