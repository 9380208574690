import React from 'react';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';

import useToast from '../../hooks/useToast';
import { Question } from '../../models/Question';
import useQuestions from '../../services/swr/useQuestions';
import {
  LabelContainer,
  QuestionInfo,
  QuestionLink,
  QuestionListItemContainer,
} from './QuestionsListItem.styles';
import { Tags, TagsContainer } from '../../styles/GenericComponentStyles';

interface ComponentProps {
  questionInfo: Question;
  index: number;
}

const QuestionListItem = ({ questionInfo, index }: ComponentProps) => {
  const { push } = useHistory();
  const { removeQuestion } = useQuestions();
  const { addToast } = useToast();

  const handleDeleteQuestion = async (id: string) => {
    try {
      await removeQuestion(id);
      addToast({
        type: 'success',
        title: 'Questionário removido',
        description: 'O questionário foi removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover questionário',
        description:
          'Ocorreu um erro ao remover o questionário, tente novamente',
      });
    }
  };

  return (
    <QuestionListItemContainer>
      <QuestionLink to={`/question-details/${questionInfo.id}`}>
        <QuestionInfo>
          <div>
            <p className="course-title">
              {index + 1}) {questionInfo.description}
            </p>
          </div>
          <p className="options">
            <strong>A) </strong>
            {questionInfo.optionA}
          </p>
          <p className="options">
            <strong>B) </strong>
            {questionInfo.description}
          </p>
          <p className="options">
            <strong>C) </strong>
            {questionInfo.optionC}
          </p>
          <p className="options">
            <strong>D) </strong>
            {questionInfo.optionD}
          </p>

          <p className="correct-option">
            <strong>Resposta correta: </strong> Alternativa{' '}
            {questionInfo.target.slice(-1)}
          </p>
          <LabelContainer childrenLength={questionInfo.tags?.length || 0}>
            <TagsContainer>
              {questionInfo.tags &&
                questionInfo.tags.split(',').map(tag => (
                  <Tags tagColor={tag} key={tag}>
                    {tag}
                  </Tags>
                ))}
            </TagsContainer>
          </LabelContainer>
        </QuestionInfo>
      </QuestionLink>

      <MutableListItemOptions
        objectName={questionInfo.description}
        onEdit={() => push(`/question-details/${questionInfo.id}`)}
        onRemove={() => handleDeleteQuestion(questionInfo.id)}
      />
    </QuestionListItemContainer>
  );
};

export default QuestionListItem;
