import styled from 'styled-components';

export const RatingsChartSection = styled.section`
  .chart-title {
    font-family: var(--font-sans);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .chart-container {
    position: relative;
    min-height: 400px;
  }
`;
