import styled from 'styled-components';
import GenericPrimaryButton from '../GenericPrimaryButton';
import StrippedButton from '../StrippedButton';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  list-style: none;
  margin-bottom: 32px;

  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ListItem = styled(StrippedButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  .selection-icon {
    color: var(--color-grey);
    width: 24px;
    height: 24px;
  }
`;

export const ConfirmSelectionButton = styled(GenericPrimaryButton)`
  font-weight: 500;
  align-self: flex-end;
`;
