import React from 'react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import GenericSecondaryButton from '../GenericSecondaryButton';
import { OptionsContainer } from './EditableDeletableOptions.styles';

interface ComponentProps {
  onEdit?: () => void;
  onDelete?: () => void;
}
const EditableDeletableOptions = ({
  onEdit = () => {},
  onDelete = () => {},
}: ComponentProps) => {
  return (
    <OptionsContainer>
      <GenericSecondaryButton className="delete-button" onClick={onDelete}>
        <MdDeleteForever className="button-icon" size={24} />
        <p>Deletar</p>
      </GenericSecondaryButton>
      <GenericSecondaryButton className="edit-button" onClick={onEdit}>
        <MdEdit className="button-icon" size={24} />
        <p>Editar</p>
      </GenericSecondaryButton>
    </OptionsContainer>
  );
};

export default EditableDeletableOptions;
