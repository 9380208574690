import React from 'react';

import { ContentWrapperContainer } from './styles';

interface ContentWrapperComponentProps {
  className?: string;
  children: React.ReactNode;
}

const ContentWrapper = ({
  className,
  children
}: ContentWrapperComponentProps) => {
  return (
    <ContentWrapperContainer className={className}>
      {children}
    </ContentWrapperContainer>
  );
};



export default ContentWrapper;
