import React, { Fragment, useEffect, useState } from 'react';
import GenericDivider from '../../components/GenericDivider';

import styled from 'styled-components';
import SearchableList from '../../components/SearchableList';
import useModalRef from '../../hooks/useModalRef';
import useToast from '../../hooks/useToast';
import useQuiz from '../../services/swr/useQuiz';
import { Quiz } from '../../models/Quiz';
import { useHistory } from 'react-router-dom';
import MutableListItemOptions from '../../components/MutableListItemOptions';
import { Tags, TagsContainer } from '../../styles/GenericComponentStyles';
import AddQuizToClassModal from './AddQuizToClassModal';

interface ClassesQuizProps {
  classId: string;
}

const ClassesQuizList = ({ classId }: ClassesQuizProps) => {
  const { getQuizByClassId, isLoading } = useQuiz();
  const { addToast } = useToast();
  const addQuizToClassModalRef = useModalRef();
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);

  const handleSearch = (search: string, quiz: Quiz) => {
    const treatedQuiz = Object.values(quiz)
      .join(' ')
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

    const match = treatedQuiz.includes(search);

    return match;
  };

  useEffect(() => {
    try {
      getQuizByClassId(classId).then(quizzes => setQuizzes(quizzes));
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao carregar cursos',
        description: `Não foi possível carregar os cursos da turma: ${error}`,
      });
    }
  }, [classId, getQuizByClassId, addToast]);

  return (
    <>
      <SearchableList
        dataArray={quizzes}
        searchMatches={handleSearch}
        onAdd={() => addQuizToClassModalRef.current?.openModal()}
      >
        {({ data }) =>
          !isLoading && quizzes.length === 0 ? (
            <p>Nenhum questionário cadastrado para essa turma</p>
          ) : (
            <ClassCoursesListContainer>
              {data.map((quiz, index) => (
                <Fragment key={quiz.id}>
                  <ClassQuizListItem classId={classId} quizInfo={quiz} />
                  {index < data.length - 1 && <GenericDivider />}
                </Fragment>
              ))}
            </ClassCoursesListContainer>
          )
        }
      </SearchableList>

      <AddQuizToClassModal
        ref={addQuizToClassModalRef}
        classId={classId}
        classQuizzes={quizzes}
      />
    </>
  );
};

export default ClassesQuizList;

interface ComponentProps {
  classId: string;
  quizInfo: Quiz;
}

const ClassQuizListItem = ({ classId, quizInfo }: ComponentProps) => {
  const { removeQuizFromClass } = useQuiz();
  const { push } = useHistory();
  const { addToast } = useToast();

  const handleRemoveQuizFromClass = async () => {
    try {
      await removeQuizFromClass(classId, quizInfo.id);
      addToast({
        type: 'success',
        title: 'Questionário removido da turma',
        description: `Questionário ${quizInfo.title} removido da turma com sucesso`,
      });
      setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover questionário da turma',
        description: `Não foi possível remover o questionário da turma: ${error}`,
      });
    }
  };

  return (
    <ListItemContainer>
      <CourseInfo>
        <p className="course-title">{quizInfo.title}</p>
        <p className="course-description">{quizInfo.description}</p>
        <LabelContainer>
          <TagsContainer>
            {quizInfo.tags &&
              quizInfo.tags.split(',').map(tag => (
                <Tags tagColor={tag} key={tag}>
                  {tag}
                </Tags>
              ))}
          </TagsContainer>
        </LabelContainer>
      </CourseInfo>

      <MutableListItemOptions
        objectName={quizInfo.title}
        onEdit={() => push(`/quiz-details/${quizInfo.id}`)}
        onRemove={() => handleRemoveQuizFromClass()}
      />
    </ListItemContainer>
  );
};

const ClassCoursesListContainer = styled.ul`
  list-style: none;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const ListItemContainer = styled.li`
  display: flex;
  align-items: center;
`;

export const LabelContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const CourseInfo = styled.article`
  margin-right: 72px;
  width: 100%;

  .course-title,
  .course-description {
    font-family: var(--font-sans);
  }

  .course-title {
    font-size: 1.25rem;
    font-weight: 500;
  }
`;
