import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';
import SearchableListControls from '../../components/SearchableListControls';

export const ModalContainer = styled(GenericModal)`
  width: 80%;
  max-width: 720px;
`;

export const TeachersListControls = styled(SearchableListControls)`
  margin: 24px 0 24px;
`;

export const ListItemContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .teacher-name,
  .teacher-email {
    font-family: var(--font-sans);
    color: var(--color-black);
  }

  .teacher-name {
    font-weight: 500;
  }
`;
