import React, { useCallback, useRef } from 'react';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import { ModalRef } from '../../hooks/useModalRef';
import { FormikHelpers } from 'formik';
import { ModalContainer } from './CreateAttachmentModal.styles';
import useAttachments from '../../services/swr/useAttachments';
import GenericFileInput from '../../components/GenericFileInput';

const CreateAttachmentModal = React.forwardRef((_, ref) => {
  const { addAttachment } = useAttachments();
  const attachmentFile = useRef<File | null>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback(
    (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>) => {
      if (!attachmentFile.current) return;
      const { title } = values;
      addAttachment(title as string, attachmentFile.current as File);

      if (attachmentInputRef.current) attachmentInputRef.current.value = '';
      
      setSubmitting(false);
      resetForm();

      (ref as ModalRef).current?.closeModal();
    },
    [addAttachment, ref],
  );

  return (
    <ModalContainer ref={ref}>
      <GenericModalForm
        initialValues={{
          title: '',
        }}
        submitButtonType="create"
        onSubmit={onSubmit}
        onCancel={() => (ref as ModalRef).current?.closeModal()}
      >
        <GenericTextInput
          name="title"
          label="Título do Anexo"
          type="text"
          placeholder="Condicionais em javascript"
        />
        <GenericFileInput
          ref={attachmentInputRef}
          name="file"
          label="Documento"
          setFile={(file: File) => { attachmentFile.current = file }}
        />
      </GenericModalForm>
    </ModalContainer>
  );
});

CreateAttachmentModal.displayName = 'CreateAttachmentModal';

export default CreateAttachmentModal;
