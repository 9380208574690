import React from 'react';
import { ToolTipContainer, ToolTipIcon, ToolTipText } from './GenericToolTip.styles';
import { Tooltip } from 'react-tooltip'


interface ComponentProps {
  text?: string;
}

const GenericToolTip = ({ text }: ComponentProps) => {
  const handleMouseOver = () => {
    const toolTipText = document.getElementById('toolTipText');
    if (toolTipText) {
      toolTipText.style.visibility = 'visible';
      toolTipText.style.opacity = '1';
    }
  };

  const handleMouseOut = () => {
    const toolTipText = document.getElementById('toolTipText');
    if (toolTipText) {
      toolTipText.style.visibility = 'hidden';
      toolTipText.style.opacity = '0';
    }
  };

  return (
    <ToolTipContainer>
      <ToolTipIcon
        data-tip
        data-for="toolTipText"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {text ? '?' : ''}
      </ToolTipIcon>
      <ToolTipText id="toolTipText">{text}</ToolTipText>
      <Tooltip id="toolTipText" ></Tooltip>
    </ToolTipContainer>
  );
};

export default GenericToolTip;