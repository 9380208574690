import React from 'react';
import { MdLogout } from 'react-icons/md';
import useAuth from '../../../../hooks/useAuth';

import { LogoutButton } from './styles';

const UserMenu  = () => {
  const { signOut } = useAuth();

  return (
    <div>
      <LogoutButton type="button" onClick={signOut}>
        Sair
        <MdLogout size={24} className="logout-icon" />
      </LogoutButton>
    </div>
  );
};

export default UserMenu;
