import styled from 'styled-components';
import { ContentWithFooter } from '../../components/Footer';
import SectionHeader from '../../components/SectionHeader';

export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const PageHeader = styled(SectionHeader)`
  margin-bottom: 32px;

  .page-title {
    font-size: 1.953rem;
  }
`;

export const TeachersList = styled.ul`
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
`;
