import styled from 'styled-components';

export const LogoPaneContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 32px;
  margin-bottom: 48px;
  background-color: var(--color-dark-green);

  @media screen and (min-width: 768px) {
    width: 50vw;
    height: 100vh;
    margin-bottom: 0;
  }

  .logo {
    max-width: 464px;
    width: 50%;

    @media screen and (min-width: 768px) {
      width: 80%;
    }
  }

  .platform {
    font-family: var(--font-display);
    font-size: 1.963rem;
    font-weight: 700;
    text-align: center;

    margin-top: 16px;
  }
`;
