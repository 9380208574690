import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import CredentialsForm from '../../../../components/CredentialsForm';
import CredentialsFormInput from '../../../../components/CredentialsFormInput';
import useAuth from '../../../../hooks/useAuth';
import { SignInFormContainer } from './styles';

const SignInForm = () => {
  const { signIn } = useAuth();
  const { push } = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = useCallback(async () => {
    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .required('e-Mail obrigatório')
        .email('Digite um e-Mail válido'),
      password: Yup.string()
        .required('Senha obrigatória')
        .min(6, 'Senha deve conter ao menos 6 caracteres'),
    });

    try {
      await validationSchema.validate(
        { email, password },
        { abortEarly: false },
      );

      await signIn({ email, password });
      push('/dashboard');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      let errorMessage = 'Erro ao fazer login:\n\n';

      for (const err of error.errors) {
        errorMessage += `${err}\n`;
      }
      // eslint-disable-next-line no-alert
      window.alert(errorMessage);
    }
  }, [email, password, signIn, push]);

  return (
    <SignInFormContainer>
      <h1 className="title">Login</h1>
      <p className="title-message">Autentique-se e entre na plataforma</p>
      <CredentialsForm submitButtonLabel="Entrar" onSubmit={handleSignIn}>
        <CredentialsFormInput
          label="e-Mail"
          input={{
            id: 'email-input',
            type: 'email',
            name: 'email-input',
            placeholder: 'email@exemplo.com.br',
            onChange: e => setEmail(e.target.value),
          }}
        />
        <CredentialsFormInput
          label="Senha"
          input={{
            id: 'password-input',
            type: 'password',
            name: 'password-input',
            placeholder: 'correct-horse-battery-staple',
            onChange: e => setPassword(e.target.value),
          }}
        />
      </CredentialsForm>
    </SignInFormContainer>
  );
};

export default SignInForm;
