import React, { useCallback, useEffect, useState } from 'react';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import GenericSecondaryButton from '../../components/GenericSecondaryButton';
import useModalRef from '../../hooks/useModalRef';
import useSchools from '../../services/swr/useSchools';
import { ContentWithFooter } from '../../components/Footer';
import styled from 'styled-components';
import { TabNavigator } from '../../components/TabNavigator';
import School from '../../models/School';
import Class from '../../models/Class';
import Student from '../../models/Student';

interface ComponentProps {
  schoolInfo: School;
}

export const SchoolDetailsPageHeader = ({ schoolInfo }: ComponentProps) => {
  const deleteClassPromptRef = useModalRef();
  const editClassModalRef = useModalRef();
  const { schoolDetails, removeSchool } = useSchools();
  const { push } = useHistory();
  const [schoolClasses, setSchoolClasses] = useState<Class[]>([]);
  const [schoolStudents, setSchoolStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState(true);

  const handleDeleteSchool = useCallback(() => {
    removeSchool(schoolInfo.id);
    push('/school-management');
  }, [schoolInfo.id, push, removeSchool]);

  useEffect(() => {
    schoolDetails(schoolInfo.id).then(({ classes, students }) => {
      setSchoolClasses(classes);
      setSchoolStudents(students);
      setLoading(false);
    });
  }, [schoolDetails, schoolInfo.id]);

  return (
    <PageHeaderContainer>
      <ClassInformation>
        <h1 className="class-name">{schoolInfo.label}</h1>
        <div className="class-stats">
          <p>{!loading ? schoolClasses.length : '---'} turmas</p>
          <p>{!loading ? schoolStudents.length : '---'} alunos</p>
        </div>
      </ClassInformation>

      <ClassOptions>
        <GenericSecondaryButton
          className="delete-button"
          onClick={() => deleteClassPromptRef.current?.openModal()}
        >
          <MdDeleteForever className="button-icon" size={24} />
          <p>Deletar</p>
        </GenericSecondaryButton>
        <GenericSecondaryButton
          className="edit-button"
          onClick={() => editClassModalRef.current?.openModal()}
        >
          <MdEdit className="button-icon" size={24} />
          <p>Editar</p>
        </GenericSecondaryButton>
      </ClassOptions>

      <ConfirmationPrompt
        ref={deleteClassPromptRef}
        promptText={`Tem certeza que quer deletar "${schoolInfo.label}" permanentemente?`}
        onConfirm={handleDeleteSchool}
      />

      {/* <EditClassModal ref={editClassModalRef} schoolInfo={schoolInfo} /> */}
    </PageHeaderContainer>
  );
};
  
export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const ClassDetailsTabNavigator = styled(TabNavigator)`
  margin-top: 64px;
`;


export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
`;

export const ClassOptions = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }

  .button-icon {
    min-width: 24px;
  }

  .delete-button,
  .edit-button {
    font-family: var(--font-sans);
    font-size: 1rem;
    font-weight: 500;

    height: fit-content;
    width: 100%;
    margin-bottom: 8px;

    @media screen and (min-width: 800px) {
      margin-bottom: 0;
    }
  }

  .delete-button {
    margin-right: 8px;
    color: var(--color-red);
  }

  .edit-button {
    color: var(--color-black);
  }
`;

export const ClassInformation = styled.div`
  margin-right: 24px;
  margin-bottom: 24px;

  font-family: var(--font-sans);
  color: var(--color-black);

  @media screen and (min-width: 480px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 800px) {
    margin-right: 0;
  }

  .class-name {
    font-size: 1.953rem;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .class-link {
    color: var(--color-black);
    font-size: 1rem;
  }

  .class-stats {
    display: flex;
    font-size: 1.25rem;

    margin-bottom: 16px;

    & > :not(:last-child) {
      margin-right: 16px;
    }
  }
`;
