import styled from 'styled-components';
import Color from '../../styles/colors';

const dividerColor = new Color('grey').withAlpha(0.15).rgba();

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${dividerColor};
`;
