import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import EditableDeletableOptions from '../../components/EditableDeletableOptions';
import NavigationBar from '../../components/NavigationBar';
import { Tab } from '../../components/TabNavigator';
import UserProfileDetails from '../../components/UserProfileDetails';
import useModalRef from '../../hooks/useModalRef';
import Student from '../../models/Student';
import StudentDetailsPageUrlParams from '../../routes/location/StudentDetailsPageUrlParams';
import useStudents from '../../services/swr/useStudents';
import NotFoundPage from '../404';
import EditStudentModal from './EditStudentModal';
import {
  PageContent,
  PageHeader,
  StudentDetailsTabNavigator,
} from './StudentDetailsPage.styles';
import StudentMetricsTab from './StudentMetricsTab';

const StudentDetailsPage = () => {
  const { push } = useHistory();
  const { studentId } = useParams<StudentDetailsPageUrlParams>();

  const { getStudent, deleteStudent, isLoading } = useStudents();
  const studentInfo = getStudent(studentId) as Student;

  const editStudentModalRef = useModalRef();
  const deleteStudentPrompRef = useModalRef();

  const handleDeleteStudent = () => {
    deleteStudent(studentInfo.id);
    push('/students-management');
  };

  if (!studentInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />
      <PageContent>
        {studentInfo && (
          <>
            <PageHeader>
              <UserProfileDetails userProfile={studentInfo.profile} />

              <EditableDeletableOptions
                onEdit={() => editStudentModalRef.current?.openModal()}
                onDelete={() => deleteStudentPrompRef.current?.openModal()}
              />
            </PageHeader>
            <StudentDetailsTabNavigator>
              <Tab label={'Responsável'}>
                <p style={{ marginTop: '16px' }}>{'<<feature>>'}</p>
              </Tab>
              <Tab label={'Assinaturas'}>
                <p style={{ marginTop: '16px' }}>{'<<feature>>'}</p>
              </Tab>
              <Tab label={'Métricas'}>
                <StudentMetricsTab studentId={studentId} />
              </Tab>
            </StudentDetailsTabNavigator>

            <ConfirmationPrompt
              ref={deleteStudentPrompRef}
              promptText={`Tem certeza que quer deletar "${studentInfo.profile.name}" permanentemente?`}
              onConfirm={handleDeleteStudent}
            />
            <EditStudentModal
              ref={editStudentModalRef}
              studentInfo={studentInfo}
            />
          </>
        )}
      </PageContent>
    </>
  );
};

export default StudentDetailsPage;
