import React, { useCallback, useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import NavigationBar from '../../components/NavigationBar';
import Footer from '../../components/Footer';
import SectionHeader from '../../components/SectionHeader';
import Class from '../../models/Class';
import Course from '../../models/Course';
import api from '../../services/api';

import {
  AssociateButton,
  AssociationContainer,
  EntityListsContainer,
  Entity,
  EntityList,
  PageContent,
} from './styles';

const ClassCourseAssociationPage = () => {
  const [existingClasses, setExistingClasses] = useState<Class[]>([]);
  const [existingCourses, setExistingCourses] = useState<Course[]>([]);

  const [toBeAssociatedClasses, setToBeAssociatedClasses] = useState<Class[]>(
    [],
  );
  const [toBeAssociatedCourses, setToBeAssociatedCourses] = useState<Course[]>(
    [],
  );

  const handleClassAdditionToAssociationList = useCallback(
    (classId: string) => {
      const selectedClass = existingClasses.find(
        classItem => classItem.id === classId,
      ) as Class;
      setToBeAssociatedClasses([...toBeAssociatedClasses, selectedClass]);

      const existingClassesWithoutSelectedClass = existingClasses.filter(
        classItem => classItem.id !== classId,
      );
      setExistingClasses(existingClassesWithoutSelectedClass);
    },
    [existingClasses, toBeAssociatedClasses],
  );

  const handleClassRemovalFromAssociationList = useCallback(
    (classId: string) => {
      const selectedClass = toBeAssociatedClasses.find(
        classItem => classItem.id === classId,
      ) as Class;
      setExistingClasses([...existingClasses, selectedClass]);

      const toBeAssociatedClassesWithoutSelectedClass =
        toBeAssociatedClasses.filter(classItem => classItem.id !== classId);
      setToBeAssociatedClasses(toBeAssociatedClassesWithoutSelectedClass);
    },
    [existingClasses, toBeAssociatedClasses],
  );

  const handleCourseAdditionToAssociationList = useCallback(
    (courseId: string) => {
      const selectedCourse = existingCourses.find(
        courseItem => courseItem.id === courseId,
      ) as Course;
      setToBeAssociatedCourses([...toBeAssociatedCourses, selectedCourse]);

      const existingCoursesWithoutSelectedCourse = existingCourses.filter(
        courseItem => courseItem.id !== courseId,
      );
      setExistingCourses(existingCoursesWithoutSelectedCourse);
    },
    [existingCourses, toBeAssociatedCourses],
  );

  const handleCourseRemovalFromAssociationList = useCallback(
    (courseId: string) => {
      const selectedCourse = toBeAssociatedCourses.find(
        courseItem => courseItem.id === courseId,
      ) as Course;
      setExistingCourses([...existingCourses, selectedCourse]);

      const toBeAssociatedCoursesWithoutSelectedCourse =
        toBeAssociatedCourses.filter(courseItem => courseItem.id !== courseId);
      setToBeAssociatedCourses(toBeAssociatedCoursesWithoutSelectedCourse);
    },
    [existingCourses, toBeAssociatedCourses],
  );

  const handleAssociateClassesAndCourses = useCallback(() => {
    if (toBeAssociatedClasses.length === 0) {
      // eslint-disable-next-line no-alert
      alert('Nenhuma turma foi selecionada');
      return;
    }

    if (toBeAssociatedCourses.length === 0) {
      // eslint-disable-next-line no-alert
      alert('Nenhum curso foi selecionado');
      return;
    }

    api.post('/classes/add-courses', {
      classesIds: toBeAssociatedClasses.map(classItem => classItem.id),
      coursesIds: toBeAssociatedCourses.map(courseItem => courseItem.id),
    });
  }, [toBeAssociatedClasses, toBeAssociatedCourses]);

  useEffect(() => {
    api
      .get('/classes/index')
      .then(response => setExistingClasses(response.data));

    api
      .get('/courses/index')
      .then(response => setExistingCourses(response.data));
  }, []);

  return (
    <>
      <NavigationBar />

      <PageContent>
        <SectionHeader>
          <h1>Associar Turmas e Cursos</h1>
        </SectionHeader>

        <AssociationContainer>
          <EntityListsContainer>
            <div>
              <h2 className="title">Turmas Existentes</h2>
              <EntityList>
                {existingClasses.map(classItem => (
                  <Entity
                    key={classItem.id}
                    onClick={() =>
                      handleClassAdditionToAssociationList(classItem.id)
                    }
                  >
                    <p className="entity-name">{classItem.title}</p>
                    <MdAdd />
                  </Entity>
                ))}
              </EntityList>
            </div>

            <div>
              <h2 className="title">Cursos Existentes</h2>
              <EntityList>
                {existingCourses.map(courseItem => (
                  <Entity
                    key={courseItem.id}
                    onClick={() =>
                      handleCourseAdditionToAssociationList(courseItem.id)
                    }
                  >
                    <p className="entity-name">{courseItem.title}</p>
                    <MdAdd />
                  </Entity>
                ))}
              </EntityList>
            </div>

            <div>
              <h2 className="title">Turmas a Associar</h2>
              <EntityList>
                {toBeAssociatedClasses.map(classItem => (
                  <Entity
                    key={classItem.id}
                    onClick={() =>
                      handleClassRemovalFromAssociationList(classItem.id)
                    }
                  >
                    <p className="entity-name">{classItem.title}</p>
                    <MdRemove />
                  </Entity>
                ))}
              </EntityList>
            </div>

            <div>
              <h2 className="title">Cursos a Associar</h2>
              <EntityList>
                {toBeAssociatedCourses.map(courseItem => (
                  <Entity
                    key={courseItem.id}
                    onClick={() =>
                      handleCourseRemovalFromAssociationList(courseItem.id)
                    }
                  >
                    <p className="entity-name">{courseItem.title}</p>
                    <MdRemove />
                  </Entity>
                ))}
              </EntityList>
            </div>
          </EntityListsContainer>

          <AssociateButton onClick={handleAssociateClassesAndCourses}>
            Associar
          </AssociateButton>
        </AssociationContainer>
      </PageContent>
      <Footer />
    </>
  );
};

export default ClassCourseAssociationPage;
