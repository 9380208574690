import styled from 'styled-components';
import { ContentWithFooter } from '../../components/Footer';
import SearchableListControls from '../../components/SearchableListControls';
import SectionHeader from '../../components/SectionHeader';
import Color from '../../styles/colors';

export const PageHeader = styled(SectionHeader)`
  .page-title {
    font-size: 1.953rem;
  }
`;

export const PageContent = styled(ContentWithFooter)`
  margin-top: 48px;
`;

export const QuestionListControls = styled(SearchableListControls)`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const dividerColor = new Color('grey').withAlpha(0.15).rgba();

export const QuestionList = styled.ul`
  list-style-type: none;

  & > :not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;

    border-bottom: 1px solid ${dividerColor};
  }
`;
