import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { convertUrlToEmbedUrl } from '../../utils/youtube';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import GenericTextArea from '../../components/GenericTextArea';
import useCourseLessons from '../../services/swr/useCourseLessons';
import { CheckboxLabel, ModalContainer } from './EditLessonModal.styles';
import Lesson from '../../models/Lesson';
import { ModalRef } from '../../hooks/useModalRef';
import { FormikHelpers } from 'formik';

interface ComponentProps {
  courseId: string;
  lessonInfo: Lesson;
}

const EditLessonModal = React.forwardRef(
  ({ courseId, lessonInfo }: ComponentProps, ref) => {
    const { updateLesson } = useCourseLessons(courseId);

    const [isLessonVisible, setIsLessonVisible] = useState(
      lessonInfo.isVisible,
    );

    const handleSubmit = useCallback(
      (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>) => {
        updateLesson({
          id: lessonInfo.id,
          title: values['lesson-name'] as string,
          description: values['lesson-description'] as string,
          videoUri: convertUrlToEmbedUrl(values['lesson-video-url'] as string),
          balance: values['balance'] as number,
          experiencePoints: values['experiencePoints'] as number,
          assignmentUri: values['lesson-assignment-url'] as string || null,
          isVisible: isLessonVisible,
        });

        setSubmitting(false);
        resetForm();

        (ref as ModalRef).current?.closeModal();
         window.location.reload();
      },
      [isLessonVisible, lessonInfo.id, ref, updateLesson],
    );

    return (
      <ModalContainer ref={ref}>
        <GenericModalForm
          initialValues={{
            'lesson-name': lessonInfo.title,
            'lesson-description': lessonInfo.description,
            'lesson-video-url': lessonInfo.videoUri,
            'lesson-assignment-url': lessonInfo.assignmentUri ?? '',
            'balance': lessonInfo.balance ?? 0,
            'experiencePoints': lessonInfo.experiencePoints ?? 0,
          }}
          validationSchema={Yup.object().shape({
            'lesson-name': Yup.string().required('Nome da aula é obrigatório'),
            'lesson-description': Yup.string().required(
              'Descrição da aula é obrigatória',
            ),
            'lesson-video-url': Yup.string().required(
              'URL do vídeo é obrigatória',
            ),
            'lesson-assignment-url': Yup.string().nullable(),
            'balance': Yup.number().default(0),
            'experiencePoints': Yup.number().default(0),
          })}
          submitButtonType="edit"
          onSubmit={handleSubmit}
          onCancel={() => (ref as ModalRef).current?.closeModal()}
        >
          <GenericTextInput
            name="lesson-name"
            label="Título da Aula"
            type="text"
            placeholder={lessonInfo.title}
          />

          <GenericTextArea
            name="lesson-description"
            label="Descrição da Aula"
            placeholder={lessonInfo.description}
          />

          <GenericTextInput
            name="lesson-video-url"
            label="URL do Vídeo"
            type="text"
            placeholder={lessonInfo.videoUri}
          />

          <GenericTextInput
            name="lesson-assignment-url"
            label="URL da Atividade"
            type="text"
            placeholder={lessonInfo.assignmentUri || 'https://www.code.org'}
          />

          <GenericTextInput
            name="balance"
            label="Quantidade de Moedas"
            type="number"
            placeholder={lessonInfo.balance?.toString() || '0'}
          />
           <GenericTextInput
            name="experiencePoints"
            type="number"
            label="Quantidade de Pontos de Experiência"
            placeholder={lessonInfo.experiencePoints?.toString() || '0'}
          />

          {/* Formik is still inconsistent with checkbox inputs, therefore this
              input should be manualy controlled by the application until a fix
              is submitted */}
          <CheckboxLabel>
            <input
              id="is-visible-ckb"
              className="generic-checkbox"
              type="checkbox"
              name="is-visible"
              checked={isLessonVisible}
              onChange={() => setIsLessonVisible(prevVal => !prevVal)}
            />
            Aula visível para alunos
          </CheckboxLabel>
        </GenericModalForm>
      </ModalContainer>
    );
  },
);

EditLessonModal.displayName = 'EditLessonModal';

export default EditLessonModal;
