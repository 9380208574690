import React from 'react';

import { LogoPaneContainer } from './styles';

const LogoPane = () => {
  return (
    <LogoPaneContainer>
      <img
        src="/assets/itus-logo-text.svg"
        alt="Escola Itus"
        className="logo"
      />
      <p className="platform">Plataforma do Administrador</p>
    </LogoPaneContainer>
  );
};

export default LogoPane;
