import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import Parent from '../../models/Parent';
import api from '../api';
import { fetcher } from './swr';
import useToast from '../../hooks/useToast';
import { UpdateProfileDTO } from '../../models/Profile';

export default function useParents() {
  const { addToast } = useToast();

  const { data, error } = useSWR<Parent[]>('/parents/index', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 24 * 7,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { mutate } = useSWRConfig();

  const getParent = useCallback(
    (parentId: string) => {
      if (!data) return;

      const parent = data.find(parent => parent.id === parentId);
      return parent;
    },
    [data],
  );

  const addParent = useCallback(
    async (name: string, email: string, password: string) => {
      try {
        await api.post('/parents/register', { name, email, password });

        addToast({
          type: 'success',
          title: 'Responsável cadastrado com sucesso',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar novo responsável',
        });
      } finally {
        mutate('/parents/index');
      }
    },
    [addToast, mutate],
  );

  const updateParentProfile = useCallback(
    async (parentProfile: UpdateProfileDTO) => {
      if (data) {
        const { id: profileId, password, ...parentProfileInfo } = parentProfile;

        const newData = data.map(parent => {
          if (parent.profile.id === profileId) {
            return {
              ...parent,
              profile: Object.assign(parent.profile, parentProfileInfo),
            };
          }

          return parent;
        });

        mutate('/parents/index', newData, false);

        try {
          await api.put(`/users/update/${profileId}`, {
            ...parentProfileInfo,
            ...(password && {
              password,
              passwordConfirmation: password,
            }),
          });

          addToast({
            type: 'success',
            title: 'Responsável editado com sucesso',
          });
        } catch (err) {
          addToast({ type: 'error', title: 'Erro ao editar responsável' });
        } finally {
          mutate('/parents/index');
        }
      }
    },
    [addToast, data, mutate],
  );

  const deleteParent = useCallback(
    (parentId: string) => {
      if (data)
        mutate(
          '/parents/index',
          data.filter(p => p.id !== parentId),
        );

      api
        .delete(`/parents/delete/${parentId}`)
        .then(() =>
          addToast({
            type: 'success',
            title: 'Responsável deletado com sucesso',
          }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao deletar responsável' }),
        )
        .finally(() => mutate('/parents/index'));
    },
    [addToast, data, mutate],
  );

  return {
    parents: data || [],
    error,
    isLoading: !data && !error,
    getParent,
    addParent,
    updateParentProfile,
    deleteParent,
  };
}
