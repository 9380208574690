import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const QuestionListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LabelContainer = styled.div<{ childrenLength: number }>`
  margin-top: ${({ childrenLength }) => (childrenLength > 1 ? '2rem' : '0')};
  display: flex;
  align-items: center;
  justify-content: start;
`;
export const QuestionLink = styled(Link)`
  text-decoration: none;
`;

export const QuestionInfo = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  & strong {
    color: var(--color-dark-blue);
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-bottom: 12px;
  }

  .course-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0.5rem 0;
  }
  .options {
    margin-top: 0.25rem;
  }
  .correct-option {
    margin-top: 1rem;
  }
`;
