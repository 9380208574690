import styled from 'styled-components';
import Color from '../../styles/colors';

const dividerColor = new Color('grey').withAlpha(0.2).rgba();

export const SectionHeaderContainer = styled.div<{ color?: string }>`
  padding: 0 0 16px 0;
  border-bottom: 1px solid ${dividerColor};

  .title {
    font-family: var(--font-sans);
    font-size: 1.563rem;
    font-weight: 500;
    color: var(--color-black);
  }
`;
