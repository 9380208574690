import React, { Fragment } from 'react';
import Student from '../../models/Student';
import {
  StudentsTableHead,
  TableContainer,
  Table,
  TableRowDivider,
} from './StudentsTable.styles';
import StudentsTableRow from './StudentsTableRow';

interface ComponentProps {
  students: Student[];
  onEditStudent: (student: Student) => void;
  onRemoveStudent: (student: Student) => void;
}

const StudentsTable = ({
  students,
  onEditStudent,
  onRemoveStudent
}: ComponentProps) => {
  return (
    <TableContainer>
      <Table>
        <StudentsTableHead>
          <tr>
            <th className="student-header">Aluno</th>
            <th className="parent-header">Responsável</th>
            <th className="spacer" />
            <th className="options-header">Opções</th>
          </tr>
        </StudentsTableHead>
        <tbody>
          {students.map((student, index) => (
            <Fragment key={student.id}>
              <StudentsTableRow
                studentInfo={student}
                onEditStudent={onEditStudent}
                onRemoveStudent={onRemoveStudent}
              />
              {index < students.length - 1 && (
                <TableRowDivider>
                  <td className="divider" colSpan={4} />
                </TableRowDivider>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default StudentsTable;
