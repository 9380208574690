import styled from 'styled-components';
import Modal from '../../components/GenericModal';

export const ModalContainer = styled(Modal)`
  width: 80%;
  max-width: 480px;
`;

export const ModalContent = styled.div`
  .modal-title {
    font-family: var(--font-sans);
    font-size: 1.563rem;

    margin-bottom: 24px;
  }
`;
