import React, { useCallback, MouseEvent } from 'react';

import {
  CredentialsFormContainer,
  ForgotPasswordLink,
  SubmitButton,
} from './styles';

interface CredentialsFormComponentProps {
  enableForgotPassword?: boolean;
  submitButtonLabel: string;
  onSubmit: () => void;
  children: React.ReactNode;
}

const CredentialsForm = ({
  enableForgotPassword = false,
  submitButtonLabel,
  onSubmit,
  children,
}: CredentialsFormComponentProps) => {
  const handleSubmit = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  return (
    <CredentialsFormContainer>
      <div>{children}</div>

      {enableForgotPassword && (
        <ForgotPasswordLink href="#index">
          Esqueci minha senha
        </ForgotPasswordLink>
      )}

      <SubmitButton className="credential-form-button" onClick={handleSubmit}>
        {submitButtonLabel}
      </SubmitButton>
    </CredentialsFormContainer>
  );
};



export default CredentialsForm;
