import React, { Fragment } from 'react';
import GenericDivider from '../../components/GenericDivider';
import SearchableList from '../../components/SearchableList';
import useModalRef from '../../hooks/useModalRef';
import Teacher from '../../models/Teacher';
import useCourseTeachers from '../../services/swr/useCourseTeachers';
import AddTeachersToCourseModal from './AddTeachersToCourseModal';
import { TeachersListContainer } from './CourseTeachersList.styles';
import CourseTeachersListItem from './CourseTeachersListItem';

interface ComponentProps {
  courseId: string;
}

const CourseLessonsList = ({ courseId }: ComponentProps) => {
  const { teachers } = useCourseTeachers(courseId);

  const addTeachersModalRef = useModalRef();

  const handleSearch = (search: string, teacher: Teacher) => {
    const treatedTeacherName = teacher.profile.name?.toLowerCase();
    const treatedTeacherEmail = teacher.profile.email.toLowerCase();

    const match =
      (treatedTeacherName && treatedTeacherName.includes(search)) ||
      treatedTeacherEmail.includes(search);

    return match;
  };

  return (
    <>
      <SearchableList
        dataArray={teachers}
        searchMatches={handleSearch}
        onAdd={() => addTeachersModalRef.current?.openModal()}
      >
        {({ data }) => (
          <>
            {data.length > 0 ? (
              <TeachersListContainer>
                {data.map((teacher, index) => (
                  <Fragment key={teacher.id}>
                    <CourseTeachersListItem
                      teacherInfo={teacher}
                      courseId={courseId}
                    />
                    {index < data.length - 1 && <GenericDivider />}
                  </Fragment>
                ))}
              </TeachersListContainer>
            ) : (
              <p>Nenhum professor encontrado</p>
            )}
          </>
        )}
      </SearchableList>

      <AddTeachersToCourseModal ref={addTeachersModalRef} courseId={courseId} />
    </>
  );
};

export default CourseLessonsList;
