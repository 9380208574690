import styled from 'styled-components';

export const ListItemContainer = styled.li`
  display: flex;
  align-items: center;
`;

export const CourseInfo = styled.article`
  margin-right: 72px;

  .course-title,
  .course-description {
    font-family: var(--font-sans);
  }

  .course-title {
    font-size: 1.25rem;
    font-weight: 500;
  }
`;
