import styled from 'styled-components';

const MenuContainer = styled.div<{ shouldShow: boolean }>`
  position: absolute;

  padding: 24px;
  border-radius: 8px;
  background-color: var(--color-light-green);

  transform: scale(${({ shouldShow }) => (shouldShow ? 1 : 0)});
  transition: transform 0.1s ease-in;
`;

export default MenuContainer;
