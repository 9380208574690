import React, { useCallback, useState } from 'react';
import NavigationBar from '../../components/NavigationBar';
import useFilter from '../../hooks/useFilter';

import QuestionListItem from './QuestionListItem';
import { useHistory } from 'react-router-dom';
import useQuestions from '../../services/swr/useQuestions';
import { Question } from '../../models/Question';
import {
  PageContent,
  PageHeader,
  QuestionList,
  QuestionListControls,
} from './QuestionsManagementPage.styles';

export const QuestionsManagementPage = () => {
  const { questions } = useQuestions();
  const { push } = useHistory();
  const {
    filter,
    clearFilter,
    filteredData: filteredQuestions,
  } = useFilter<Question>(questions);
  const [isUsingSearch, setIsUsingSearch] = useState(false);

  const onSearch = useCallback(
    (search: string) => {
      const treatedSearch = search.toLowerCase().trim();
      filter(question => {
        const treatedQuestionInfo = Object.values(question)
          .join(' ')
          .toLowerCase();
        return treatedQuestionInfo.includes(treatedSearch);
      });

      setIsUsingSearch(true);
    },
    [filter],
  );

  const questionList = isUsingSearch
    ? filteredQuestions
    : [...questions].reverse();
  return (
    <>
      <NavigationBar />
      <PageContent>
        <PageHeader>
          <h1 className="page-title">Banco de Questões</h1>
        </PageHeader>

        <QuestionListControls
          onSearch={onSearch}
          onClearSearch={clearFilter}
          onAdd={() => push('/create-questions')}
        />

        <QuestionList>
          <p>Total de questões: {questionList.length}</p>
          {questionList.map((question: Question, index: number) => (
            <QuestionListItem
              key={question.id}
              questionInfo={question}
              index={index}
            />
          ))}
        </QuestionList>
      </PageContent>
    </>
  );
};
