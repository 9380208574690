import React, { Fragment, useEffect } from 'react';
import GenericDivider from '../../components/GenericDivider';
import SearchableList from '../../components/SearchableList';
import useModalRef from '../../hooks/useModalRef';
import useToast from '../../hooks/useToast';
import Course from '../../models/Course';
import useClassCourses from '../../services/swr/useClassCourses';
import AddCoursesToClassModal from './AddCoursesToClassModal';
import { ClassCoursesListContainer } from './ClassCoursesList.styles';
import ClassCoursesListItem from './ClassCoursesListItem';

interface ComponentProps {
  classId: string;
}

const ClassCoursesList = ({ classId }: ComponentProps) => {
  const { courses, isLoading, error } = useClassCourses(classId);
  const { addToast } = useToast();
  const addCourseModalRef = useModalRef();

  const handleSearch = (search: string, course: Course) => {
    const treatedCourseName = course.title?.toLowerCase();

    const match =
      (treatedCourseName && treatedCourseName.includes(search)) ||
      course.description.includes(search);

    return match;
  };

  useEffect(() => {
    if (error)
      addToast({
        type: 'error',
        title: 'Erro ao carregar cursos',
        description: `Não foi possível carregar os cursos da turma: ${error}`,
      });
  }, [addToast, error]);

  return (
    <>
      <SearchableList
        dataArray={courses}
        searchMatches={handleSearch}
        onAdd={() => addCourseModalRef.current?.openModal()}
      >
        {({ data }) =>
          !isLoading && courses.length === 0 ? (
            <p>Nenhum curso cadastrado para essa turma</p>
          ) : (
            <ClassCoursesListContainer>
              {data.map((course, index) => (
                <Fragment key={course.id}>
                  <ClassCoursesListItem classId={classId} courseInfo={course} />
                  {index < data.length - 1 && <GenericDivider />}
                </Fragment>
              ))}
            </ClassCoursesListContainer>
          )
        }
      </SearchableList>

      <AddCoursesToClassModal ref={addCourseModalRef} classId={classId} />
    </>
  );
};

export default ClassCoursesList;
