import React from 'react';

import NavigationBar from '../../components/NavigationBar';
import SectionHeader from '../../components/SectionHeader';

import { PageContent } from './Dashboard.styles';
import General from './General';

const DashboardPage = () => {
  return (
    <>
      <NavigationBar />
      <PageContent>
        <SectionHeader>
          <h1>Visão Geral</h1>
        </SectionHeader>
        <General />
      </PageContent>
    </>
  );
};

export default DashboardPage;
