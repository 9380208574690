import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import School, { UpdateSchoolDTO } from '../../models/School';
import api from '../api';
import { fetcher } from './swr';
import useToast from '../../hooks/useToast';
import Class from '../../models/Class';
import Student from '../../models/Student';

export default function useSchools() {
  const { addToast } = useToast();
  const { mutate } = useSWRConfig();
  const { data, error } = useSWR<School[]>('/schools/index', fetcher, {
    dedupingInterval: 60 * 1000 * 60 * 5,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const getSchool = useCallback(
    (schoolId: string) => data?.find(schoolItem => schoolItem.id === schoolId),
    [data],
  );

  const addSchool = useCallback(
    (schoolObj: Omit<School, 'id'>) => {
      api
        .post('/schools/register', {
          name: schoolObj.name,
          label: schoolObj.label,
          address: schoolObj.address,
          phone: schoolObj.phone,
        })
        .then(() =>
          addToast({ type: 'success', title: 'Escola cadastrada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao cadastrar turma' }),
        )
        .finally(() => mutate('/schools/index'));
    },
    [addToast, mutate],
  );

  const getSchoolStudents = useCallback(
    async (schoolId: string): Promise<Student[]> => {
      const { data } = await api.get<Student[]>(
        `/schools/${schoolId}/students`,
      );
      return data;
    },
    [],
  );

  const getSchoolClasses = useCallback(
    async (schoolId: string): Promise<Class[]> => {
      const { data } = await api.get<Class[]>(`/schools/${schoolId}/classes`);
      return data;
    },
    [],
  );

  const schoolDetails = useCallback(
    async (schoolId: string) => {
      const [classes, students] = await Promise.all([
        getSchoolClasses(schoolId),
        getSchoolStudents(schoolId),
      ]);
      return { classes, students };
    },
    [getSchoolClasses, getSchoolStudents],
  );

  const updateSchool = useCallback(
    (updateSchoolDTO: UpdateSchoolDTO) => {
      if (data) {
        const newData = data.map(c => {
          if (c.id === updateSchoolDTO.id)
            return Object.assign(c, updateSchoolDTO);
          return c;
        });

        mutate('/schools/index', newData, false);
      }

      api
        .put('/schools/update', updateSchoolDTO)
        .then(() =>
          addToast({ type: 'success', title: 'Escola editada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao atualizar a turma' }),
        )
        .finally(() => mutate('/schools/index'));
    },
    [addToast, data, mutate],
  );

  const removeSchool = useCallback(
    (schoolId: string) => {
      if (data)
        mutate(
          '/schools/index',
          data.filter(c => c.id !== schoolId),
          false,
        );

      api
        .delete(`/schools/delete/${schoolId}`)
        .then(() =>
          addToast({ type: 'success', title: 'Escola deletada com sucesso' }),
        )
        .catch(() =>
          addToast({ type: 'error', title: 'Erro ao deletar turma' }),
        )
        .finally(() => mutate('/schools/index'));
    },
    [addToast, data, mutate],
  );

  return {
    schools: data || [],
    getSchool,
    addSchool,
    schoolDetails,
    updateSchool,
    removeSchool,
    error,
    isLoading: !data && !error,
  };
}
