import React from 'react';
import { useField } from 'formik';
import { TextAreaLabel } from './GenericTextArea.styles';
import InputError from '../InputError';
import StylableComponent from '../StylableComponent';

interface ComponentProps extends StylableComponent {
  name: string;
  label: string;
  placeholder: string;
}

const GenericTextArea = ({
  name,
  label,
  placeholder,
  className
}: ComponentProps) => {
  const [fieldProps, fieldMeta] = useField({
    name,
  });

  return (
    <TextAreaLabel htmlFor={name} className={className}>
      {label}
      {fieldMeta.touched && fieldMeta.error && (
        <InputError>{fieldMeta.error}</InputError>
      )}
      <textarea
        className="generic-text-area"
        placeholder={placeholder}
        {...fieldProps}
      />
    </TextAreaLabel>
  );
};

export default GenericTextArea;
