import React, { useMemo } from 'react';
import { AxisOptions, Chart } from 'react-charts';
import {
  MdCheckCircleOutline,
  MdHighlightOff,
  MdRemoveCircleOutline,
} from 'react-icons/md';
import { LessonAnalytics } from '../../models/StudentAnalytics';
import useStudentCourseAnalytics from '../../services/swr/useStudentCourseAnalytics';
import { secondsToHHMMString, secondsToRoundMinutes } from '../../utils/time';
import {
  LessonInformation,
  LessonsAndAssignmentsDataSection,
  LessonsStatusesSection,
  LessonStatuses,
  MetricCard,
  MetricCardsContainer,
  ResponsivityWrapper,
  SectionHeader,
  WatchTimeChartSection,
} from './StudentCourseMetrics.styles';

interface ComponentProps {
  studentId: string;
  courseId: string;
}

const StudentCourseMetrics = ({
  studentId,
  courseId
}: ComponentProps) => {
  const { analytics, isLoading, validAnalytics } = useStudentCourseAnalytics(
    studentId,
    courseId,
  );
  const lessonsAnalytics = validAnalytics;

  const chartData = useMemo(
    () => [
      {
        label: 'Lessons',
        data: lessonsAnalytics || [],
      },
    ],
    [lessonsAnalytics],
  );

  const primaryAxis = useMemo(
    (): AxisOptions<LessonAnalytics> => ({
      getValue: d => d.lessonTitle,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<LessonAnalytics>[] => [
      {
        getValue: d => (d.totalWatchTime / d.duration) * 100,
        elementType: 'line',
        formatters: {
          scale: (value: number) => `${value?.toFixed(2)}%`,
        },
        min: 0,
        max: 100,
      },
    ],
    [],
  );

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  return analytics ? (
    <>
      {chartData && (
        <WatchTimeChartSection>
          <SectionHeader>Tempo em Aula</SectionHeader>
          <div className="graph-container">
            <Chart
              options={{
                data: chartData,
                primaryAxis,
                secondaryAxes,
              }}
            />
          </div>
        </WatchTimeChartSection>
      )}

      <ResponsivityWrapper>
        <LessonsAndAssignmentsDataSection>
          <SectionHeader>Dados de Aulas e Atividades</SectionHeader>

          <MetricCardsContainer>
            <MetricCard>
              <div className="data">
                <p>{analytics.totalAssignmentClicks}</p>
              </div>
              <p className="metric-name">Cliques em Atividade</p>
            </MetricCard>
            <MetricCard>
              <div className="data">
                <p>{analytics.totalFullyWatchedLessons}</p>
              </div>
              <p className="metric-name">Total de Aulas Assistidas</p>
            </MetricCard>
            <MetricCard>
              <div className="data">
                <p>{secondsToRoundMinutes(analytics.averageWatchTime)}</p>
              </div>
              <p className="metric-name">Tempo Médio em Aula</p>
            </MetricCard>
            <MetricCard>
              <div className="data">
                <p>{secondsToHHMMString(analytics.totalWatchTime)}</p>
              </div>
              <p className="metric-name">Tempo Total em Aula</p>
            </MetricCard>
          </MetricCardsContainer>
        </LessonsAndAssignmentsDataSection>

        <LessonsStatusesSection>
          <SectionHeader>Situação das Aulas</SectionHeader>

          <ul className="lessons-list">
            {analytics.lessonsAnalytics.map(la => (
              <LessonInformation key={la.lessonId}>
                <p className="lesson-title">{la.lessonTitle}</p>

                <LessonStatuses>
                  {la.isFullyWatched && (
                    <div className="status green">
                      <MdCheckCircleOutline />
                      <p>Assistida</p>
                    </div>
                  )}
                  {!la.isFullyWatched && la.totalWatchTime > 0 && (
                    <div className="status orange">
                      <MdRemoveCircleOutline />
                      <p>Em Progresso</p>
                    </div>
                  )}
                  {!la.isFullyWatched && la.totalWatchTime === 0 && (
                    <div className="status red">
                      <MdHighlightOff />
                      <p>Não Assistida</p>
                    </div>
                  )}

                  {la.hasAssignment && la.wasAssignmentClicked && (
                    <div className="status green">
                      <MdCheckCircleOutline />
                      <p>Atividade Aberta</p>
                    </div>
                  )}
                  {la.hasAssignment && !la.wasAssignmentClicked && (
                    <div className="status red">
                      <MdHighlightOff />
                      <p>Atividade a Abrir</p>
                    </div>
                  )}
                </LessonStatuses>
              </LessonInformation>
            ))}
          </ul>
        </LessonsStatusesSection>
      </ResponsivityWrapper>
    </>
  ) : (
    <p>Nenhum dado foi encontrado para esse estudante neste curso</p>
  );
};

export default StudentCourseMetrics;
