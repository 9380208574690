import React from 'react';
import { Switch, Route as RouterRoute, Redirect } from 'react-router-dom';

import NotFoundPage from '../pages/404';
import ClassCourseAssociationPage from '../pages/ClassCourseAssociation';
import ClassDetailsPage from '../pages/ClassDetailsPage';
import ClassManagementPage from '../pages/ClassManagementPage';
import CourseDetailsPage from '../pages/CourseDetailsPage';
import CourseManagementPage from '../pages/CourseManagementPage';
import DashboardPage from '../pages/Dashboard';
import FreeTrialManagementPage from '../pages/FreeTrialManagementPage';
import ParentDetailsPage from '../pages/ParentDetailsPage';
import ParentsManagementPage from '../pages/ParentsManagementPage';
import SignIn from '../pages/SignIn';
import StudentDetailsPage from '../pages/StudentDetailsPage';
import StudentsManagementPage from '../pages/StudentsManagementPage';
import TeacherDetailsPage from '../pages/TeacherDetailsPage';
import TeachersManagementPage from '../pages/TeachersManagementPage';

import Route from './Route';
import SchoolManagementPage from '../pages/SchoolsManagementPage/SchoolManagementPage';
import SchoolDetailPage from '../pages/SchoolDetailPage';
import AttachmentManagementPage from '../pages/AttachmentManagementPage';
import AttachmentDetailsPage from '../pages/AttachmentDetailsPage/AttachmentDetailsPage';
import { QuizManagementPage } from '../pages/QuizManagementPage';
import CreateQuizPage from '../pages/CreateQuizPage/CreateQuizPage';
import QuizDetailsPage from '../pages/QuizDetailsPage/QuizDetailsPage';
import { QuestionsManagementPage } from '../pages/QuestionsManagementPage';
import CreateQuestionsPage from '../pages/CreateQuestionPage/CreateQuestionsPage';

const Routes = () => (
  <Switch>
    <RouterRoute path="/" exact>
      <Redirect to="/login" />
    </RouterRoute>

    <Route path="/login" component={SignIn} />

    <Route path="/dashboard" isPrivate component={DashboardPage} />

    <Route path="/class-management" isPrivate component={ClassManagementPage} />

    <Route
      path="/schools-management"
      isPrivate
      component={SchoolManagementPage}
    />
    <Route
      path="/school-details/:schoolId"
      isPrivate
      component={SchoolDetailPage}
    />

    <Route path="/quiz-management" isPrivate component={QuizManagementPage} />
    <Route path="/quiz-details/:quizId" isPrivate component={QuizDetailsPage} />
    <Route path="/create-quiz" isPrivate component={CreateQuizPage} />

    <Route
      path="/questions-management"
      isPrivate
      component={QuestionsManagementPage}
    />

    <Route path="/create-questions" isPrivate component={CreateQuestionsPage} />

    <Route
      path="/class-details/:classId"
      isPrivate
      component={ClassDetailsPage}
    />

    <Route
      path="/course-management"
      isPrivate
      component={CourseManagementPage}
    />
    <Route
      path="/course-details/:courseId"
      isPrivate
      component={CourseDetailsPage}
    />

    <Route
      path="/attachments-management"
      isPrivate
      component={AttachmentManagementPage}
    />

    <Route
      path="/attachments-details/:attachmentId"
      isPrivate
      component={AttachmentDetailsPage}
    />

    <Route
      path="/teachers-management"
      isPrivate
      component={TeachersManagementPage}
    />
    <Route
      path="/teacher-details/:teacherId"
      isPrivate
      component={TeacherDetailsPage}
    />

    <Route
      path="/parents-management"
      isPrivate
      component={ParentsManagementPage}
    />
    <Route
      path="/parent-details/:parentId"
      isPrivate
      component={ParentDetailsPage}
    />

    <Route
      path="/students-management"
      isPrivate
      component={StudentsManagementPage}
    />
    <Route
      path="/student-details/:studentId"
      isPrivate
      component={StudentDetailsPage}
    />

    <Route
      path="/free-trial-management"
      isPrivate
      component={FreeTrialManagementPage}
    />

    <Route
      path="/class-course-association"
      isPrivate
      component={ClassCourseAssociationPage}
    />

    <RouterRoute path="/">{NotFoundPage}</RouterRoute>
  </Switch>
);

export default Routes;
