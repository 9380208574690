import styled from 'styled-components';

export const ScrollableContainer = styled.div`
  overflow-y: scroll;

  scrollbar-color: var(--color-light-green) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--color-light-green);
  }
`;
