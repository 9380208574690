type ColorName = keyof typeof COLOR_MAP;

const COLOR_MAP = {
  white: 'ffffff',
  grey: '3e434b',
  black: '020a17',
  red: 'b32111',
  'dark-blue': '07224b',
  'light-green': '3db723',
  'dark-green': '269912',
  'dark-green-hover': '1e7a0e',
};

export const color = (colorName: ColorName): string =>
  `#${COLOR_MAP[colorName]}`;

/**
 * Fluent API for manipulating a color.
 */
export default class Color {
  private colorName: ColorName;
  private alpha: number;

  constructor(colorName: ColorName) {
    this.colorName = colorName;
    this.alpha = 1;
  }

  private colorValue() {
    return COLOR_MAP[this.colorName];
  }

  /**
   * Adds an alpha value to the color.
   *
   * @param alpha a number between 0 and 1 representing the alpha value to add.
   */
  withAlpha(alpha: number) {
    if (alpha < 0 || alpha > 1)
      throw new Error(
        'Illegal Argument: alpha must be a value between 0 and 1',
      );

    this.alpha = alpha;

    return this;
  }

  /**
   * Returns the color as an rgba string.
   *
   * @returns a string in the format 'rgba(r, g, b, a)'
   */
  rgba() {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      this.colorValue(),
    );

    if (!result) throw new Error('Unable to parse color');

    return `rgba(${parseInt(result[1], 16)}, ${parseInt(
      result[2],
      16,
    )}, ${parseInt(result[3], 16)}, ${this.alpha})`;
  }

  /**
   * Returns the color as a hexadecimal string.
   *
   * @returns a string in the format `#RRGGBBAA` if an alpha value was added. `#RRGGBB` otherwise.
   */
  hex() {
    if (this.alpha) {
      return `#${this.colorValue()}${parseInt(this.alpha.toString(16), 16)}`;
    }

    return `#${this.colorValue()}`;
  }
}
