import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useModalRef from '../../hooks/useModalRef';
import School from '../../models/School';
import NotFoundPage from '../404';
import {
  PageContent,
  SchoolsDetailsTabNavigator,
} from './SchoolsDetailsPage.styles';
import EditSchoolModal from './EditSchoolModal';
import { Tab } from '../../components/TabNavigator';
import useSchools from '../../services/swr/useSchools';
import { ClassesRankingPage } from './RankingDetailPage';
import NavigationBar from '../../components/NavigationBar';
import { SchoolDetailsPageHeader } from './SchoolDetailsPageHeader';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import SchoolDetailsPageUrlParams from '../../routes/location/SchoolDetailsPageUrlParams';

const SchoolDetailsPage = () => {
  const { schoolId } = useParams<SchoolDetailsPageUrlParams>();
  const { getSchool, removeSchool, isLoading } = useSchools();
  const schoolInfo = getSchool(schoolId) as School;

  const { push } = useHistory();

  const deleteSchoolPromptRef = useModalRef();
  const editSchoolModalRef = useModalRef();

  const handleDeleteSchool = () => {
    removeSchool(schoolInfo.id);
    push('/schools-management');
  };

  if (!schoolInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />
      <PageContent>
        {schoolInfo && (
          <>
            <SchoolDetailsPageHeader schoolInfo={schoolInfo} />

          <SchoolsDetailsTabNavigator>
              <Tab label="Turmas">
                <h1>Turmas</h1>
              {/* <ClassStudentsTable classId={classId} /> */}
            </Tab>
            <Tab label="Ranking">
                <ClassesRankingPage schoolId={schoolId} />
            </Tab>
            <Tab label="Métricas">
                <h1>Métricas</h1>
              {/* <ClassMetricsTab classId={classId} /> */}
            </Tab>
          </SchoolsDetailsTabNavigator>

            <ConfirmationPrompt
              ref={deleteSchoolPromptRef}
              promptText={`Tem certeza que quer deletar "${schoolInfo.label}" permanentemente?`}
              onConfirm={handleDeleteSchool}
            />
            <EditSchoolModal ref={editSchoolModalRef} schoolInfo={schoolInfo} />
          </>
        )}
      </PageContent>
    </>
  );
};

export default SchoolDetailsPage;
