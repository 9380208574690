import styled from 'styled-components';
import ContentWrapper from '../ContentWrapper';
import StrippedButton from '../StrippedButton';
import BurguerMenu from './components/BurguerMenu';
import MenuContainer from './components/MenuContainer';

export const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  top: 0;

  height: 80px;
  width: 100%;

  background-color: var(--color-dark-green);

  z-index: 10;
`;

export const NavBarContent = styled(ContentWrapper)`
  /* Used by hanging menus */
  position: relative;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  height: 60px;
  max-width: 1120px;

  .itus-logo {
    width: 56px;
  }

  .itus-logo:hover {
    cursor: pointer;
  }
`;

export const MenuGroup = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  .divider {
    width: 1px;
    height: 40px;
    background-color: var(--color-white);
    margin-left: 16px;
  }
`;

export const AdminMenuToggler = styled(StrippedButton)`
  position: relative;
  display: flex;
  align-items: center;

  margin-left: 16px;

  .menu-title {
    font-family: var(--font-sans);
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--color-white);
    margin-left: 8px;
  }

  svg {
    color: var(--color-white);
  }
`;

export const UserContainer = styled.div`
  position: relative;
`;

export const UserInformation = styled.article`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  min-width: 264px;

  &:hover {
    cursor: pointer;
  }

  .user-name {
    font-family: var(--font-sans);
    font-size: 1.25rem;
    color: var(--color-white);

    margin-right: 16px;
  }

  .user-avatar {
    max-width: 64px;
    aspect-ratio: 1 / 1;
  }
`;

export const UserMenuContainer = styled(MenuContainer)`
  right: 0;
  margin-top: 8px;

  transform-origin: top right;
`;

export const NavBarBurguerMenu = styled(BurguerMenu)`
  display: block;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Vanishable = styled.div<{ at: number }>`
  display: none;

  @media screen and (min-width: ${props => props.at}px) {
    display: block;
  }
`;
