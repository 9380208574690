import React from 'react';
import { useParams } from 'react-router-dom';
import NavigationBar from '../../components/NavigationBar';
import { Tab } from '../../components/TabNavigator';
import Course from '../../models/Course';
import CourseDetailsPageUrlParams from '../../routes/location/CourseDetailsPageUrlParams';
import useCourses from '../../services/swr/useCourses';
import NotFoundPage from '../404';
import {
  CourseDetailsTabNavigator,
  PageContent,
} from './CourseDetailsPage.styles';
import CourseDetailsPageHeader from './CourseDetailsPageHeader';
import CourseLessonsList from './CourseLessonsList';
import CourseMetricsTab from './CourseMetricsTab';
import CourseTeachersList from './CourseTeachersList';

const CourseDetailsPage =  () => {
  const { courseId } = useParams<CourseDetailsPageUrlParams>();
  const { getCourse, isLoading } = useCourses();

  const courseInfo = getCourse(courseId);

  if (!courseInfo && !isLoading) {
    return <NotFoundPage />;
  }

  return (
    <>
      <NavigationBar />

      {isLoading ? (
        <p>Carregando curso...</p>
      ) : (
        <PageContent>
          <CourseDetailsPageHeader courseInfo={courseInfo as Course} />
          <CourseDetailsTabNavigator>
            <Tab label="Aulas">
              <CourseLessonsList courseId={courseId} />
            </Tab>
            <Tab label="Professores">
              <CourseTeachersList courseId={courseId} />
            </Tab>
            <Tab label="Métricas">
              <CourseMetricsTab courseId={courseId} />
            </Tab>
          </CourseDetailsTabNavigator>
        </PageContent>
      )}
    </>
  );
};

export default CourseDetailsPage;
