import styled from 'styled-components';
import CallToActionButton from '../CallToActionButton';

export const CredentialsFormContainer = styled.form``;

export const ForgotPasswordLink = styled.a`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: var(--color-grey);

  margin-top: 16px;
`;

export const SubmitButton = styled(CallToActionButton)`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  width: 100%;
  margin-top: 32px;
  padding: 12px;
  border-radius: 24px;

  --shadow-color: 0deg 0% 66%;

  box-shadow: 0.3px 0.4px 0.7px hsl(var(--shadow-color) / 0.06),
    1.2px 1.5px 2.7px -0.2px hsl(var(--shadow-color) / 0.19),
    2.6px 3.2px 5.9px -0.3px hsl(var(--shadow-color) / 0.32),
    5.4px 6.7px 12.3px -0.5px hsl(var(--shadow-color) / 0.45);

  &:active {
    transition: box-shadow 0.2s ease-out;
    box-shadow: 0.3px 0.4px 0.5px hsl(var(--shadow-color) / 0.39),
      0.8px 1px 1.3px -1px hsl(var(--shadow-color) / 0.36),
      2.1px 2.6px 3.5px -2px hsl(var(--shadow-color) / 0.32),
      5.4px 6.7px 9px -3px hsl(var(--shadow-color) / 0.29);
  }
`;
