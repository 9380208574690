import styled from 'styled-components';
import GenericPrimaryButton from '../GenericPrimaryButton';
import GenericSecondaryButton from '../GenericSecondaryButton';

export const ForgotPasswordLink = styled.a`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: var(--color-grey);

  margin-top: 16px;
`;

export const FormOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;

  & > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const CancelButton = styled(GenericSecondaryButton)`
  color: var(--color-red);
  font-weight: 500;
`;

export const SubmitButton = styled(GenericPrimaryButton)`
  font-weight: 500;
`;
