import React, { useCallback, useImperativeHandle, useRef, ReactPortal, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import dialogPolyfill from 'dialog-polyfill';
import { MdClose } from 'react-icons/md';
import { CloseModalButton, ModalContainer, ModalContent } from './Modal.styles';
import StylableComponent from '../StylableComponent';

interface ModalProps extends StylableComponent {
  children?: React.ReactNode;
}

const Modal = forwardRef<unknown, ModalProps>((props, ref) => {
  const { className, children } = props;
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const openModal = useCallback(() => {
    const modal  = modalRef.current as HTMLDialogElement;
    dialogPolyfill.registerDialog(modal);
    modal?.showModal();
  }, []);

  const closeModal = useCallback(() => {
    const modal = modalRef.current;
    modal?.close();
  }, []);

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
  }));

  const modalContent = (
    <ModalContainer ref={modalRef} className={className}>
      <ModalContent>
        <CloseModalButton onClick={closeModal}>
          <MdClose className="close-icon" />
        </CloseModalButton>

        <div>{children}</div>
      </ModalContent>
    </ModalContainer>
  );

  return ReactDOM.createPortal(modalContent, document.body) as ReactPortal;
});

Modal.displayName = 'Modal';

export default Modal;
