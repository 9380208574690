import React, { useCallback } from 'react';
import * as Yup from 'yup';
import GenericTextInput from '../../components/GenericTextInput';
import useParents from '../../services/swr/useParents';
import Parent from '../../models/Parent';
import { ModalContainer, ModalContent } from './EditParentModal.styles';
import { ModalRef } from '../../hooks/useModalRef';
import { UpdateProfileDTO } from '../../models/Profile';
import { stripEmptyFields } from '../../utils/objects';
import GenericModalForm from '../../components/GenericModalForm';
import { FormikHelpers } from 'formik';

interface ComponentProps {
  parentInfo: Parent;
}

const EditParentModal = React.forwardRef<unknown, ComponentProps>(
  ({ parentInfo }, ref) => {
    const { updateParentProfile } = useParents();

    const closeModal = useCallback(
      () => (ref as ModalRef).current?.closeModal(),
      [ref],
    );

    const onSubmit = useCallback(
      async (values: Record<string, unknown>, { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>) => {
        const updateParentProfileDTO: UpdateProfileDTO = {
          id: parentInfo.profile.id,
          ...stripEmptyFields(values),
        };
        updateParentProfile(updateParentProfileDTO);

        (ref as ModalRef).current?.closeModal();

        setSubmitting(false);
        resetForm();
      },
      [parentInfo.profile.id, ref, updateParentProfile],
    );

    return (
      <ModalContainer ref={ref}>
        <ModalContent>
          <h1 className="modal-title">Editar Responsável</h1>

          <GenericModalForm
            initialValues={{
              name: '',
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              email: Yup.string().email('Digite um e-Mail válido'),
              password: Yup.string().min(
                6,
                'Senha deve conter ao menos 6 caracteres',
              ),
            })}
            submitButtonType="edit"
            onSubmit={onSubmit}
            onCancel={closeModal}
          >
            <GenericTextInput
              name="name"
              label="Nome"
              type="text"
              placeholder={parentInfo.profile.name || 'João da Silva'}
            />
            <GenericTextInput
              name="email"
              label="e-Mail"
              type="email"
              placeholder={parentInfo.profile.email}
            />
            <GenericTextInput
              name="password"
              label="Senha"
              type="password"
              placeholder="***********"
            />
          </GenericModalForm>
        </ModalContent>
      </ModalContainer>
    );
  },
);

EditParentModal.displayName = 'EditParentModal';

export default EditParentModal;
