import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const QuizListItemContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuizLink = styled(Link)`
  text-decoration: none;
`;

export const QuizDescriptionContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
  flex-direction: column;
`;

export const QuizInfo = styled.div`
  font-family: var(--font-sans);
  color: var(--color-black);

  .school-title {
    font-size: 1.25rem;
    font-weight: 500;

    margin-bottom: 8px;
  }
`;
