import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';

export const ModalContainer = styled(GenericModal)`
  width: 80%;
  max-width: 600px;
`;

export const Title = styled.h1`
  margin-bottom: 3rem;
  font-family: 'Roboto', sans-serif;
`;
