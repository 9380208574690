import React from 'react';
import styled, { keyframes } from 'styled-components';
import { RankingMember } from '../models/RankingMember';


interface ComponentProps {
  ranking: RankingMember[];
}

const GenericRanking = ({ ranking }: ComponentProps) => {
  return (
    <Container>
      <Table>
        <HeaderContainer>
          <TableRow>
            <TableHeader>
              <Icon>
                <img
                  src="/assets/trophy.svg"
                  alt="Ícone de troféu"
                  height={30}
                  width={30}
                />
              </Icon>
            </TableHeader>
            <TableHeader>Turma</TableHeader>
            <TableHeader>
              <Icon>
                <img
                  src="/assets/coin.svg"
                  alt="Ícone de moeda"
                  height={30}
                  width={30}
                />
              </Icon>
            </TableHeader>
            <TableHeader>
              <Icon>
                <img
                  src="/assets/xp.svg"
                  alt="Ícone de experiência"
                  height={27}
                  width={27}
                />
              </Icon>
            </TableHeader>
            <TableHeader>
              <Icon>
                <img
                  src="/assets/percentage.png"
                  alt="Ícone de porcentagem"
                  height={27}
                  width={27}
                />
              </Icon>
            </TableHeader>
          </TableRow>
        </HeaderContainer>
        <BodyContainer>
          {ranking.map((member, index) => {
            const percentage = member.completionRate
              ? Number(member.completionRate).toFixed(2)
              : 0;
            return (
              <TableRow key={member.classId}>
                <TableData>
                  <RankingPosition>{index + 1}</RankingPosition>
                </TableData>
                <TableData>{member.title}</TableData>
                <TableData>{member.totalBalance}</TableData>
                <TableData>{member.totalExperiencePoints}</TableData>
                <TableData>{percentage} %</TableData>
              </TableRow>
            );
          })}
        </BodyContainer>
      </Table>
    </Container>
  );
};

export default GenericRanking;

const HeaderAnimation = () => keyframes`
  0% { opacity: 0; color: transparent; transform: scale(1.05)}
  50% { color: auto }
  100% { opacity: 100%; transform: scale(1)}
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  border-radius: 5px;
  margin: 2rem auto;
  font-family: 'Inter'; /* theme.fonts.inter */

  @media screen and (max-width: 768px) {
    font-size: 1.0rem; /* theme.fontSizes.sm */
  }
`;

const TableRow = styled.tr`
  height: 2rem;
`;

const TableHeader = styled.th`
  padding: 8px;
  text-align: center;
  font-size: 1.5rem; /* theme.fontSizes.md */
  color: #E1E1E6; /* theme.colors.subTitle */

  @media screen and (max-width: 768px) {
    font-size: 1.0rem; /* theme.fontSizes.sm */
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RankingPosition = styled.div`
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #269912; /* theme.colors.primary */
  color: #f0f2f5; /* theme.colors.background */
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    font-size: 1.0rem; /* theme.fontSizes.sm */
  }
`;

const Container = styled.div``;

const TableRowAnimation = () => keyframes`
  0% { opacity: 0; transform: translateY(15px) }
  100% { opacity: 1; transform: translateY(0) }
  
`;

const BodyContainer = styled.tbody`
  background-color: #e5e5e5; /* theme.colors.backgroundSecondary */
  animation: ${TableRowAnimation} 1s ease-in-out;
`;

const TableData = styled.td`
  padding: 1.25rem 2rem;
  text-align: center;
  color: #222; /* theme.colors.themedTitle */

  &:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

const HeaderContainer = styled.thead`
  background-color: #2782C4; /* theme.colors.secondary */
  animation: ${HeaderAnimation} 1s ease-in-out;

  ${TableHeader}:first-child {
    border-top-left-radius: 6px;
  }

  ${TableHeader}:last-child {
    border-top-right-radius: 6px;
  }
`;

