import React, { useCallback, useRef } from 'react';
import { ModalRef } from '../../hooks/useModalRef';
import styled from 'styled-components';
import GenericModal from '../../components/GenericModal';
import useToast from '../../hooks/useToast';
import GenericModalForm from '../../components/GenericModalForm';
import GenericTextInput from '../../components/GenericTextInput';
import GenericFileInput from '../../components/GenericFileInput';
import useQuiz from '../../services/swr/useQuiz';
import { FormikHelpers } from 'formik';

const AddQuizAttachmentModal = React.forwardRef((_, ref) => {
  const { addToast } = useToast();
  const { attachQuiz } = useQuiz();

  const attachmentFile = useRef<File | null>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(
    async (
      values: Record<string, unknown>,
      { setSubmitting, resetForm }: FormikHelpers<Record<string, unknown>>,
    ) => {
      try {
        if (!attachmentFile.current) return;
        const { quizTitle, description, tags } = values as {
          quizTitle: string;
          description: string;
          tags: string;
        };
        await attachQuiz(
          attachmentFile.current as File,
          quizTitle,
          description,
          tags,
        );

        if (attachmentInputRef.current) attachmentInputRef.current.value = '';

        setSubmitting(false);
        resetForm();

        (ref as ModalRef).current?.closeModal();

        addToast({
          type: 'success',
          title: 'Questionário anexado com sucesso',
          description: 'O questionário foi anexado com sucesso',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao anexar questionário',
          description:
            'Ocorreu um erro ao anexar o questionário, tente novamente',
        });
      }
    },
    [ref, addToast, attachQuiz],
  );

  return (
    <ModalContainer ref={ref}>
      <GenericModalForm
        initialValues={{
          quizTitle: '',
          description: '',
          tags: '',
        }}
        submitButtonType="create"
        onSubmit={handleSubmit}
        onCancel={() => (ref as ModalRef).current?.closeModal()}
      >
        <GenericTextInput
          name="quizTitle"
          label="Título do Quiz"
          type="text"
          placeholder="Eventos"
        />
        <GenericTextInput
          name="description"
          label="Descrição do Quiz"
          type="text"
          placeholder="Quiz sobre eventos para o 1º ano"
        />
        <GenericTextInput
          name="tags"
          label="Tags"
          type="text"
          tooltip="Separe as tags por vírgula. Servirão para facilitar a busca"
          placeholder="1ºano, loops, condicionais"
        />
        <GenericFileInput
          ref={attachmentInputRef}
          name="file"
          label="Perguntas"
          setFile={(file: File) => {
            attachmentFile.current = file;
          }}
        />
      </GenericModalForm>
    </ModalContainer>
  );
});

AddQuizAttachmentModal.displayName = 'AddQuizAttachmentModal';

export default AddQuizAttachmentModal;

const ModalContainer = styled(GenericModal)`
  width: 50%;
`;
